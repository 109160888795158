import {
  FETCH_AUTH0_TOKEN_ERROR,
  FETCH_AUTH0_TOKEN_COMPLETE,
} from '../types'

const INITIAL_STATE = {
  token: '',
  fetchedAuth0TokenError: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_AUTH0_TOKEN_ERROR:
      return {
        ...state,
        fetchedAuth0TokenError: true,
      }

    case FETCH_AUTH0_TOKEN_COMPLETE:
      return {
        ...state,
        token: action.token,
        fetchedAuth0TokenError: false,
      }

    default: return state
  }
}

export default reducer