import CheckIcon from '../../../CheckIcon'
import { Container, Tooltip } from './styles'

const StarProblem = ({ starred, onClick }) => {
  return (
    <Container>
      <CheckIcon
        width='25px'
        height='24px'
        checked={starred}
        onClick={onClick}
      />
      <Tooltip>
        Click the check to recommend this problem to your cohort
      </Tooltip>
    </Container>
  )
}

export default StarProblem
