import React, { useEffect, useState, useRef } from 'react'
import withPageWrapper from '../../hoc/AuthPageWrapper'
import PracticeProblemList from '../ListItems/PracticeProblems'
import PracticeProblem from '../ViewsContent/PracticeProblem'
import axios from '../../axios-orders'
import { useParams } from 'react-router-dom'
import BlockLoader from '../ContentLoaders/BlockLoader'

const Concept = () => {
  const cancelSource = useRef(null)
  const [items, setItems] = useState({})
  const [activeProblem, setActiveProblem] = useState({})
  const [fetchingItems, setFetchingItems] = useState(false)
  const routeParams = useParams()

  useEffect(() => {
    cancelSource.current = axios.CancelToken.source()
    setFetchingItems(true)
    // Fetch the session videos
    axios.get('/common/practice-problems', {
      cancelToken: cancelSource.current.token,
      params: {
        id: routeParams.id,
      }
    }).then(({ data: { data, title } }) => {
      let sortedData = data.sort((a, b) => a.concept > b.concept ? 1 : -1)
      // Format item title via index e.g "Q1. Linear Dependence"
      sortedData = sortedData.map((item, index) => ({
        ...item,
        title: `Q${index + 1}. ${item.concept}`,
      }))
      setFetchingItems(false)
      setItems({
        title,
        data: sortedData,
      })
      // Set the active item data
      let activeItem = sortedData[0] || {}
      if (activeItem) {
        setActiveProblem(activeItem)
      }
    }).catch(error => {
      // Handle error
      console.log(error)
      setFetchingItems(false)
    })

    return () => {
      cancelSource.current.cancel('Cleanup function: cancel request')
    }
  }, [])

  const showNextQuestion = () => {
    // Get the problems data
    const { title, data } = items
    // Get the active problem ID
    const activeProblemId = activeProblem?.problemId
    const activeProblemIndex = data.findIndex(problem => problem.problemId === activeProblemId)
    // Update current active problem item
    data[activeProblemIndex] = {
      ...data[activeProblemIndex],
      hasAttempted: true,
      dateCompleted: new Date(),
    }
    // Set the new items
    setItems({
      title,
      data,
    })
    // Get the new active item by incrementing the index
    const newActiveItem = data[activeProblemIndex + 1]
    if (newActiveItem) {
      // Update the active item
      setActiveProblem(newActiveItem)
    }
  }

  return (
    <div className="mt-10 flex flex-wrap -mx-5">
      <div className="w-full md:w-2/5 px-5">
        {
          fetchingItems &&
          <BlockLoader width={600} height={500} />
        }
        {
          !fetchingItems &&
          <PracticeProblemList items={items} setActiveProblem={setActiveProblem} />
        }
      </div>
      <div className="w-full md:w-3/5 px-5">
        {
          fetchingItems &&
          <BlockLoader width={600} height={500} />
        }
        {
          (!fetchingItems && activeProblem.problemId) &&
          <PracticeProblem
            hint={activeProblem.hint}
            id={activeProblem.problemId}
            title={activeProblem.title}
            problem={activeProblem.problem}
            hintLink={activeProblem.hintLink}
            solution={activeProblem.solution}
            solutionVideo={activeProblem.solutionLink}
            answerOptions={activeProblem.answerOptions}
            correctAnswer={activeProblem.correctAnswer}
            showNextQuestion={showNextQuestion}
          />
        }
      </div>
    </div>
  )
}

export default withPageWrapper(Concept, 'study', '/study', 'Study Home')