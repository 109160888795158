import React from 'react'
import { Link } from 'react-router-dom'

import NewItemIcon from '../../assets/icons/newTag.svg'

const SidebarItem = ({ icon, route, title, isActive, onClick, newItem }) => {
  return (
    <Link
      onClick={onClick}
      to={route}
      className={`app-sidebar__item ${
        isActive ? 'app-sidebar__item--active' : ''
      } font-medium mb-4`}
    >
      {newItem ? (
        <img
          style={{ position: 'fixed', marginTop: '-20px', marginLeft: '-15px' }}
          src={NewItemIcon}
          alt='new'
        ></img>
      ) : (
        <></>
      )}
      <span className='app-sidebar__item__icon'>{icon}</span>
      <span className='app-sidebar__item__title text-violet-dark leading-20 ml-2 inline-block text-xl'>
        {title}
      </span>
    </Link>
  )
}

export default SidebarItem
