import React from 'react'
import { ReactComponent as GoogleIcon } from '../../assets/icons/google.svg'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg'

export const Button = ({ disabled, small, large, style = {}, text, icon, iconDirection, className, onClick, type = 'button', noBackgroundColor }) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      style={{
        borderRadius: '4px',
        padding: small ? '6px 2px' : large ? '15px 25px' : '11px 25px',
        opacity: disabled ? '0.3' : 1,
        ...style
      }}
      className={`${!noBackgroundColor ? 'text-white' : 'text-black'} font-lg flex items-center justify-center leading-18 ${className ?? ''}`}
    >
      {icon && (!iconDirection || iconDirection === "left") &&
        <span className="align-middle mr-2 inline-block w-5">{icon}</span>
      }
      <span className="align-middle inline-block">{text}</span>
      {icon && iconDirection === "right" &&
        <span className="align-middle ml-2 inline-block w-5">{icon}</span>
      }
    </button>
  )
}

const BaseBrandButton = ({ icon, text, className, onClick, style = {} }) => (
  <button
    onClick={onClick}
    style={{
      borderRadius: '4px',
      padding: '9px 25px',
      ...style
    }}
    className={`font-medium text-center w-full text-gray-darkest font-lg inline-block leading-20 ${className ?? ''}`}>
    <span className="align-middle mr-3 inline-block">
      {icon}
    </span>
    <span className="align-middle">{text}</span>
  </button>
)

export const GoogleButton = (props) => <BaseBrandButton icon={<GoogleIcon />} {...props} />

export const FacebookButton = (props) => <BaseBrandButton icon={<FacebookIcon />} {...props} />