import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { setCourses } from '../redux/actions/edlyft'

export function useUpdateCohortStarredPracticeProblems() {
  const dispatch = useDispatch()
  const courses = useSelector(state => state.edlyft.courses, shallowEqual) || []

  return (edlyftCohort, starredPracticeProblems = []) => {
    const updatedCourses = courses.map(course => {
      if (course.edlyftCohort !== edlyftCohort) {
        return course
      }

      return { ...course, starredPracticeProblems }
    })

    dispatch(setCourses(updatedCourses))
  }
}
