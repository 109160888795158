import React from 'react'
import { BarLoader } from 'react-spinners'
import edlyftLogo from '../assets/images/edlyft-logo-small.png'

const PageLoader = () => (
  <div className="bg-white fixed flex flex-col items-center justify-center w-full h-full">
    <span className="inline-block mb-16 animate-bounce">
      <img src={edlyftLogo} alt="logo" />
    </span>
    <BarLoader width={150} height={2} />
  </div>
)

export default PageLoader