import React, {useCallback, useState} from "react";

import {ReactComponent as PlayIcon} from "../../assets/icons/play_btn.svg";
import {ReactComponent as EditIcon} from "../../assets/icons/edit_btn.svg";
import {ReactComponent as BinIcon} from "../../assets/icons/bin_btn.svg";

import TimestampForm from './form'
import {amplitudeInstance} from "../../amplitude";
import DeleteConfirmationModal from "./deleteModal";

function timerToSec(timer){
    let vtimer = timer.split(":")

    if (vtimer.length < 3) {
        const [mm, ss] = vtimer
        let vminutes = +mm
        let vseconds = parseFloat(ss)

        return vminutes * 60 + vseconds
    }

    const [hh, mm, ss] = vtimer

    let vhours = +hh
    let vminutes = +mm
    let vseconds = parseFloat(ss)

    return vhours * 3600 + vminutes * 60 + vseconds
}

const TimestampsList = ({ isMentor, timestamps, updateTimestamps, videoID, classID }) => {
    const [allTimestamps, setTimestamps] = useState(timestamps)
    const [editingIndex, setTimestampEditing] = useState()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [timestampsAfterDelete, setTimestampsAfter] = useState()

    const [_, setCurrentEditing] = useState({
        timestamp: '',
        sentence: ''
    })

    const handleSaveClick = useCallback((index, { timestamp, sentence }) => {
        const newSetOfTimestamps = [
            ...allTimestamps,
        ]

        newSetOfTimestamps[index].timestamp = timestamp;
        newSetOfTimestamps[index].sentence = sentence;

        setTimestampEditing(null)
        setTimestamps(newSetOfTimestamps)
        updateTimestamps(newSetOfTimestamps)
    }, [allTimestamps])

    const handlePlayClick = useCallback((index) => {
        const event = new CustomEvent('play-from-timestamp', {
            detail: {
                timestamp: timerToSec(allTimestamps[index].timestamp)
            }
        });

        amplitudeInstance.logEvent("Clicked a Recording Timestamp", {
            videoId: videoID,
            cohort: classID,
        })

        window.dispatchEvent(event);
    }, [allTimestamps])

    const handleEditClick = useCallback((index) => {
        setTimestampEditing(index)

        setCurrentEditing({
            timestamp: allTimestamps[index].timestamp,
            sentence: allTimestamps[index].sentence
        })
    }, [allTimestamps])

    const handleDeleteClick = useCallback((index) => {
        setTimestampsAfter(allTimestamps.filter((_ , tsin) => tsin !== index))
        setShowDeleteModal(true)
    }, [allTimestamps])

    return (
        <ul className="timestamps-list">
            {allTimestamps.sort((a, b) => {
                        return timerToSec(a.timestamp) - timerToSec(b.timestamp)
                    }).map(({ sentence, timestamp }, index) => (
                        <li key={index} onClick={(e) => {
                            e.stopPropagation();
                            handlePlayClick(index)
                        }}>
                            {(editingIndex !== index) ? (
                                <>
                                    <span>{timestamp}</span>
                                    <span>{sentence}</span>
                                    <div className="controls">
                                        {isMentor ? (
                                            <>
                                                <button onClick={(e) => {
                                                    e.stopPropagation();
                                                    handlePlayClick(index)
                                                }}>
                                                    <PlayIcon />
                                                </button>
                                                <button onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditClick(index)
                                                }}>
                                                    <EditIcon />
                                                </button>
                                                <button onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteClick(index)
                                                }}>
                                                    <BinIcon />
                                                </button>
                                            </>
                                        ) : (
                                            <button onClick={(e) => {
                                                e.stopPropagation();
                                                handlePlayClick(index)
                                            }}>
                                                <PlayIcon />
                                            </button>
                                        )}
                                    </div>
                                </>
                            ): (
                                <TimestampForm
                                    key={index}
                                    defaultTimestamp={timestamp}
                                    defaultSentence={sentence}
                                    handleSubmit={(data) => handleSaveClick(index, data)}
                                    handleClose={() => {
                                        setTimestampEditing(null)
                                    }}
                                />
                            )}
                        </li>
                    ))}
            <DeleteConfirmationModal
                open={showDeleteModal}
                onClose={(shouldDelete) => {
                    if (shouldDelete) {
                        updateTimestamps(timestampsAfterDelete)
                    }

                    setTimestampsAfter(undefined)
                    setShowDeleteModal(false)
                }}
            />
        </ul>
    )
}

export default TimestampsList
