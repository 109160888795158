import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SETTINGS = {
  dots: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
}

const AuthSlider = ({ slides }) => (
  <div className="auth-slider">
    <Slider {...SETTINGS}>
      {
        slides.map((slide, index) => (
          <div key={index} className="outline-none text-center">
            <div className="mb-10 md:mb-20 mx-auto" style={{ maxWidth: '350px' }}>
              <span className="leading-33 font-medium block text-violet-dark text-5xl mb-2">
                {slide.title}
              </span>
              <span style={{ color: '#8B8896' }} className="leading-30 font-thin text-xl block">{slide.caption}</span>
            </div>
            <div className="mx-auto px-20" style={{ width: '568px', maxWidth: '100%' }}>
              <img src={slide.image} alt={slide.title}/>
            </div>
          </div>
        ))
      }
    </Slider>
  </div>
)

export default AuthSlider

