import React from 'react'
import { amplitudeInstance } from '../../amplitude'
import withFilePreview from '../../hoc/FilePreview'
import { connect } from 'react-redux'
import { Page } from '../../contexts/page'
import { saveInteraction } from '../../utils'
import useMetricsApi, { RecordingEvent } from '../../hooks/useMetricsApi'

const SingleNote = ({
  notes,
  showFilePreview,
  title,
  edlyftCohort,
  userId,
  screenOut,
  isActive,
  isMentor,
  firstName,
  lastName,
}) => {
  const { enqueue } = useMetricsApi()
  const onClick = (item, currentPageName) => {
    showFilePreview(item.cloudfrontURL)
    amplitudeInstance.logEvent('Clicks Recording Note', {
      cohort: edlyftCohort,
      fromPage: currentPageName,
    })
    enqueue(RecordingEvent)
  }

  if (Array.isArray(notes)) {
    return (
      <Page.Consumer>
        {({ currentPageName }) => (
          <div className='font-medium leading-13 text-sm'>
            <span className='text-darkest mr-1'>{title || 'Notes:'}</span>
            {notes.map(item => (
              <button
                onClick={() => {
                  saveInteraction({
                    resourcePoster: '',
                    edlyftCohort,
                    resourceType: 'note',
                    resourceId: item.id,
                    screenOut,
                    userId,
                    isActive,
                    isMentor,
                    resourceTitle: 'clickedRecordingNote',
                    userName: `${firstName} ${lastName}`,
                  })
                  onClick(item, currentPageName)
                }}
                key={item.id}
                className='text-violet-dark underline mr-1'
              >
                {item.name}
              </button>
            ))}
          </div>
        )}
      </Page.Consumer>
    )
  }

  // No attachments
  return null
}

const mapStateToProps = state => {
  const {
    user: {
      edlyftCohort,
      userId,
      screenOut,
      isActive,
      isMentor,
      firstName,
      lastName,
    },
  } = state

  return {
    edlyftCohort,
    userId,
    screenOut,
    isActive,
    isMentor,
    firstName,
    lastName,
  }
}

export default connect(mapStateToProps)(withFilePreview(SingleNote))
