import { Route, BrowserRouter, Switch } from 'react-router-dom'

import GetStarted from "./GetStarted"
import AuthForm from "./AuthForm"
import AuthFormLogin from "./AuthFormLogin"
import VerificationCode from "./VerificationCode"
import Referral from "./Referral"

import { checkSubscription } from "./checkSubscription"

const GuestRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={GetStarted} />
      <Route exact path='/signup' component={AuthForm} />
      <Route exact path='/login' component={AuthFormLogin} />
      {/* <Route exact path='/verify' component={VerificationCode} /> */}
      <Route exact path='/refer/:firstName/:lastName' component={Referral} />
      <Route path='*' component={GetStarted} />
    </Switch>
  </BrowserRouter>
)

export {
  // functions
  checkSubscription,

  // components
  GetStarted,
  AuthForm,
  AuthFormLogin,
  VerificationCode,
  Referral,
  GuestRoutes
}