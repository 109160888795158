import { Checkbox } from 'antd';

const style = {
  container: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row-reverse",
    alignSelf: "flex-end",
    flexGrow: 1,
  }
}

const SelectProblemIcon = (props) => {
  const {
    problem, 
    addToSelectedProblems, 
    removeFromSelectedProblems 
  } = props

  const onChange = (e) => {
    const target = e.target
    if (target.checked) {
      addToSelectedProblems(problem)
    } else {
      removeFromSelectedProblems(problem)
    }
  }

  return (
    <div style={style.container}>
      <Checkbox onChange={onChange}/>
    </div>
  )
}

export default SelectProblemIcon;