import React, { useEffect, useState } from 'react'
import 'katex/dist/katex.min.css'
import { connect } from 'react-redux'
import { amplitudeInstance } from '../../amplitude'
import PracticeHintModal from '../Modal/PracticeHintModal'

const PracticeProblemHint = ({ title, edlyftCohort, hint, hintLink }) => {
  const [availableHints, setAvailableHint] = useState(2)
  const [isModalOpen, setOpenModal] = useState(false)
  const TOTAL_HINTS = 2

  const amplitudeData = {
    cohort: edlyftCohort,
    practiceProblemTitle: title,
  }

  useEffect(() => {
    setAvailableHint(2)
  }, [hintLink, hint])

  const handleHintClicked = () => {
    amplitudeInstance.logEvent('Clicked Hints Button Practice Problem', amplitudeData)
    setAvailableHint(1)
    setOpenModal(true)
  }

  return (
    <div className="flex items-center justify-between rounded bg-gray-lightest py-6 px-5">
      <button onClick={handleHintClicked} style={styles.hintButton} className="max-w-full text-tiny block mb-2 font-medium leading-15 rounded-md bg-blue-lightest px-2 py-2 text-center border border-blue-normal text-blue-normal">
        Hints
      </button>
      <span className="text-black font-thin leading-17 text-tiny">
        (<span className="font-medium">{availableHints}/{TOTAL_HINTS}</span> hints left)
      </span>
      <PracticeHintModal
        hint={hint}
        open={isModalOpen}
        hintLink={hintLink}
        totalHint={TOTAL_HINTS}
        availableHint={availableHints}
        setAvailableHint={setAvailableHint}
        closeModal={() => setOpenModal(false)}
      />
    </div>
  )
}

const styles = {
  hintButton: {
    width: '134px',
  }
}

const mapStateToProps = state => {
  const { user: { edlyftCohort } } = state

  return {
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(PracticeProblemHint)