// import amplitude from 'amplitude-js';
import { Button } from 'antd';

const style = {
  container: {
    position: "fixed",
    marginLeft: "260px",
    background: "white",
    padding: "2em",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "15px",
    bottom: "50px",
    boxShadow: "2px 2px 5px rgb(0 0 0 / 50%)"
  },
  displayNone: {
    display: "none"
  },
  displayFlex: {
    display: "flex"
  },
  text: {
    width: "40%"
  },
  selected: {
    width: "20%"
  },
  buttons: {
    width: "20%"
  },
  cancel: {
    color: "#1890ff"
  }
}

const QuestionSelectionStickyBar = (props) => {
  const { 
    selectedProblems, 
    showBar,
    cancelClicked,
    submitClicked,
    submitButtonLoading
    } = props

  let containerStyle, cardsCount
  if (showBar) {
    containerStyle = {
      ...style.displayFlex, 
      ...style.container 
    }
  } else {
    containerStyle = {
      ...style.displayNone
    }
  }

  if (Array.isArray(selectedProblems)) {
    cardsCount = selectedProblems.length
  } else {
    cardsCount = 0
  }

  return (
    <div style={containerStyle}>
      <div style={style.text}>
        <p>Click to select the questions you need help with and click 'submit' to notify your mentor via a slack message.</p>
      </div>
      <div style={style.selected}>
        {cardsCount} questions selected
      </div>
      <div style={style.buttons}>
        <Button 
          ghost 
          style={style.cancel}
          onClick={cancelClicked}
        >
        Cancel
        </Button>
        <Button 
          type="primary"
          onClick={submitClicked}
          loading={submitButtonLoading}
        >
        Submit
        </Button>
      </div>
    </div>
  )
}

export default QuestionSelectionStickyBar;