import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import { debounce } from '../helpers'
import { omit } from 'lodash';

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, onInputChange = () => { }, ...props }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    const debouncedOptions = debounce(loadOptions, debounceTimeout);
    return value => {
      onInputChange(value);
      debouncedOptions(value);
    };
  }, [fetchOptions, debounceTimeout, onInputChange]);

  const { notFoundText = 'not found', ...omittedProps } = omit(props, ['onSearch', 'options', 'notFoundContent']);

  return (
    <Select
      filterOption={false}
      showSearch
      {...omittedProps}
      notFoundContent={fetching ? <Spin size="small" /> : notFoundText}
      onSearch={debounceFetcher}
      options={options}
    />
  );
}

export default DebounceSelect;