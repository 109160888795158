import React, { useCallback, useState, useEffect } from 'react'
import { Modal } from 'react-responsive-modal'
import { convertSecondsToVideoDuration } from '../../utils/convertSecondsToVideoDuration'
import DeleteFileModal from './DeleteFileModal'
import 'react-responsive-modal/styles.css'
import { Tabs } from 'antd'
import { ReactComponent as RoundCloseIcon } from '../../assets/icons/x-round.svg'
import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment.svg'
import UploadSpinner from '../../assets/images/spinner.gif'
import { useDropzone } from 'react-dropzone'
import { Button } from '../Form/Button'
import DeleteIcon from '../../assets/icons/delete-blue.svg'
import ReloadIcon from '../../assets/icons/reload.svg'
import UploadBoxBorder from '../../assets/images/upload-border.svg'
import UploadInputBorder from '../../assets/images/upload-file-border.svg'
import UploadBoxBorderGray from '../../assets/images/upload-border-gray.svg'
import UploadInputBorderGray from '../../assets/images/upload-file-border-gray.svg'
import { useForm } from 'react-hook-form'
import { nanoid } from 'nanoid/non-secure'
import axios, { axiosDefault } from '../../axios-orders'
import { connect } from 'react-redux'
import UploadedItem from '../UploadedItem'
import AlertSimple from '../AlertSimple'
import { toast } from 'react-toastify'
import { currentEdlyftTerm } from '../../config'
import { amplitudeInstance } from '../../amplitude'
import NavbarDropdown from '../navbar-dropdown'
import { ReactComponent as RoundPlusIcon } from '../../assets/icons/plus-round.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-x.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import warningIcon from '../../assets/icons/warning-triangle.svg'
import _ from 'lodash'
import { getCohortTags } from '../../utils/api'

const { TabPane } = Tabs

function extractNameAndExtension(url) {
  const firstIndex = url.lastIndexOf('/')
  const secondIndex = url.lastIndexOf('.')

  const fileName = url.substring(firstIndex + 1, secondIndex)
  const extension = url.substr(secondIndex + 1, url.length)

  return { fileName, extension }
}

const TagsInput = ({
  onUpdate,
  tags: recordingTags,
  edlyftCohort,
  updateVideoTag,
  idx,
}) => {
  const [suggestedTags, setSuggestedTags] = useState([])
  const [keyword, setKeyword] = useState('')
  const [timeoutHandler, setTimeoutHandler] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (loading) {
      getCohortTags(edlyftCohort)
        .then(({ data }) => {
          setSuggestedTags(data)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [loading])

  useEffect(() => {
    if (keyword.trim().length > 0 && !recordingTags?.includes(keyword)) {
      searchKeywords()
    }
  }, [keyword, edlyftCohort])

  const onKeywordChange = useCallback(e => {
    const value = e.target.value
    setKeyword(value)
  }, [])

  const searchKeywords = useCallback(() => {
    if (timeoutHandler) {
      clearInterval(timeoutHandler)
    }

    const handler = setTimeout(() => {
      getCohortTags(edlyftCohort, keyword)
        .then(({ data }) => {
          setSuggestedTags(data)
        })
        .catch(e => {
          console.log(e)
        })
    }, 500)

    setTimeoutHandler(handler)
  }, [edlyftCohort, keyword])

  const handleSelectedKeyword = useCallback(
    tag => {
      if (!recordingTags.includes(tag)) {
        onUpdate(recordingTags.concat(tag))

        updateVideoTag(tag, idx)
      }
    },
    [recordingTags],
  )

  return (
    <div
      style={{ minHeight: '40px' }}
      className='py-1 relative rounded bg-gray-lightest form-input flex items-center justify-between'
    >
      <div className='flex-1'>
        {
          <>
            {recordingTags?.map((tag, index) => (
              <div
                key={index}
                className='my-1 relative z-10 inline-flex mr-2 text-violet-dark items-center justify-between'
                style={styles.tag}
              >
                <span className='font-thin leading-14 text-sm mr-4'>{tag}</span>
                <button
                  style={styles.tagCloseIcon}
                  type='button'
                  onClick={() => {
                    onUpdate(recordingTags.filter(t => t !== tag))
                    updateVideoTag(tag, idx)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            ))}
          </>
        }
      </div>
      <NavbarDropdown style={{ position: 'static' }}>
        {!(recordingTags?.length >= 5) && (
          <NavbarDropdown.Toggle className='flex'>
            <>
              <span className='absolute w-full h-full left-0 top-0'></span>
              <button type='button'>
                <RoundPlusIcon />
              </button>
            </>
          </NavbarDropdown.Toggle>
        )}
        <NavbarDropdown.CSSTransitionMenu
          style={styles.tagsDropdown}
          className='right-0 z-40'
          timeout={200}
        >
          <div className='bg-white' style={styles.tagsDropdownContent}>
            <div
              style={styles.tagsDropdownHeader}
              className='border-b py-4 px-5 flex items-center justify-between text-violet-dark'
            >
              <span className='text-base font-medium leading-18'>
                Add Concept Tags
              </span>
              <NavbarDropdown.Toggle className='flex'>
                <NavbarDropdown.Close>
                  <button type='button' style={styles.tagsDropdownClose}>
                    <CloseIcon />
                  </button>
                </NavbarDropdown.Close>
              </NavbarDropdown.Toggle>
            </div>
            <div
              style={styles.tagsSearch}
              className='relative border-b flex items-center justify-between text-violet-dark'
            >
              <span
                className='text-violet-dark absolute top-0 bottom-0 my-auto'
                style={styles.tagsSearchIcon}
              >
                <SearchIcon />
              </span>
              <input
                name='tags'
                value={keyword}
                onChange={onKeywordChange}
                style={styles.tagsSearchInput}
                placeholder='Search or add a new Tag'
                className='leading-17 font-thin text-gray hover:bg-white outline-none bg-white border-none w-full h-full'
              />
            </div>
            <div className='overflow-y-scroll' style={styles.tagsContent}>
              {keyword.trim().length > 1 &&
              !recordingTags?.includes(keyword) ? (
                <TagItem
                  onClick={() => handleSelectedKeyword(keyword)}
                  text={`Create '${keyword}'`}
                  disabled={recordingTags.length >= 5}
                />
              ) : (
                suggestedTags.map((tag, index) => (
                  <TagItem
                    onClick={() => handleSelectedKeyword(tag)}
                    text={tag}
                    key={index}
                    disabled={recordingTags?.length >= 5}
                  />
                ))
              )}
            </div>
          </div>
        </NavbarDropdown.CSSTransitionMenu>
      </NavbarDropdown>
    </div>
  )
}

const TagItem = ({ text, onClick, disabled }) => (
  <button
    type='button'
    onClick={onClick}
    disabled={disabled}
    className='text-left block w-full hover:bg-blue-lightest cursor-pointer hover:text-blue-normal text-gray'
  >
    <span
      style={styles.keywordItem}
      className='leading-15 font-medium text-tiny block pr-10 py-4 ml-10 border-b'
    >
      {text}
    </span>
  </button>
)

const UploadRecordingModal = ({
  open,
  closeModal,
  data,
  edlyftCohort,
  user,
  slackID,
}) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const watchSessionDate = watch('sessionDate', data?.sessionDate)
  const [isSubmitting, setSubmitting] = useState(false)
  const [progressCount, setProgressCount] = useState(0)
  const [video, setVideo] = useState(data?.video)
  const [videoUrls, setVideoUrls] = useState([])
  const [videoDurations, setVideoDurations] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [videoUploadStatus, setVideoUploadStatus] = useState(null)
  const [notes, setNotes] = useState(data?.notes ?? [])
  const [tags, setTags] = useState(data?.tags ?? [])
  const [videoTags, setVideoTags] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [activeKey, setActiveKey] = useState('1')
  const [activeVideo, setActiveVideo] = useState(false)

  function callback(key) {
    setActiveKey(key.toString())
  }

  useEffect(() => {
    setVideo(data?.video)
    data?.video && setVideoUrls([{ ...data?.video, status: 'success' }])
  }, [data?.video])

  useEffect(() => {
    setNotes(data?.notes ?? [])
  }, [data?.notes])

  useEffect(() => {
    setTags(data?.tags ?? [])
  }, [data?.tags])

  // Handle form submission

  const updateVideoUrl = (index, data) => {
    const { name, video, url, status } = data
    videoUrls[index] = { name: name, video: video, url: url, status: status }
    const previousVideos = [...videoUrls]
    setVideoUrls(previousVideos)
  }

  const switchVideoFocus = i => () => {
    setActiveVideo(`active` + i)
  }

  const onVideoBlur = () => {
    setActiveVideo(false)
  }

  const openConfirmModal = video => {
    setSelectedVideo(video)
    setModalOpen(true)
  }

  const uploadRecording = async ({
    videoTitle,
    videoDescription,
    videoLinks,
    sessionDate,
    vid,
    duration,
    videoTag,
    videoNotes,
  }) => {
    const links = videoLinks?.split(',')

    try {
      let formData = {
        videoTitle,
        sessionDate: data?.rawSessionDate || sessionDate,
        videoDescription,
        classID: edlyftCohort, // Edlyft Cohort
        edlyftTerm: currentEdlyftTerm?.type,
        uploadURL: vid ? vid.url : null,
        links,
        duration: convertSecondsToVideoDuration(duration),
        courseNotes: Array.isArray(videoNotes)
          ? videoNotes
              .filter(note => note.url)
              .map(note => ({
                id: note.id,
                name: note.name,
                url: note.url,
              }))
          : [], // Set notes to only items with a URL
        tags: videoTag,
        slackID,
      }

      // If a videoId property is present, we update the recording instead
      // by adding a videoId to the form data. When the api sees a `videoID`
      // in the form data, it updates the record instead of creating a new one
      if (data?.videoId) {
        formData['videoId'] = data.videoId
      }

      setSubmitting(true)
      const response = await axios.post('/mentor/recordings', formData)
      // If a videoId is present, this means it's an edit and not a new
      // upload
      if (!data?.videoId) {
        amplitudeInstance.logEvent('Upload Recording - Done', {
          course: edlyftCohort,
          uploadStatus: 'success',
        })
      }

      const sendEmailNotification = async () => {
        await axios.post('/common/cohort/upload-notification', {
          edlyftCohort: user.edlyftCohort,
          authorName: `${user.firstName} ${user.lastName}`,
          videoThumnail: response?.data?.s3BucketPath,
          title: response?.data?.title,
          description: response?.data?.description,
          date: response?.data?.sessionDate ? response?.data?.sessionDate : '',
          videoId: response.data['video-id'],
        })
      }

      const alertUser = () => {
        toast(
          <AlertSimple
            title={
              <div style={{ maxWidth: '281px' }} className='font-medium'>
                {data?.videoId
                  ? 'Your video has successfully been updated!'
                  : 'Your video has successfully been uploaded!'}
              </div>
            }
            closeText='Got It'
          />,
        )
      }
      if (user.edlyftCohort) {
        try {
          // (!data?.videoId) evaluates to false if the user is editing a record
          if (!data?.videoId) {
            await sendEmailNotification()
          }
          //popup successful notification when video uploads successfully
          alertUser()
          // Reload page after successful upload
          window.location.reload()
        } catch (err) {
          throw Error('Something Unexpected Went Wrong')
        }
      }
    } catch (e) {
      console.error(e)
      if (!data?.videoId) {
        amplitudeInstance.logEvent('Upload Recording - Done', {
          course: edlyftCohort,
          uploadStatus: 'error',
        })
      }
      setSubmitting(false)
      // Handle recording submission error
      toast(
        <AlertSimple
          title='Oops...An error occured'
          message={
            <div>
              Something went wrong with your video upload Course{' '}
              {vid ? <span className='font-medium'>{vid?.name}</span> : null}
            </div>
          }
          closeText='Close'
          type='error'
        />,
      )
    }
  }

  const onSubmit = async ({ videoDescription, sessionDate, meta }) => {
    if (activeKey < 2) {
      callback(Number(activeKey) + 1)
    } else {
      meta.forEach((m, index) => {
        uploadRecording({
          videoTitle: m.videoTitle,
          videoDescription,
          videoLinks: m.videoLinks,
          sessionDate,
          duration: videoDurations[index],
          vid: videoUrls[index],
          videoTag: videoTags[index]?.tag,
          videoNotes: notes[index]?.notes,
        })
      })
    }
  }

  const retryVideoUpload = i => () => {
    // Check if a video and raw file exists in state
    if (videoUrls && videoUrls.length > 0) {
      uploadVideo(videoUrls[i].video, i)
    }
  }

  const retryNoteUpload = (noteId, i) => {
    if (Array.isArray(notes)) {
      const noteToReupload = notes[i].notes.find(note => note.id === noteId)
      // Check if a video and raw file exists in state
      if (noteToReupload && noteToReupload.raw_file) {
        updateNote(
          noteId,
          {
            uploadStatus: 'processing',
          },
          i,
        )
        uploadNote(noteToReupload.raw_file, noteToReupload.id, i)
      }
    }
  }

  const uploadVideo = async (file, index) => {
    setProgressCount(0)
    updateVideoUrl(index, {
      name: file?.name,
      video: file,
      status: 'uploading',
    })
    // setVideoUrls(prevState => [...prevState, {name: file?.name, video: file, status: 'uploading'}])
    // Get presigned URL for notes upload
    await axios
      .get('/common/pre-signed/video-recordings', { params: { contentType: file.type } })
      .then(({ data }) => {
        setProgressCount(prevCount => prevCount + 1)
        const uploadURL = data.uploadURL
        const filename = data.filename
        // Create a new file with updated name
        const updatedFile = new File([file], filename, {
          type: file.type,
        })
        // Upload video to S3
        axiosDefault
          .put(uploadURL, updatedFile, {
            headers: {
              'Content-Type': file.type,
              'x-amz-acl': 'private',
            },
          })
          .then(() => {
            // Get the clean video URL without query parameters
            // from the presigned URL
            const videoURL = uploadURL.split('?')[0]

            updateVideoUrl(index, {
              name: file.name,
              url: videoURL,
              status: 'success',
            })
          })
          .catch(e => {
            // Handle S3 upload errors
            updateVideoUrl(index, {
              name: file.name,
              video: file,
              status: 'error',
            })
          })
      })
      .catch(e => {
        // Handle presigned URL error
        updateVideoUrl(index, { name: file.name, video: file, status: 'error' })
      })
  }

  const uploadNote = (file, noteId, i) => {
    // Get the mimetype
    const mime = file.type
    // Get file extension
    const extension = file.name.split('.').pop()
    // Get presigned URL for notes upload
    axios
      .get('/common/pre-signed/course-notes', {
        params: {
          mime,
          extension,
        },
      })
      .then(({ data }) => {
        const uploadURL = data.uploadURL
        const filename = data.filename

        // Create a new file with updated name
        const updatedFile = new File([file], filename, {
          type: file.type,
        })
        // Upload video to S3
        axiosDefault
          .put(uploadURL, updatedFile, {
            headers: {
              'Content-Type': mime,
              'x-amz-acl': 'private',
              'Content-Disposition': 'attachment',
            },
          })
          .then(() => {
            // Get the clean video URL without query parameters
            // from the presigned URL
            const noteURL = uploadURL.split('?')[0]
            updateNote(
              noteId,
              {
                uploadStatus: 'success',
                url: noteURL,
              },
              i,
            )
          })
          .catch(() => {
            // Handle S3 upload errors
            updateNote(
              noteId,
              {
                uploadStatus: 'error',
              },
              i,
            )
          })
      })
      .catch(() => {
        // Handle presigned URL error
        updateNote(
          noteId,
          {
            uploadStatus: 'error',
          },
          i,
        )
      })
  }

  const onDrop = useCallback(acceptedFiles => {
    // multiple videos upload
    setVideoUrls([])
    // setVideoDurations([])
    const loadVideoData = file =>
      new Promise((resolve, reject) => {
        try {
          let video = document.createElement('video')
          video.preload = 'metadata'

          video.onloadedmetadata = function () {
            resolve(this)
          }

          video.onerror = function () {
            reject('Invalid video. Please select a video file.')
          }

          video.src = window.URL.createObjectURL(file)
        } catch (e) {
          reject(e)
        }
      })
    // console.log('files dropped', acceptedFiles)
    // acceptedFiles.forEach(async (file) => {
    //   console.log('file iteration', file)

    //   // videoDuration[index]

    // })

    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach(async (video, index) => {
        const newlyAddedVideo = await loadVideoData(video)
        // console.log(video.duration)
        videoDurations[videoUrls.length] = newlyAddedVideo.duration
        setVideoDurations(videoDurations)
        uploadVideo(video, videoUrls.length)
      })
    }
  }, [])

  const handleVideoDelete = i => e => {
    e.preventDefault()
    if (videoUrls.length > 1) {
      acceptedFiles.splice(i, 1)
      let prevVideos = [...videoUrls]
      let filteredVideos = prevVideos.filter((v, key) => key !== i)
      setVideoUrls(filteredVideos)
    } else {
      acceptedFiles.splice(0)
      setVideoUrls([])
    }

    setModalOpen(false)
  }

  /**
   * Handle onChange event on notes
   */
  const handleNoteUpload = i => e => {
    // Get the uploaded file
    const file = e.target.files[0]
    // Set the noteID locally
    const noteId = nanoid()
    // Update the notes item
    if (notes[i]) {
      notes[i] = {
        id: noteId,
        notes: [
          ...notes[i].notes,
          {
            id: noteId,
            name: file.name,
            raw_file: file,
            uploadStatus: 'processing',
          },
        ],
      }
    } else {
      notes[i] = {
        id: noteId,
        notes: [
          {
            id: noteId,
            name: file.name,
            raw_file: file,
            uploadStatus: 'processing',
          },
        ],
      }
    }
    setNotes(notes)

    uploadNote(file, noteId, i)
  }

  // Update single note in state
  const updateNote = (id, data, i) => {
    // Check if notes is an array
    if (Array.isArray(notes)) {
      // Update note states
      setNotes(prevNotes => {
        // Get the index of the item to update
        const itemIndex = prevNotes[i].notes.findIndex(note => note.id == id)
        // If an item with a valid index is founf
        if (itemIndex !== -1) {
          // Create new notes array item
          let newNotes = [...prevNotes]
          // Update item with key and value from new object
          newNotes[i].notes[itemIndex] = {
            ...newNotes[i].notes[itemIndex],
            ...data,
          }
          return newNotes
        }
        return prevNotes
      })
    }
  }

  /**
   * Delete a note locally
   *
   * @param {String} id - The note id
   */
  const deleteNote = (id, i) => {
    // Check if notes is an array
    if (Array.isArray(notes[i].notes)) {
      const prevNotes = [...notes]
      prevNotes[i].notes = prevNotes[i].notes.filter(note => note.id != id)
      setNotes(prevNotes)
    }
  }

  /**
   * Update tags in state
   *
   * @param {String} tag - New tag to add
   */
  const updateTags = tags => {
    setTags(tags)
  }

  const updateVideoTag = (tag, i) => {
    if (videoTags[i]) {
      if (videoTags[i]?.tag.includes(tag)) {
        videoTags[i] = { tag: videoTags[i]?.tag.filter(t => t !== tag) }
      } else {
        videoTags[i] = { tag: [...videoTags[i]?.tag, tag] }
      }
    } else {
      videoTags[i] = { tag: [tag] }
    }
    setVideoTags(videoTags)
  }

  /**
   * Delete a tag
   *
   * @param {String} tagToDelete
   */
  const deleteTag = tagToDelete => {
    // Check if notes is an array
    if (Array.isArray(tags)) {
      setTags(tags.filter(tag => tag != tagToDelete))
    }
  }

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept: 'video/mp4,video/quicktime',
      multiple: true,
      maxFiles: 4,
    })

  // Check if there are any notes uplaoding
  const isAnyNoteUploading = Array.isArray(notes)
    ? notes.findIndex(note => note.uploadStatus === 'processing') !== -1
    : false
  // Flag to check if button should be enabled for submission
  const disableSubmitButton = !(
    videoUrls.length > 0 && _.every(videoUrls, { status: 'success' })
  )

  let inputWrapperBorderStyle = styles.uploadInputWrapper
  let inputBoxBorderStyle = styles.uploadBox
  let uploadInfoLinkStyle = {}
  // Check if edit upload modal
  if (data?.videoId) {
    // Video Upload
    if (video) {
      inputBoxBorderStyle = styles.uploadBoxGray
      uploadInfoLinkStyle = styles.uploadInfoTopStyle
    }
    // Notes
    if (!notes || (Array.isArray(notes) && notes.length == 0)) {
      inputWrapperBorderStyle = styles.uploadInputWrapperGray
    }
  }

  // Edlyft cohort that need a space between then number and text
  const cohortWithSpace = ['Math54', 'Data100', 'Math33a', 'Math61']
  // Initialize with original cohort text
  let formattedCourse = edlyftCohort
  // Check if cohort needs a space
  if (cohortWithSpace.includes(edlyftCohort)) {
    // Split by first occurence of digit and concatenate with a space
    // Math54 => 'Math 54', Math33a => 'Math 33a'
    formattedCourse = edlyftCohort.split(/(\d.+)/).join(' ')
  }

  const ATTENDANCE_URL = process.env.REACT_APP_ATTENDANCE_FORM

  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      styles={styles.modal}
      classNames={{
        modal: 'w-full overflow-y-auto',
        closeButton: 'outline-none',
      }}
      closeIcon={
        <span style={{ width: '34px', height: '34px' }}>
          <RoundCloseIcon />
        </span>
      }
    >
      <div>
        <div className='border-b px-10' style={styles.header}>
          <h2 className='text-violet-dark leading-28 text-3xl font-medium'>
            {data?.video ? 'Edit Recording' : 'Upload Recording'}
          </h2>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='pt-2 pb-16 px-10 sm:px-8'
        >
          <Tabs
            activeKey={activeKey}
            style={{ marginBottom: 32, overflowY: 'auto' }}
            tabBarStyle={{ padding: '0' }}
            onChange={callback}
          >
            <TabPane tab='1. Upload & add details' key='1'>
              <div className='sm:grid sm:grid-cols-2 gap-x-2'>
                <div
                  className='sm:px-3 sm:mx-0 sm:mb-0 mb-4'
                  style={styles.uploadSection}
                >
                  <h3 className='text-black text-xl leading-24 mb-6'>
                    Video(s)
                  </h3>
                  <span className='text-violet-dark block leading-15 text-tiny mb-1'>
                    Select up to 4 video files for upload
                  </span>
                  {videoUrls.map((file, i) => (
                    <div
                      key={i}
                      style={
                        activeVideo === `active${i}`
                          ? styles.activeVideo
                          : styles.inactiveVideo
                      }
                      className='mb-3'
                    >
                      {activeVideo === `active${i}` ? (
                        <div style={styles.pointer} />
                      ) : (
                        <div />
                      )}
                      {file.status === 'error' && (
                        <div
                          style={styles.uploadVideoError}
                          className='mb-0 px-3 py-4 flex flex-col items-center justify-center bg-red-lightest rounded-md'
                        >
                          <span className='break-all block leading-20 text-black text-lg mb-4'>
                            "{file ? file.name : null}" upload failed
                          </span>
                          <Button
                            type='button'
                            onClick={retryVideoUpload(i)}
                            className='border border-blue-normal text-blue-normal'
                            text='Try Again'
                            icon={<img src={ReloadIcon} alt='reload' />}
                            iconDirection='right'
                          />
                        </div>
                      )}
                      {file.status === 'uploading' && (
                        <div
                          style={styles.inputBoxBorderStyle}
                          className='mb-0 px-3 py-4 d-flex items-center justify-center flex-col bg-blue-lightest rounded-md'
                        >
                          <div className='flex justify-between items-center'>
                            <span>Video {i + 1}</span>
                            <button
                              className='border-0 focus:outline-none'
                              onClick={handleVideoDelete(i)}
                            >
                              <img src={DeleteIcon} />
                            </button>
                          </div>
                          <span className='break-all block leading-20 text-black text-lg mb-4'>
                            {file ? file.name : null}
                          </span>
                          {!file.url && (
                            <img
                              className='block mx-auto'
                              src={UploadSpinner}
                              style={{ width: '24px', height: '24px' }}
                            />
                          )}
                        </div>
                      )}
                      {file.status === 'success' && (
                        <div
                          className='mb-0 px-3 py-4 flex d-flex flex-col bg-green-lightest rounded-md w-full'
                          style={styles.uploadVideoSuccess}
                        >
                          <div className='flex justify-between items-center mb-4'>
                            <span>Video {i + 1}</span>
                            <button
                              className='border-0 focus:outline-none'
                              onClick={() => openConfirmModal(file)}
                            >
                              <img src={DeleteIcon} />
                            </button>
                          </div>
                          <span className='block text-center leading-20 text-black text-lg'>
                            {file?.name} uploaded.
                          </span>
                        </div>
                      )}
                    </div>
                  ))}

                  <div
                    style={inputBoxBorderStyle}
                    className='mb-3 flex items-center justify-center relative outline-none px-2 bg-blue-lightest cursor-pointer'
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p className='text-center leading-24 text-base font-medium'>
                        <span style={styles.uploadInfoTopStyle}>
                          Drag and drop or{' '}
                          <span
                            style={uploadInfoLinkStyle}
                            className='underline text-blue-normal'
                          >
                            Click here
                          </span>
                        </span>
                        <span style={styles.uploadInfoBottomStyle}>
                          <br /> to upload video
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className='sm:px-3 w-full sm:w-auto flex-1'>
                  <h3 className='text-black text-xl leading-24 mb-6'>
                    General information
                  </h3>

                  <div className='mb-6'>
                    <span className='text-violet-dark block leading-15 text-tiny mb-1'>
                      Date of Recording
                    </span>
                    <input
                      disabled={data?.rawSessionDate}
                      type='date'
                      name='sessionDate'
                      className='rounded bg-gray-lightest form-input text-black hover:text-black focus:text-black'
                      defaultValue={data?.sessionDate}
                      ref={register({ required: true })}
                    />
                    <span
                      className='leading-15 text-tiny mb-1'
                      style={styles.errorMsg}
                    >
                      {errors.sessionDate && (
                        <>
                          <img src={warningIcon} alt='warning' /> &nbsp;
                          <span> Please add a video date</span>
                        </>
                      )}
                    </span>
                  </div>

                  <div className='mb-6'>
                    <span className='text-violet-dark block leading-15 text-tiny mb-1'>
                      Recording description
                    </span>
                    <textarea
                      name='videoDescription'
                      className='rounded bg-gray-lightest form-input text-black hover:text-black focus:text-black'
                      placeholder='A summary of what you covered in the recording'
                      defaultValue={data?.description}
                      ref={register({ required: true })}
                    />
                    <span
                      className='leading-15 text-tiny mb-1'
                      style={styles.errorMsg}
                    >
                      {errors.videoDescription && (
                        <>
                          <img src={warningIcon} alt='warning' /> &nbsp;
                          <span> Please add a video description</span>
                        </>
                      )}
                    </span>
                  </div>

                  <div style={styles.divider} />

                  {videoUrls.map((field, i) => {
                    // console.log('videoUrls from fieldset', videoUrls)
                    const fieldName = `meta[${i}]`
                    return (
                      <fieldset
                        name={fieldName}
                        key={fieldName}
                        className='mb-0'
                        onFocus={switchVideoFocus(i)}
                        onBlur={onVideoBlur}
                      >
                        <h3 className='text-black text-xl leading-24 mb-6'>
                          Video {i + 1} details
                        </h3>

                        <div className='mb-4'>
                          <span className='text-violet-dark block leading-15 text-tiny mb-1'>
                            Recording title
                          </span>
                          <input
                            name={`${fieldName}.videoTitle`}
                            className='rounded bg-gray-lightest form-input text-black hover:text-black focus:text-black'
                            placeholder='Enter the topic covered'
                            defaultValue={data?.videoTitle}
                            ref={register({
                              required: true,
                              validate: value => value.length > 0,
                            })}
                          />
                          <span
                            className='leading-15 text-tiny mb-1'
                            style={styles.errorMsg}
                          >
                            {errors.meta && errors.meta[i]?.videoTitle && (
                              <>
                                <img src={warningIcon} alt='warning' /> &nbsp;
                                <span> Please add a video title</span>
                              </>
                            )}
                          </span>
                        </div>

                        <div className='mb-4'>
                          <span className='text-violet-dark block leading-15 text-tiny mb-1'>
                            Links (separate by commas)
                          </span>
                          <input
                            name={`${fieldName}.videoLinks`}
                            className='rounded bg-gray-lightest form-input text-black hover:text-black focus:text-black'
                            placeholder=''
                            defaultValue={data?.links}
                            ref={register()}
                          />
                          <span
                            className='leading-15 text-tiny mb-1'
                            style={styles.errorMsg}
                          >
                            {errors.meta && errors.meta[i]?.videoLinks && (
                              <>
                                <img src={warningIcon} alt='warning' /> &nbsp;
                                <span> Please add a video link</span>
                              </>
                            )}
                          </span>
                        </div>

                        <div className='mb-4'>
                          <span className='text-violet-dark block leading-15 text-tiny mb-1'>
                            Tags
                          </span>
                          <TagsInput
                            idx={i}
                            tags={videoTags[i]?.tag ?? []}
                            onUpdate={updateTags}
                            updateVideoTag={updateVideoTag}
                            edlyftCohort={edlyftCohort}
                          />
                        </div>

                        <div>
                          <div className=''>
                            <span
                              style={{ color: 'rgba(102, 102, 102, 0.3)' }}
                              className='block mb-1 leading-15'
                            >
                              Notes
                            </span>
                            <div
                              style={inputWrapperBorderStyle}
                              className='relative bg-blue-lightest'
                            >
                              <div
                                style={{ top: '-2px' }}
                                className='relative flex items-center'
                              >
                                <span
                                  style={{ width: '24px', height: '24px' }}
                                  className='text-blue-normal'
                                >
                                  <AttachmentIcon />
                                </span>
                                <span
                                  style={{ color: 'rgba(51, 51, 51, 0.38)' }}
                                  className='text-sm ml-2 leading-20'
                                >
                                  Upload files (.jpg, .png, .pdf, .pptx)
                                </span>
                              </div>
                              <input
                                accept='image/*,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                onChange={handleNoteUpload(i)}
                                style={styles.uploadInput}
                                type='file'
                                name='note'
                                className='opacity-0 absolute'
                              />
                            </div>
                          </div>
                          {Array.isArray(notes[i]?.notes) && (
                            <>
                              {notes[i].notes.map(note => {
                                let actionText
                                switch (note.uploadStatus) {
                                  case 'processing':
                                    actionText = 'Uploading...'
                                    break

                                  case 'error':
                                    actionText = (
                                      <span className='text-red-normal'>
                                        Upload Failed{' '}
                                        <span className='underline font-medium'>
                                          Try Again
                                        </span>
                                      </span>
                                    )
                                    break

                                  default:
                                    actionText = 'X Delete'
                                    break
                                }

                                return (
                                  <UploadedItem
                                    key={note.id}
                                    title={note.name}
                                    onActionClick={
                                      note.uploadStatus === 'error'
                                        ? () => retryNoteUpload(note.id, i)
                                        : () => deleteNote(note.id, i)
                                    }
                                    disableAction={
                                      note.uploadStatus === 'processing'
                                    }
                                    actionText={actionText}
                                    className='text-tiny'
                                  />
                                )
                              })}
                            </>
                          )}
                        </div>

                        <div style={styles.divider} />
                      </fieldset>
                    )
                  })}
                </div>
              </div>
            </TabPane>
            <TabPane tab='2. Take attendance' key='2'>
              <div className=''>
                <p className='text-tiny'>
                  If this upload is from a live session, complete the attendance
                  form below. Otherwise, skip and click ‘Save’.
                </p>

                <iframe
                  className='airtable-embed'
                  src={ATTENDANCE_URL}
                  frameBorder='0'
                  width='100%'
                  height='533'
                  style={{
                    background: 'transparent',
                    border: '1px solid #ccc',
                  }}
                ></iframe>
              </div>
            </TabPane>
          </Tabs>

          <div className='flex flex-wrap sm:flex-no-wrap justify-between items-center sm:px-3 mt-8'>
            {videoUploadStatus === 'uploading' && (
              <div className=''>
                <span className='flex items-center space-x-2'>
                  <img
                    src={UploadSpinner}
                    style={{ width: '24px', height: '24px' }}
                  />
                  <span className='text-gray-light text-tiny leading-20 whitespace-no-wrap'>
                    Uploading {`${progressCount}/${acceptedFiles.length}`}
                  </span>
                </span>
              </div>
            )}
            <div className='w-full flex justify-end'>
              <Button
                disabled={disableSubmitButton || isSubmitting}
                className='bg-blue-normal'
                type='submit'
                text={
                  isSubmitting
                    ? 'Uploading...'
                    : disableSubmitButton &&
                      (isAnyNoteUploading || videoUploadStatus === 'uploading')
                    ? 'Please wait...'
                    : activeKey < 2
                    ? 'Next'
                    : 'Save'
                }
              />
            </div>
          </div>
        </form>

        <DeleteFileModal
          open={isModalOpen}
          closeModal={() => setModalOpen(true)}
          videoUrls={videoUrls}
          selectedVideo={selectedVideo}
          handleVideoDelete={handleVideoDelete}
        />
      </div>
    </Modal>
  )
}

// Overriding default file input, we need to manually do
// some calculation to set the width, height and position
// so it blends with the border image. We use the section
// width and padding to get the width
const UPLOAD_SECTION_WIDTH = 300 //248;
const COLUMNS_PADDING = 24
const baseUploadInputWrapper = {
  height: '40px',
  borderRadius: '10px',
  borderWidth: '10px',
}
const baseUploadBoxWrapper = {
  borderRadius: '10px',
  borderWidth: '10px',
  height: '178px',
}

const GREY_COLOR = '#EFEFEF'

const styles = {
  errorMsg: {
    color: '#EB6469',
    display: 'flex',
    marginTop: '5px',
  },
  tagsContent: {
    maxHeight: '145px',
  },
  keywordItem: {
    borderColor: GREY_COLOR,
  },
  tagsSearchInput: {
    padding: '16px 20px 16px 50px',
  },
  tagsSearchIcon: {
    width: '12px',
    height: '12px',
    left: '20px',
  },
  tagsSearch: {
    borderColor: GREY_COLOR,
  },
  tagsDropdownHeader: {
    borderColor: GREY_COLOR,
  },
  tagsDropdownClose: {
    width: '18px',
    height: '18px',
  },
  tag: {
    padding: '4px 12px 4px 8px',
    background: 'rgba(249, 215, 114, 0.3)',
    border: '0.7px solid #F9D772',
    borderRadius: '100px',
  },
  tagCloseIcon: {
    width: '10px',
    height: '10px',
  },
  tagsDropdownContent: {
    width: '242px',
    boxShadow: '0px 10px 25px rgba(151, 143, 175, 0.08)',
    border: '1px solid #E4E9F2',
    borderRadius: '10px',
  },
  tagsDropdown: {
    top: '48px',
  },
  uploadInfoTopStyle: {
    color: 'rgba(30, 17, 71, 0.48)',
  },
  uploadInfoBottomStyle: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
  uploadVideoSuccess: {
    height: '126px',
    border: '1px solid rgba(120, 202, 149, 0.45)',
  },
  uploadVideoError: {
    border: '1px solid rgba(235, 100, 105, 0.45)',
    // backgroundColor: '#FEF8EF'
  },
  uploadingSpinner: {
    // width: '90px',
    // height: '100px',
  },
  uploadingTextInfo: {
    maxWidth: '172px',
  },
  uploadSection: {
    width: `${UPLOAD_SECTION_WIDTH + COLUMNS_PADDING}px`,
  },
  uploadInput: {
    height: '40px',
    top: '-10px',
    left: '-10px',
    width: `${UPLOAD_SECTION_WIDTH - COLUMNS_PADDING}px`,
  },
  uploadInputWrapper: {
    ...baseUploadInputWrapper,
    borderImage: `url(${UploadInputBorder}) 10 repeat`,
  },
  uploadInputWrapperGray: {
    ...baseUploadInputWrapper,
    backgroundColor: 'rgba(234, 234, 234, 0.2)',
    borderImage: `url(${UploadInputBorderGray}) 10 repeat`,
  },
  uploadBox: {
    ...baseUploadBoxWrapper,
    borderImage: `url(${UploadBoxBorder}) 4% round`,
  },
  uploadBoxGray: {
    ...baseUploadBoxWrapper,
    backgroundColor: 'rgba(234, 234, 234, 0.2)',
    borderImage: `url(${UploadBoxBorderGray}) 4% round`,
  },
  activeVideo: {
    position: 'relative',
    borderRadius: '0.5rem',
    border: '4px solid var(--color-blue)',
  },
  inactiveVideo: {
    borderRadius: '0.5rem',
    border: '4px solid transparent',
  },
  pointer: {
    position: 'absolute',
    top: 0,
    right: '-20px',
    transform: 'translateY(100%)',
    width: 0,
    height: 0,
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderLeft: '20px solid var(--color-blue)',
  },
  header: {
    borderColor: 'var(--color-gray-lighter-hard)',
    paddingTop: '30px',
    paddingBottom: '22px',
  },
  divider: {
    borderTop: '1px solid var(--color-gray-lighter-thin)',
    height: '1px',
    margin: '24px 0',
  },
  modal: {
    overlay: {
      zIndex: '2000',
    },
    modal: {
      padding: 0,
      maxWidth: '731px',
      borderRadius: '20px',
      maxHeight: '728px',
    },
    closeButton: {
      right: '40px',
      top: '24px',
    },
  },
}

const mapStateToProps = state => {
  const { user } = state
  const { edlyftCohort, slackID } = user

  return {
    edlyftCohort,
    user,
    slackID,
  }
}
export default connect(mapStateToProps)(UploadRecordingModal)
