import {
    SET_STUDENT_MENTORS
} from '../types'

const INITIAL_STATE = {
    mentors: [],
    courseNumbers: {}
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_STUDENT_MENTORS:
            return {
                ...state,
                mentors: action.mentors,
                courseNumbers: action.courseNumbers
            }

        default: return state
    }
}

export default reducer
