import React from 'react'
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg'
import { connect } from 'react-redux'
import SidebarContent from './SidebarContent'

const PremiumCard = React.lazy(() => import('../PremiumCard'))

const Sidebar = ({ isMentor, firstName, lastName }) => {

  let referalName = `${firstName}Rox`
  if (firstName === 'Harry' && lastName === 'Li') {
    referalName = `${firstName}LRox`
  }
  return (
    <div className="app-sidebar flex flex-col justify-between bg-gray-lightest pb-10 hidden vlg:block">
      <div>
        <div style={{ width: '80px', height: '40px' }} className="ml-16 mr-6 mt-4 mb-24 inline-block">
          <LogoIcon />
        </div>
        <SidebarContent className="mx-4" />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const {user: {isMentor, firstName}} = state

  return {
    isMentor,
    firstName,
  }
}


export default connect(mapStateToProps)(Sidebar)