import React, { useState } from 'react'
import Logo from '../../assets/images/edlyft-logo.png'
import { GoogleButton, FacebookButton, Button } from '../Form/Button'
import { useForm } from 'react-hook-form'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'

import { Auth } from 'aws-amplify';

// need to still add error states (like -- user with this email already exists)

const Label = ({ text, htmlFor }) => (
  <label htmlFor={htmlFor} className="text-tiny leading-15 mb-1 block" style={{ color: 'rgba(102, 102, 102, 0.3)' }}>
    {text}
  </label>
)

async function signIn(email, password) {
  try {
    const user = await Auth.signIn(email, password);
  } catch (error) {
    console.log('error signing in', error);
  }
}



const AuthFormLogin = ({ title }) => {
  const [showPassword, setShowPassword] = useState(false)
  const { register, handleSubmit } = useForm()

  const handleFormSubmit = (data) => {
    signIn(data.email, data.password);
    console.log(data);
  }
  //remove the onclick; data includes name and passwork. Call signUp


  return (
    <div className="py-10 px-8">
      <img className="mb-16 mx-auto" style={{ width: '88px' }} src={Logo} />
      <h1 className="mb-6 font-medium text-8xl text-center text-center text-violet-dark leading-40">{title}</h1>
      <div style={{ maxWidth: '400px', boxShadow: '0px 10px 50px rgba(5, 76, 70, 0.03)', borderWidth: '1px' }} className="p-8 rounded-lg border-gray-lighter-hard">
        <GoogleButton className="mb-4 bg-gray-lightest" text="Log In with Google" />
        <FacebookButton className="mb-10" text="Log In with Facebook" style={{ backgroundColor: 'rgba(60, 90, 154, 0.1)' }} />
        <span className="leading-18 text-base text-gray-dark block text-center mb-8">Or Log In with Email:</span>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mb-4">
            <Label text="Email Address" htmlFor="email" />
            <input style={{ color: '#BFBFBF' }} placeholder="Enter your email address" className="font-light rounded form-input bg-gray-lightest" id="email" type="email" name="email" ref={register} />
          </div>
          <div className="mb-4">
            <Label text="Password" htmlFor="password" />
            <div className="relative flex items-center">
              <input style={{ color: '#BFBFBF' }} placeholder="Create a password" className="font-light rounded form-input bg-gray-lightest" id="password" type={showPassword ? "text" : "password"} name="password" ref={register} />
              <span style={{ right: '10px' }} onClick={() => setShowPassword(!showPassword)} className="absolute cursor-pointer">
                <EyeIcon />
              </span>
            </div>
          </div>
          <Button className="bg-blue-normal block w-full" type="submit" text="Log In" />
        </form>
      </div>
      <span className="mt-12 text-lg font-thin block text-center text-black">
        Do not have an account? <a className="font-medium underline" href="/signup"> Sign Up</a>
      </span>
    </div>
  )
}



export default AuthFormLogin;