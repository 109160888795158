import { ReactComponent as StarUnmarkedIcon } from '../../assets/icons/star-unmarked.svg'
import { ReactComponent as StarMarkedIcon } from '../../assets/icons/star-marked.svg'
import styled from 'styled-components'

const getIconBaseStyles = (width, height) => `
  width: ${width ? width : '17px'};
  height: ${height ? height : '16px'};
  
  &:hover {
    stroke: #BABABA;
    stroke-width: 1px;
    stroke-linejoin: round;
    cursor: pointer;
  }
`

export const StarUnmarked = styled(StarUnmarkedIcon)`
  ${({ width, height }) => getIconBaseStyles(width, height)};
`
export const StarMarked = styled(StarMarkedIcon)`
  ${({ width, height }) => getIconBaseStyles(width, height)};
`
