import { SET_STUDY_TASKS_PAYLOAD, GET_STUDY_TASKS_PAYLOAD } from '../types'

const INITIAL_STATE = {
  tasks: []
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STUDY_TASKS_PAYLOAD:
      return {
        ...state,
       ...action.tasks
      }

    case GET_STUDY_TASKS_PAYLOAD:
      return {
        ...state,
       tasks: action.tasks
      }

    default:
      return state
  }
}

export default reducer
