// src/index.js

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
// import { Auth0Provider } from "./react-auth0-spa";
import { Auth0Provider } from '@auth0/auth0-react'
import history from './utils/history'
import 'antd/dist/antd.css'
import { amplitudeInstance } from './amplitude'
import { Provider as ReduxProvider } from 'react-redux'
import store from './redux'
const { NODE_ENV } = process.env
if (NODE_ENV !== 'development') {
  console.log = () => {}
  console.info = () => {}
  console.warn = () => {}
}
//Setup Amplitude
amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
  domain: window.location.hostname,
})

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  // Log the authentication event
  amplitudeInstance.logEvent('Login')
  let redirectURL =
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  // Append subscriptionId to redirect URL after authentication
  if (appState && appState.suid) {
    redirectURL = `${redirectURL}?suid=${appState.suid}`
  }
  history.push(redirectURL)
}

ReactDOM.render(
  <ReduxProvider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      useRefreshTokens={true}
      cacheLocation='localstorage'
    >
      <App />
    </Auth0Provider>
  </ReduxProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
