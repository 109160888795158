// import axios from '../../../axios-orders'
import { connect } from 'react-redux'
import ExamPrep from './ExamPrep'
import withPageWrapper from '../../../hoc/AuthPageWrapper'

import { setStudentMentors } from '../../../redux/actions/mentors'
import CommonSidebar from '../../ViewsContent/CommonSidebar'

const mapStateToProps = state => {
  const { user, mentors } = state
  const edlyftCohort = user.edlyftCohort

  return {
    edlyftCohort,
    user,
    // EG: for some reasons which are beyond of my understanding,
    //     the mentor object is a complex object of:
    //     ...
    //     mentors: {
    //        mentors: []
    //        courseNumbers: {}
    //     }
    //
    //     ... Hopefully this is going to change soon.
    mentorsObject: mentors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMentors: (mentors, courseNumbers) => {
      dispatch(setStudentMentors(mentors, courseNumbers))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPageWrapper(ExamPrep, 'exam-prep', undefined, undefined, CommonSidebar))
