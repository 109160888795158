import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { Page } from '../contexts/page'

export default (Component, pageName, backLink, backLinkTitle) => {
  return class AuthPageWrapper extends React.PureComponent {
    render() {
      return (
        <Page.Provider value={{ currentPageName: pageName }}>
          <div className='flex'>
            <Sidebar />
            <div className='app-page-content flex-1 overflow-hidden'>
              <Header
                disableCourseSwitcher={pageName === 'single-video'}
                backLink={backLink}
                backLinkTitle={backLinkTitle}
              />
              <div className='flex main'>
                <div className='flex-1 overflow-hidden pb-10'>
                  <Component {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Page.Provider>
      )
    }
  }
}
