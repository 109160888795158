import {
  FETCH_AUTH0_TOKEN_ERROR,
  FETCH_AUTH0_TOKEN_COMPLETE,
  FETCH_AUTH0_TOKEN_IN_PROGRESS,
} from '../types'

export const fetchAuth0TokenError = () => {
  return {
    type: FETCH_AUTH0_TOKEN_ERROR,
  }
}

export const fetchedAuth0Token = (token) => {
  return {
    type: FETCH_AUTH0_TOKEN_COMPLETE,
    token,
  }
}