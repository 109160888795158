import React from 'react'
import Switch from 'react-switch'
import { connect } from 'react-redux'
import { switchCourse as switchCourseAction } from '../../redux/actions/user'
import { getCommonCohortTitle } from '../../helpers'

const SWITCH_HANDLE_COLOR = '#4081EB'

const Course = ({ title, isActive }) => (
  <span
    className={
      isActive
        ? 'text-blue-normal font-bold xl'
        : 'font-medium text-blue-darkest lg opacity-50'
    }
  >
    {title}
  </span>
)

const CourseSwitcher = ({
  courses,
  edlyftCohort,
  switchCourse,
  allCourses,
}) => {
  const changeActiveCourse = () => {
    // Toggle the active courses.
    // P.S number of courses is always 2
    const newActiveCourse =
      edlyftCohort === courses[1] ? courses[0] : courses[1]
    switchCourse(newActiveCourse)
    const event = new CustomEvent('newActiveCourse', {
      detail: {
        newActiveCourse,
      },
    })

    window.dispatchEvent(event)
  }

  return (
    <div className='flex items-center'>
      <Course
        title={getCommonCohortTitle(courses[0], allCourses)}
        isActive={edlyftCohort === courses[0]}
      />
      <Switch
        onChange={() => changeActiveCourse()}
        checked={edlyftCohort === courses[1]}
        checkedIcon={null}
        uncheckedIcon={null}
        onHandleColor={SWITCH_HANDLE_COLOR}
        offHandleColor={SWITCH_HANDLE_COLOR}
        className='mx-3 bg-gray course-switcher'
        height={23}
        width={37}
      />
      <Course
        title={getCommonCohortTitle(courses[1], allCourses)}
        isActive={edlyftCohort === courses[1]}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const {
    user,
    edlyft: { courses: allCourses },
  } = state
  const { edlyftCohort, courses } = user

  return {
    edlyftCohort,
    courses,
    allCourses,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    switchCourse: course => dispatch(switchCourseAction(course)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSwitcher)
