import axios from '../../axios-orders'

export const getStudentMentors = async courses => {
  const { data, status } = await axios.post('/student/mentors', {
    cohorts: courses,
  })

  if (!(200 <= status && status < 300))
    throw new Error('Getting student mentors did not return an ok status')

  const { mentors, courseNumbers } = data
  return { mentors, courseNumbers }
}
