import React, { useEffect, useRef, useMemo } from 'react'
import VideoPlayer from 'plyr-react'

import amplitude from 'amplitude-js'
import { amplitudeInstance } from '../../../../amplitude'
import useMetricsApi, { NPPEvent } from '../../../../hooks/useMetricsApi'
import { connect } from 'react-redux'
import { saveInteraction } from '../../../../utils'

const Player = props => {
  const {
    problem,
    edlyftCohort,
    isMentor,
    screenOut,
    isActive,
    firstName,
    lastName,
    userId,
  } = props

  const ref = useRef()
  const options = useMemo(
    () => ({
      speed: {
        selected: 1,
        options: [1, 1.25, 1.5, 2],
      },
      ratio: '16:9',
      controls: [
        'rewind',
        'play',
        'fast-forward',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'fullscreen',
        'settings',
      ],
    }),
    [],
  )

  useEffect(() => {
    const player = ref.current.plyr
    if (player.source) {
      if (problem) {
        player.on('playing', trackPlaying(problem))
      } else {
        player.off('playing')
      }
    }
  }, [problem])

  const { enqueue } = useMetricsApi()

  const trackPlaying = problem => {
    const identify = new amplitude.Identify()
    amplitudeInstance.identify(identify)
    const eventName = 'Clicked Play on a Solution Video'
    amplitudeInstance.logEvent(eventName, {
      ...problem,
    })
    enqueue(NPPEvent)
    saveInteraction({
      resourcePoster: '',
      edlyftCohort,
      resourceType: 'practiceProblem',
      resourceId: problem.id,
      screenOut,
      userId,
      isActive,
      isMentor,
      resourceTitle: eventName,
      userName: `${firstName} ${lastName}`,
    })
  }

  const playerView = () => (
    <VideoPlayer
      source={{
        type: 'video',
        title: problem?.title,
        sources: [
          {
            src: problem?.solutionVideoUrl,
            type: 'video/mp4',
          },
        ],
        poster: problem?.solutionImageUrl,
      }}
      options={options}
      ref={ref}
    />
  )

  return playerView()
}

const mapStateToProps = ({
  user: {
    edlyftCohort,
    isMentor,
    screenOut,
    isActive,
    firstName,
    lastName,
    userId,
  },
}) => ({
  edlyftCohort,
  isMentor,
  screenOut,
  isActive,
  firstName,
  lastName,
  userId,
})

export default connect(mapStateToProps)(Player)
