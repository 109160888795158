import React from 'react'
import withPageWrapper from '../../../hoc/AuthPageWrapper'
import CommonSidebar from '../../ViewsContent/CommonSidebar'
import jstz from 'jstz';

const { REACT_APP_TECHX_CALENDAR } = process.env
const timezone = jstz.determine();
let userTime = REACT_APP_TECHX_CALENDAR + "&ctz=" + encodeURIComponent(timezone.name());

const TechXSessions = () => {

  return <>
    <h1 className='my-0 font-mono font-semibold text-5xl tracking-tight text-violet-dark font-'>All TechX Sessions</h1>
    <p className='text-gray-70 max-w-lg'>If you're unable to attend your mentor’s session this week, feel free to join another mentor's session.</p>

    <iframe title='techx-calendar' src={userTime} style={{borderWidth: 0}} className='mt-6 w-full' height="600" frameborder="0" />
  </>
}

export default withPageWrapper(TechXSessions, 'techx-sessions', null, null, CommonSidebar)