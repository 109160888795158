import {
    SET_STUDENT_MENTORS
} from '../types'

export const setStudentMentors = (mentors, courseNumbers) => {
    return {
        type: SET_STUDENT_MENTORS,
        mentors,
        courseNumbers,
    }
}
