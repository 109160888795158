import React, { useState, useCallback, useRef } from 'react'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import { connect } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-x.svg'
import DebounceSelect from '../../DebounceSelect'
import { getCohortTags } from '../../../utils/api'

const TagInput = ({ tags, onSelect, onDelete, max = 5, edlyftCohort }) => {
  const [keyword, setKeyword] = useState('')
  const dropdownContainerRef = useRef()

  const queryConceptTagOptions = word =>
    getCohortTags(edlyftCohort, word, 3).then(({ data }) =>
      data.map(tag => ({ label: tag, value: tag })),
    )

  const handleSelectedKeyword = tag => {
    onSelect(tag)
    setKeyword('')
  }
  const handleChangeKeyword = word => setKeyword(word)

  const listAndCreateDropdown = useCallback(
    menu => {
      const addNewConceptTag = e => {
        e.preventDefault()
        onSelect(keyword)
        setKeyword('')
      }

      return (
        <>
          {menu}
          {keyword.length > 0 && (
            <>
              <Divider
                style={{
                  margin: '0.5rem 0.75rem',
                  width: 'auto',
                  minWidth: 'auto',
                }}
              />
              <button
                onClick={addNewConceptTag}
                className='text-left inline-flex items-center gap-3 cursor-pointer text-gray-normal p-1 my-1 mx-2'
              >
                <span className='text-gray-darkest'>Create new tag</span>
                <PlusOutlined className='text-xl' />
              </button>
            </>
          )}
        </>
      )
    },
    [keyword, onSelect],
  )

  const underTagLimit = tags.length < max

  return (
    <div className='tag-list rounded-inherit'>
      {underTagLimit && (
        <div
          className='text-gray-normal flex items-center justify-between bg-gray-lightest relative rounded-t-inherit'
          ref={dropdownContainerRef}
        >
          <SearchOutlined className='ml-3 text-xl' />
          <DebounceSelect
            value={keyword}
            searchValue={keyword}
            notFoundText={keyword}
            fetchOptions={queryConceptTagOptions}
            onSelect={handleSelectedKeyword}
            onInputChange={handleChangeKeyword}
            style={{ width: '100%' }}
            getPopupContainer={() => dropdownContainerRef.current}
            dropdownRender={listAndCreateDropdown}
            size='large'
            showArrow={false}
          />
        </div>
      )}
      <div
        style={{ minHeight: 40 }}
        className={`w-full text-base text-justify resize-none bg-gray-lightest outline-none p-2 leading-5 font-light border-gray-focus ${
          underTagLimit ? 'rounded-b-inherit border-t' : 'rounded-inherit'
        }`}
      >
        <div className='flex gap-1 flex-wrap'>
          {tags.map((tag, index) => (
            <div
              key={index}
              className='text-blue-normal px-3 py-1 inline-flex text-tiny items-center gap-1 bg-blue-lighter rounded-3xl'
            >
              <span className='font-normal text-violet-dark'>{tag}</span>
              <span
                className='h-6 w-6 cursor-pointer'
                onClick={() => onDelete(tag)}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const {
    user: { edlyftCohort },
  } = state

  return {
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(TagInput)
