import { toast } from 'react-toastify'

import { express } from '../../axios-orders'
import AlertSimple from '../../components/AlertSimple'

export const starPracticeProblem = async ({
  edlyftCohort,
  practiceProblemId,
}) => {
  try {
    const { data } = await express.post('/practice-problem/star', {
      edlyftCohort,
      practiceProblemId,
    })

    return data
  } catch (error) {
    toast(
      <AlertSimple
        title='Oops...An error occured'
        message='Something went wrong while trying to star a problem. Check your internet connection.'
        closeText='Got It'
        type='error'
      />,
    )

    throw error
  }
}
