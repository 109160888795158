import React from 'react'
import ContentLoader from 'react-content-loader'

const BlockLoader = ({ width, height }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#EEEEF0"
    foregroundColor="#f9f9f9"
    style={{
      maxWidth: '100%',
      width: `${width}px`,
      maxHeight: `${height}px`,
      height: 'auto'
    }}
  >
    <rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
  </ContentLoader>
)

export default BlockLoader