export const currentEdlyftTerm = { type: 'fall2023', title: 'Fall 2023' }

// { type: 'spring2023', title: 'Spring 2023', }
export const pastCohortRecordings = {
  ALL_Prep10A: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep10B: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20A: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20B: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20C: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20E: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20F: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20D: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_Prep20G: [{ type: 'summer2022', title: 'Summer 2022', }],
  ALL_CareerPrep: [{ type: 'summer2022', title: 'Summer 2022', }],
  HOW_CS135: [{ type: 'spring2022', title: 'Spring 2022' }],
  HOW_CS136: [{ type: 'spring2022', title: 'Spring 2022' }],
  UCB_CS61A: [
    { type: 'fall2020', title: 'Fall 2020' },
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'summer2021', title: 'Summer 2021' },
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UCB_CS61B: [
    { type: 'fall2020', title: 'Fall 2020' },
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'summer2021', title: 'Summer 2021' },
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UCB_CS70: [
    { type: 'fall2020', title: 'Fall 2020' },
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'summer2021', title: 'Summer 2021' },
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UCB_CS61C: [
    { type: 'fall2020', title: 'Fall 2020' },
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'summer2021', title: 'Summer 2021' },
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UMD_CS131: [
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UMD_CS132: [
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UNC_CS210: [
    { type: 'spring2021', title: 'Spring 2021' },
    { type: 'fall2021', title: 'Fall 2021' }
  ],
  NCT_CS167: [{ type: 'spring2022', title: 'Spring 2022' }],
  TCX_APD1: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD2: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD3: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD4: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD5: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD6: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD7: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD8: [{ type: 'spring2022', title: 'Spring 2022' }, { type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD9: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD10: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD11: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD12: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD13: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD14: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD15: [{ type: 'spring2023', title: 'Spring 2023' }],
  TCX_APD16: [{ type: 'spring2023', title: 'Spring 2023' }],
  UNC_CS110: [
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UNC_CS301: [{ type: 'spring2022', title: 'Spring 2022' }],
  UNC_CS211: [{ type: 'fall2021', title: 'Fall 2021' }],
  SJS_CS46A: [{ type: 'spring2022', title: 'Spring 2022' }],
  SJS_CS46B: [{ type: 'spring2022', title: 'Spring 2022' }],
  SJS_CS146: [
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  SJS_Math42: [
    { type: 'fall2021', title: 'Fall 2021' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  SJS_CS102: [{ type: 'fall2021', title: 'Fall 2021' }],

  UCB_Data100: [
    { type: 'fall2020', title: 'Fall 2020' },
    { type: 'spring2022', title: 'Spring 2022' }
  ],
  UCB_Math54: [{ type: 'spring2022', title: 'Spring 2022' }],
  ALL_TechnicalInterviewPrep: [{type: 'summer2021', title: 'Summer 2021'}],
  UMD_CS250: ''
}

export const defaultTimezone = 'America/Los_Angeles'
export const rewardClasses = [
  'CS61A',
  'CS61B',
  'CS61C',
  'CS70',
  'CS100',
  'CS135',
  'CMSC131',
  'CMSC132'
]
