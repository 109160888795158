import React, { useState } from 'react'
import { Button } from './Form/Button'
import { ReactComponent as UploadIcon } from '../assets/icons/upload.svg'
import UploadRecordingModal from './Modal/UploadRecordingModal'
import { amplitudeInstance } from '../amplitude'

const UploadRecording = ({
  description,
  title,
  titleClass,
  descriptionStyle = {},
  edlyftCohort,
  fromPage,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const onUploadClick = () => {
    setModalOpen(true)
    amplitudeInstance.logEvent('Upload Recording - Begin', {
      course: edlyftCohort,
      fromPage,
    })
  }

  return (
    <>
      <div className='bg-blue-lightest rounded py-10 pl-10 pr-8 md:flex md:items-end md:flex-row md:justify-between'>
        <div>
          <h3 className={`mb-2 text-3xl font-medium leading-28 ${titleClass}`}>
            {title}
          </h3>
          <span
            style={{ maxWidth: '296px', ...descriptionStyle }}
            className='font-light block text-tiny leading-20 text-gray-dark'
          >
            {description}
          </span>
        </div>
        <Button
          onClick={onUploadClick}
          icon={<UploadIcon />}
          text='Upload Recording'
          className='bg-blue-normal'
        />
      </div>
      <UploadRecordingModal
        open={isModalOpen}
        closeModal={() => setModalOpen(false)}
      />
    </>
  )
}


export default UploadRecording
