import React from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import 'react-file-previewer/dist/styles.css'
import { Button } from '../Form/Button'
import cautionIcon from '../../assets/icons/caution.svg'

const DeleteFileModal = ({ open, closeModal, videoUrls, selectedVideo, handleVideoDelete }) => {
  const index = videoUrls.findIndex(v => v?.name === selectedVideo?.name)

  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      styles={styles.modal}
      classNames={{
        modal: 'max-w-full w-full',
        closeButton: "outline-none",
      }}
      showCloseIcon={false}
    >
      <div style={styles.container}>
        <img style={styles.imgStyle} src={cautionIcon} alt='caution' />
        <div style={styles.title}>{`Do you want to delete ${selectedVideo?.name} file?`}</div>
      
        <div className='flex justify-evenly'>
          <Button
            noBackgroundColor={true}
            type='submit'
            text='Close'
            onClick={closeModal}
            className="font-medium text-lg"
          />
          <Button
            onClick={handleVideoDelete(index)}
            className='bg-blue-normal text-lg'
            type='button'
            text='Delete'
          />
        </div>
      </div>
    </Modal >
  )
}

const styles = {
  modal: {
    overlay: {
      zIndex: '2000'
    },
    modal: {
      padding: 0,
      maxWidth: "526px",
      borderRadius: "20px",
    }
  },
  imgStyle: {
    margin: '0 auto'
  },
  container: {
    width: "480px",
    margin: '4em auto',
    textAlign: 'center',
    color: '#2C2543'
  },
  title:{
    fontWeight: '500',
    fontSize: '26px',
    paddingTop: '1em',
    marginBottom: '2em',
  },
}

export default DeleteFileModal
