import { SET_UPDATE_BOOKING_MODAL } from '../types'

const INITIAL_STATE = {
  openModal: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_UPDATE_BOOKING_MODAL:
      return {
        ...state,
        openModal: action.modalState
      }

    default:
      return state
  }
}

export default reducer
