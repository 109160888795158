import React, { useState, useMemo } from 'react'
import {
  PictureTwoTone,
  DeleteTwoTone,
  LoadingOutlined,
} from '@ant-design/icons'
import { Upload, Spin } from 'antd'
import fileSize from 'filesize'
import { omit } from 'lodash'

const { Dragger } = Upload
const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const FileCard = ({ name, size, onDelete = () => {}, fileIcon }) => {
  const readableSize = useMemo(() => fileSize(size), [size])

  return (
    <div className='flex flex-row flex-no-wrap gap-4 items-center text-xl p-4 bg-blue-lighter border-blue-normal border rounded-lg'>
      {fileIcon}
      <span className='text-base text-gray-70 flex-grow font-normal'>
        {name}
      </span>
      <span
        className='text-sm text-violet-lightest font-medium uppercase'
        style={{ letterSpacing: 1 }}
      >
        {readableSize}
      </span>
      <DeleteTwoTone onClick={onDelete} />
    </div>
  )
}

const DroppableFileInput = ({
  file,
  children,
  style,
  fileIcon = <PictureTwoTone />,
  onUpload = () => {},
  onDelete = () => {},
  validateFile = () => true,
  onLoading = () => {},
  ...props
}) => {
  const [fileList, setFileList] = useState()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)

  const propStyle = style || {}
  const draggerStyle = {
    display: file ? 'none' : '',
    ...omit(propStyle, ['display']),
  }

  const omittedProps = omit(props, [
    'showUploadList',
    'multiple',
    'onChange',
    'fileList',
    'beforeUpload',
  ])

  const mergedProps = {
    style: draggerStyle,
    fileList,
    ...omittedProps,
  }

  const beforeUpload = file => {
    const results = validateFile ? validateFile(file) : true
    if (typeof results === 'string') {
      setErrorMessage(results)
      return false
    } else {
      setErrorMessage(undefined)
    }
    return true
  }

  const renderUploadedFile = () =>
    file && (
      <FileCard
        name={file.name}
        size={file.size}
        onDelete={onDelete}
        fileIcon={fileIcon}
      />
    )

  return (
    <>
      <Dragger
        showUploadList={false}
        multiple={false}
        beforeUpload={beforeUpload}
        onChange={({ file, fileList }) => {
          setFileList([...fileList])

          const { status } = file
          if (status === 'uploading') {
            setLoading(true)
            onLoading(true)
          }
          if (status === 'done') {
            onUpload(file)
          }

          if (status === 'done' || status === 'error') {
            setLoading(false)
            onLoading(false)
          }
        }}
        {...mergedProps}
      >
        {loading && <Spin indicator={spinnerIcon} />}
        {!file && !loading && children}
      </Dragger>
      {renderUploadedFile()}
      {errorMessage && <p className='text-red-normal'>{errorMessage}</p>}
    </>
  )
}

export default DroppableFileInput
