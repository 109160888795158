import { combineReducers } from 'redux'
import user from './user'
import auth from './auth'
import mentors from './mentors'
import problems from './problems'
import exams from './exam'
import edlyft from './edlyft'
import tasks from './tasks'
import concept from './concept'
import utils from './utils'
import availability from './availability'

const rootReducer = combineReducers({
  user,
  auth,
  mentors,
  problems,
  edlyft,
  exams,
  tasks,
  concept,
  utils,
  availability,
})

export default rootReducer
