import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ReactComponent as SelectDropdownIcon } from '../../assets/icons/select-dropdown.svg'
import 'antd/lib/select/style/css'
import { Select } from 'antd'

const FormFilter = ({ title, tags }) => {
  const location = useLocation()
  const history = useHistory()
  // Get search query
  const params = new URLSearchParams(location.search)
  const selectedTag = params.get('tag') || ''
  const titleQuery = params.get('query') || ''
  const [formQuery, setFormQuery] = useState(titleQuery)
  const [tagDropdownVisible, setTagDropdownVisibility] = useState(false)

  const onTagChange = (newTag) => {
    history.push({
      path: '/recordings',
      search: `?tag=${newTag || ''}&query=${titleQuery}`,
    })
  }

  const onTitleFormSubmit = (e) => {
    e.preventDefault()
    history.push({
      path: '/recordings',
      search: `?tag=${selectedTag}&query=${formQuery}`
    })
  }

  return (
    <div className="mb-4 lg:flex lg:items-center lg:justify-between">
      <h1 className="font-medium text-3xl leading-28 text-violet-dark">
        {title}
      </h1>
      <div className="sm:flex sm:items-center">
        <form onSubmit={onTitleFormSubmit}>
          <input onChange={(e) => setFormQuery(e.target.value)} value={formQuery} style={{ borderRadius: '5px', minWidth: '211px', maxWidth: '211px' }} className="form-input" placeholder="Search for Title" />
        </form>
        {
          (Array.isArray(tags) && tags.length > 0) &&
          <div style={{ borderRadius: '4px', minWidth: '192px', maxWidth: '192px' }} className="border border-gray-lighter bg-gray-lightest focus:bg-gray-lightest flex items-center relative sm:ml-6">
            <span className="absolute pl-3 pr-6 text-gray-light">{selectedTag || 'Filter'}</span>
            <span className="px-3 border-l border-gray-lighter-soft absolute top-0 bottom-0 right-0 flex items-center">
              <SelectDropdownIcon />
            </span>

            <Select
              allowClear
              onDropdownVisibleChange={() => {
                setTagDropdownVisibility(true)
              }}
              onBlur={() => {
                setTagDropdownVisibility(false)
              }}
              size="large"
              placeholder="Filter"
              className={tagDropdownVisible ? 'opacity-1' : 'opacity-0'}
              style={{ width: '100%' }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                </div>
              )}
              defaultValue={selectedTag}
              onChange={onTagChange}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="">Filter</Select.Option>
              {
                tags.map((tag, index) => (
                  <Select.Option key={index} value={tag}>{tag}</Select.Option>
                ))
              }
            </Select>
          </div>
        }
      </div>
    </div>
  )
}

export default FormFilter
