import React from 'react'
import FilePreviewModal from '../components/Modal/FilePreviewModal'

export default Component => {
  return class FilePreview extends React.PureComponent {
    constructor(props) {
      super(props)

      this.state = {
        openModal: false,
        url: null,
      }

      this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal(openModal, url) {
      this.setState({
        openModal,
        url,
      })
    }

    render() {
      const { url, openModal } = this.state
      return (
        <div>
          <Component
            showFilePreview={fileUrl => this.toggleModal(true, fileUrl)}
            {...this.props}
          />
          <FilePreviewModal
            open={openModal}
            closeModal={() => this.toggleModal(false, null)}
            url={url}
          />
        </div>
      )
    }
  }
}
