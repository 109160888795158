import { AVAILABILITY_ACTION_TYPES } from '../types/availability'

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  relatedCohorts: [],
  selectedMentor: {},
}

const availabilityReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case AVAILABILITY_ACTION_TYPES.FETCH_RELATED_COHORTS_START:
      return { ...state, isLoading: true }
    case AVAILABILITY_ACTION_TYPES.FETCH_RELATED_COHORTS_SUCCESS:
      return { ...state, isLoading: false, relatedCohorts: payload }
    case AVAILABILITY_ACTION_TYPES.FETCH_RELATED_COHORTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        relatedCohorts: null,
      }
    case AVAILABILITY_ACTION_TYPES.SET_SELECTED_MENTOR:
      return { ...state, selectedMentor: payload }
    default:
      return state
  }
}

export default availabilityReducer
