import { Col, Row } from 'antd'
import { ALL_TOPICS_TEXT, MENTOR_RECOMMENDED_TEXT } from '../../../constants'
import TopicRect from './TopicRect'

const FeaturedTopicsStyle = {
  marginTop: '2em',
  marginBottom: '2em',
}

const topicListStyle = {
  display: 'flex',
  justifyContent: 'start',
  overflowX: 'scroll',
}

const topicsColStyle = {
  display: 'flex',
  textAlign: 'left',
  alignSelf: 'center',
}

const FeaturedTopics = props => {
  const { topics, filterTopic, selectedTopics, hasMentorRecommendedProblems } =
    props
  return (
    <div style={FeaturedTopicsStyle}>
      <Row>
        <Col span={4} style={topicsColStyle}>
          <TopicRect
            key={'Featured Topics'}
            text={'Featured Topics'}
            insideStyle={{ marginLeft: '38px' }}
            noBackground
          />
        </Col>
        <Col span={20} style={topicListStyle}>
          {hasMentorRecommendedProblems ? (
            <TopicRect
              key={MENTOR_RECOMMENDED_TEXT}
              text={MENTOR_RECOMMENDED_TEXT}
              selected={selectedTopics.includes(MENTOR_RECOMMENDED_TEXT)}
              onClick={filterTopic}
            />
          ) : null}
          <TopicRect
            key={ALL_TOPICS_TEXT}
            text={ALL_TOPICS_TEXT}
            selected={selectedTopics.includes(ALL_TOPICS_TEXT)}
            onClick={filterTopic}
          />

          {topics.map(t => (
            <TopicRect
              key={t}
              text={t}
              selected={selectedTopics.includes(t)}
              onClick={filterTopic}
            />
          ))}
        </Col>
      </Row>
    </div>
  )
}

export default FeaturedTopics
