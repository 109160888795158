import {ReactComponent as SaveIcon} from "../../assets/icons/save_btn.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/close_blue.svg";
import React, {useCallback, useRef, useState} from "react";

const TimestampForm = ({ defaultTimestamp, defaultSentence, handleSubmit, handleClose }) => {
    const timeStampInputRef = useRef()
    const sentenceInputRef = useRef()

    const [currentEditing, setCurrentEditing] = useState({
        timestamp: defaultTimestamp,
        sentence: defaultSentence
    })

    const handleInputChange = useCallback((e) => {
        setCurrentEditing({
            ...currentEditing,
            [e.target.name]: e.target.value,
        })
    }, [currentEditing])

    return (
        <>
            <div className="timestamps-inputs">
                <form onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleSubmit(currentEditing)
                }}>
                    <input
                        required
                        type="text"
                        pattern="\d{2}:\d{2}(:\d{2})?"
                        name="timestamp"
                        placeholder={"00:00"}
                        defaultValue={defaultTimestamp}
                        onFocus={(e) => { e.stopPropagation()}}
                        onBlur={(e) => { e.stopPropagation()}}
                        onChange={(e) => {
                            e.stopPropagation()
                            handleInputChange(e)
                        }}
                        ref={timeStampInputRef}
                    />
                    <input
                        required
                        type="text"
                        defaultValue={defaultSentence}
                        name="sentence"
                        placeholder={"Enter title"}
                        onFocus={(e) => { e.stopPropagation()}}
                        onBlur={(e) => { e.stopPropagation()}}
                        onChange={(e) => {
                            e.stopPropagation()
                            handleInputChange(e)
                        }}
                        ref={sentenceInputRef}
                    />
                    <button type="submit">
                        <SaveIcon />
                    </button>
                    <button type="button" onClick={handleClose}>
                        <CloseIcon />
                    </button>
                </form>
            </div>
        </>
    );
}

export default TimestampForm
