import React, { useEffect } from 'react'
import UploadRecording from '../UploadRecording'
import RecordingList from '../ListItems/RecordingList'
import { connect } from 'react-redux'
import withPageWrapper from '../../hoc/AuthPageWrapper'
import { amplitudeInstance } from '../../amplitude'
import CommonSidebar from './../ViewsContent/CommonSidebar'

const Recordings = ({ isMentor, edlyftCohort }) => {
  useEffect(() => {
    amplitudeInstance.logEvent('Hit Recordings Page')
  }, [])

  return (
    <div>
      {isMentor && (
        <UploadRecording
          titleClass='text-black-normal'
          title='Upload Video Recordings'
          description='Make sure you fill in as much information for your students during the uploading process.'
          edlyftCohort={edlyftCohort}
          fromPage='recordings'
        />
      )}
      <div className='pt-6 px-8'>
        <RecordingList isMentor={isMentor} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const {
    user: { isMentor, edlyftCohort },
  } = state

  return {
    isMentor,
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(
  withPageWrapper(Recordings, 'recordings', null, null, CommonSidebar),
)
