import { Button, Typography, Space } from 'antd'
import figure from '../assets/flag.svg'

const { Title } = Typography;

const styles = {
  container: {
    width: "710px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "4em",
    textAlign: "center"
  },
  button: {
    width: "140px",
    marginTop: "3em"
  },
  figure: {
    maxWidth: "150px",
    paddingTop: "2em",
    paddingBottom: "2em"
  }
}

const YourMentorHasBeenNotified = (props) => {
  const { onClick } = props

  return (
    <div style={styles.container}>
      <img src={figure} alt={"Empty"} style={styles.figure}></img>
      <Title style={styles.title}>Your mentor has been notified on Slack!</Title>
      <Title level={5}>You should receive a response from your mentor soon. You can book a 1:1 session if you still need help</Title>
      <Space direction="vertical" size={10}></Space>
      <Button onClick={onClick} 
        type="primary" 
        size={"large"} 
        style={styles.button} 
      >OK</Button>
    </div>
  )
}

export default YourMentorHasBeenNotified;