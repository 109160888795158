import React from 'react'
import { amplitudeInstance } from '../amplitude'

export default (Component) => {
  return class AmplitudeVideoPlayLog extends React.PureComponent {
    constructor(props) {
      super(props)
      this.videoPlayHandler = this.videoPlayHandler.bind(this)
    }

    videoPlayHandler(playerRef, videoId, amplitudeData = {}) {
      const currentTime = playerRef.currentTime
      // Only log event when current time is 0 i.e the first play button click
      if (currentTime === 0) {
        amplitudeInstance.logEvent("Plays Recording", {
          videoId,
          ...amplitudeData,
        })
      }
    }

    render() {
      return <Component videoPlayHandler={this.videoPlayHandler} {...this.props} />
    }
  }
}
