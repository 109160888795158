import { SET_CONCEPT_PAYLOAD, GET_CONCEPT_PAYLOAD } from '../types'

const INITIAL_STATE = {
  data: ''
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONCEPT_PAYLOAD:
      return {
        ...state,
       ...action.concept
      }

    case GET_CONCEPT_PAYLOAD:
      return {
        ...state,
        concept: action.concept
      }

    default:
      return state
  }
}

export default reducer
