import React, { useCallback, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import withPageWrapper from '../../../hoc/AuthPageWrapper'
import { setStudentMentors } from '../../../redux/actions/mentors'
import axios from '../../../axios-orders'
import SuccessModal from './successModal'
import BookingsSidebar from './sidebar'
import { getMentorToBook } from '../../../helpers'
import amplitude from 'amplitude-js'
import { amplitudeInstance } from '../../../amplitude'
import { getStudentMentors, saveInteraction } from '../../../utils'

const styles = {
  title: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.03em',
    color: '#2C2543',
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#666666',
  },
  section: {
    marginTop: '50px',
  },
  textarea: {
    width: '100%',
    height: '100px',
    background: '#F9F9F9',
    border: '1px solid #DFDFE1',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '20px',
  },
  submitBtn: {
    width: '139px',
    height: '40px',
    background: '#4290F3',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '50px',
    float: 'right',
  },
  mentorOption: {
    height: '120px',
    background: '#F9F9F9',
    borderRadius: '20px',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    marginRight: '20px',
  },
  option: {
    width: '20px',
    height: '20px',
  },
  mentorName: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#2C2543',
  },
  mentorCohort: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '15px',
    color: '#2C2543',
  },
  mentorProfile: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    border: '3px solid #FFFFFF',
    boxSizing: 'border-box',
    backgroundSize: 'contain',
  },
  dropdown: {
    width: 'auto',
    height: '50px',
    background: '#F9F9F9',
    border: '1px solid #DFDFE1',
    borderRadius: '4px',
    paddingLeft: '10px',
  },
  sectionTitle: {
    fontSize: '1.2em',
  },
}

const timezones = {
  PST: 'Pacific Standard Time',
  EST: 'Eastern Standard Time',
  CST: 'Central Standard Time',
  HAST: 'Hawaii-Aleutian Standard Time',
  AKST: 'Alaska Standard Time',
  MST: 'Mountain Standard Time',
}

const Booker = ({
  edlyftCohort,
  userId,
  firstName,
  lastName,
  isActive,
  isMentor,
  screenOut,
  mentors,
  courseNumbers,
  courses,
  setMentors,
}) => {
  const [currentCourse, setCurrentCourse] = useState(courses[0])
  const [currentMentor, setCurrentMentor] = useState()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const history = useHistory()

  const formRef = useRef(null)

  const fetchMentors = useCallback(async () => {
    const { mentors, courseNumbers } = await getStudentMentors(courses)
    if (mentors.length) {
      const mentorToBook = getMentorToBook(mentors, edlyftCohort)
      setMentors(mentors, courseNumbers)
      setCurrentMentor(mentorToBook.userId)
    }
  }, [])

  useEffect(() => {
    if (mentors.length < 1) {
      fetchMentors()
    }
  }, [mentors])

  const newActiveCourseCallback = useCallback(e => {
    setCurrentCourse(e.detail.newActiveCourse)
  }, [])

  useEffect(() => {
    setCurrentMentor(
      mentors.filter(({ courses }) => courses.includes(currentCourse))[0]
        ?.userId,
    )
  }, [currentCourse])

  const handleFormChange = useCallback(
    e => {
      if (e.target.checkValidity()) {
        setDisableBtn(false)
      }
    },
    [formRef, disableBtn],
  )

  const handleSubmitCallback = useCallback(
    async e => {
      e.preventDefault()
      setDisableBtn(true)
      const slackID =
        mentors.find(mentor => mentor.userId === e.target.mentor.value)
          ?.slackID ?? ''

      try {
        await axios.post(`/mentor/bookings`, {
          studentName: firstName,
          slackID,
          mentor: e.target.mentor.value,
          timezone: e.target.timezone.value,
          reason: e.target.reason.value,
          suitableTime: e.target.suitableTime.value,
        })
        setShowSuccessModal(true)

        var identify = new amplitude.Identify().set('cohort', currentCourse)

        amplitudeInstance.identify(identify)
        saveInteraction({
          resourcePoster: '',
          edlyftCohort,
          resourceType: 'booker',
          resourceId: '',
          screenOut,
          userId,
          isActive,
          isMentor,
          resourceTitle: 'clickedSubmitOnBooker',
          userName: `${firstName} ${lastName}`,
        })
        amplitudeInstance.logEvent('Clicked Submit on Office Hours Booker', {
          cohort: currentCourse,
        })
      } catch (e) {
        console.error(e)
      }
    },
    [mentors, currentCourse, disableBtn],
  )

  window.addEventListener('newActiveCourse', newActiveCourseCallback)

  return (
    <div style={{ width: '90%', padding: '20px' }}>
      <h1 style={styles.title}>
        Book time with your mentor for{' '}
        {courseNumbers[currentCourse]
          ? courseNumbers[currentCourse]
          : currentCourse}
      </h1>
      <p style={styles.subtitle}>
        Each student has access to one 1:1 office hour a week
      </p>
      <form
        ref={formRef}
        onSubmit={handleSubmitCallback}
        onChange={handleFormChange}
      >
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>
            Which mentor do you want to schedule time to speak with?
          </h2>
          <div className='flex flex-wrap'>
            {mentors
              .filter(({ courses }) => courses.includes(currentCourse))
              .map(({ firstName, lastName, photoURL, userId }, index) => (
                <div
                  style={{
                    ...styles.mentorOption,
                    backgroundColor:
                      userId === currentMentor ? '#EBF3FD' : '#F9F9F9',
                  }}
                  className='flex'
                  key={userId}
                >
                  <div
                    style={{
                      ...styles.mentorProfile,
                      backgroundImage: `url(${photoURL})`,
                    }}
                  />
                  <div style={{ margin: '0px 25px' }}>
                    <h2 style={styles.mentorName}>
                      {firstName} {lastName}
                    </h2>
                    <p style={styles.mentorCohort}>
                      {courseNumbers[currentCourse]
                        ? courseNumbers[currentCourse]
                        : currentCourse}{' '}
                      mentor
                    </p>
                  </div>
                  <input
                    type={'radio'}
                    name={'mentor'}
                    value={currentMentor}
                    checked={currentMentor === userId}
                    style={styles.option}
                    key={userId}
                    required
                    onChange={e => {
                      setCurrentMentor(userId)
                    }}
                  />
                </div>
              ))}
          </div>
        </section>
        <section style={styles.section}>
          <h2 style={styles.sectionTitle}>
            What do you want to discuss with{' '}
            {currentMentor
              ? mentors.filter(({ userId }) => userId === currentMentor)[0]
                  .firstName
              : ''}
            ?
          </h2>
          <textarea
            name='reason'
            style={styles.textarea}
            required
            placeholder='I’m thinking about dropping my CS course, I want to discuss my midterm scores, I have a bug I can’t fix.'
          />
        </section>
        <section style={styles.section}>
          <div className={'flex flex-row justify-between align-center mb-2'}>
            <h2 style={styles.sectionTitle}>
              What days and times work best for you?
            </h2>
            <select name={'timezone'} style={styles.dropdown} required>
              <option value={''}>Select the timezone you are in</option>
              {Object.entries(timezones).map(([value, text]) => (
                <option value={value} key={value}>
                  {text}
                </option>
              ))}
              <option value={'outside US'}>
                Outside the US (Specify in the textbox below)
              </option>
            </select>
          </div>
          <textarea
            name='suitableTime'
            style={styles.textarea}
            placeholder='Please specify what day(s) and time(s) are most suitable for you.'
            required
          />
        </section>
        <button type='submit' style={styles.submitBtn} disabled={disableBtn}>
          Submit
        </button>
      </form>
      <SuccessModal
        mentorName={
          currentMentor
            ? mentors.filter(({ userId }) => userId === currentMentor)[0]
                .firstName
            : ''
        }
        open={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false)
          history.goBack()
        }}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const {
    user: {
      edlyftCohort,
      userId,
      firstName,
      lastName,
      isActive,
      isMentor,
      screenOut,
      courses,
    },
    mentors: { mentors, courseNumbers },
  } = state

  return {
    edlyftCohort,
    userId,
    firstName,
    lastName,
    isActive,
    isMentor,
    screenOut,
    mentors,
    courseNumbers,
    courses,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMentors: (mentors, courseNumbers) =>
      dispatch(setStudentMentors(mentors, courseNumbers)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPageWrapper(Booker, 'Booker', '/', 'Dashboard', BookingsSidebar))
