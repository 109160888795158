import { useAuth0 } from '@auth0/auth0-react'
import { makeAxiosTokenOption } from '../utils/api/helpers'
import { express } from '../axios-orders'

const queue = []
const delay = 100
let timeoutId = null

export default function useMetricsApi() {
  const { getAccessTokenSilently } = useAuth0()

  const processQueue = async () => {
    const metrics = [...queue]
    queue.length = 0

    try {
      const token = await getAccessTokenSilently()
      const options = makeAxiosTokenOption(token)
      express.post('/metrics/wau-airtable', metrics, options)
    } catch (error) {
      console.error(error)
    } finally {
      timeoutId = null
    }
  }

  const enqueue = eventType => {
    queue.push({
      eventType,
      timestamp: Date.now(),
    })
    if (!timeoutId) {
      timeoutId = setTimeout(processQueue, delay)
    }
  }

  return {
    enqueue,
  }
}

export const NPPEvent = 'practice problem'
export const RecordingEvent = 'recording'
export const MentorInteractionEvent = 'mentor interaction'
