import { samBackend } from '../../axios-orders'

export const startConvoWithMentor = async ({
  mentorId,
  studentId,
  practiceProblemName,
}) =>
  await samBackend.post('/notify/start_conversation_with_mentor', {
    mentorId,
    studentId,
    practiceProblemName,
  })
