import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export function useEdlyftCohorts(edlyftCohorts) {
  const edlyft = useSelector(state => state.edlyft, shallowEqual)

  const cohorts = useMemo(() => {
    const { courses } = edlyft
    const allCourses = courses || []
    
    return edlyftCohorts.map(edlyftCohort => {
      const foundCohort = allCourses.find(
        course => course.edlyftCohort === edlyftCohort,
      )
      const { tags, exams, ...rest } = foundCohort || {}
      const cohort = foundCohort
        ? {
            ...rest,
            tags: tags || [],
            exams: ['Midterm 1', 'Midterm 2', 'Midterm 3', 'Finals'],
            // this is what it should be - but we are keeping it static for the mean time
            // exams: exams || [],
          }
        : null
      return cohort
    })
  }, [edlyftCohorts, edlyft])

  return cohorts
}
