import axios from '../../axios-orders'

export const getCohortTags = async (edlyftCohort, query = '', limit = undefined) =>
  await axios.get('/mentor/cohort-tags', {
    params: {
      edlyftCohort,
      query,
      limit,
    },
  })
