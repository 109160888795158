// User profile
export const ONBOARD_USER = 'ONBOARD_USER'
export const SWITCH_COURSE = 'SWITCH_COURSE'
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR'
export const FETCH_USER_INFO_COMPLETE = 'FETCH_USER_INFO_COMPLETE'
export const FETCH_USER_INFO_IN_PROGRESS = 'FETCH_USER_INFO_IN_PROGRESS'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const FETCH_AUTH0_TOKEN_ERROR = 'FETCH_AUTH0_TOKEN_ERROR'
export const FETCH_AUTH0_TOKEN_COMPLETE = 'FETCH_AUTH0_TOKEN_COMPLETE'
export const UPDATE_EMAIL_PREFERENCES = 'UPDATE_EMAIL_PREFERENCES'
export const SET_STUDENT_MENTORS = 'SET_STUDENT_MENTORS'
// Set exam plans
export const SET_EXAMS_PLAN_PAYLOAD = 'SET_EXAMS_PLAN_PAYLOAD'
export const SET_EXAMS_PLAN_PENDING = 'SET_EXAMS_PLAN_PENDING'
export const SET_EXAMS_PLAN_ERROR = 'SET_EXAMS_PLAN_ERROR'
// Get exam plans
export const GET_EXAMS_PLAN_PAYLOAD = 'GET_EXAMS_PLAN_PAYLOAD'
export const GET_EXAMS_PLAN_PENDING = 'GET_EXAMS_PLAN_PENDING'
export const GET_EXAMS_PLAN_ERROR = 'GET_EXAMS_PLAN_ERROR'

export const SET_STUDY_TASKS_PAYLOAD = 'SET_STUDY_TASKS_PAYLOAD'

export const GET_STUDY_TASKS_PAYLOAD = 'GET_STUDY_TASKS_PAYLOAD'
export const SET_CONCEPT_PAYLOAD = 'SET_CONCEPT_PAYLOAD'
export const GET_CONCEPT_PAYLOAD = 'GET_CONCEPT_PAYLOAD'


// UTILITIES ACTIONS
export const SET_UPDATE_BOOKING_MODAL = 'SET_UPDATE_BOOKING_MODAL'

//GET and SET EDLYFT LIVE COURSES
export const SET_EDLYFT_COURSES = 'SET_EDLYFT_COURSES'
export const GET_EDLYFT_COURSES = 'GET_EDLYFT_COURSES'

//GET and SET PRACTICE PROBLEMS
export const SET_PRACTICE_PROBLEMS = 'SET_PRACTICE_PROBLEMS'
export const UPDATE_PRACTICE_PROBLEM = 'UPDATE_PRACTICE_PROBLEM'
export const ADD_PRACTICE_PROBLEM = 'ADD_PRACTICE_PROBLEM'