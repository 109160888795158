import React, {useCallback} from 'react'
import {connect} from 'react-redux';
import { withHttp } from '../../utils/withHttp'
import {amplitudeInstance} from "../../amplitude";
import { saveInteraction } from '../../utils';

const VideoLinks = ({ links, edlyftCohort, userId, firstName, lastName, isActive, screenOut, isMentor }) => {
  const firstLink = links[0]

  const handleClick = useCallback(() => {
    saveInteraction({
      resourcePoster: '',
      edlyftCohort,
      resourceType: 'recording',
      resourceId: '',
      screenOut,
      userId,
      isActive,
      isMentor,
      resourceTitle: 'clickedRecording',
      userName: `${firstName} ${lastName}`,
    })
    amplitudeInstance.logEvent('Clicked Recording Link', {
      Cohort: edlyftCohort,
      FromPage: 'recordings list',
      LinkURL: firstLink,
    })
  }, [])

  return (
    <div className='text-sm'>
      {firstLink && (
        <>
          <span className='text-darkest leading-13 font-medium mr-2'>
            Link:
          </span>
          <span className='inline-block mr-2'>
            {/* check if link contains http otherwised display link or prepend http */}
            <a onClick={handleClick} href={withHttp(firstLink)} target={'_blank'}>
              {firstLink}
            </a>
          </span>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const {
    user: { edlyftCohort, userId, firstName, lastName, isActive, screenOut, isMentor },
  } = state

  return {
    edlyftCohort,
    userId,
    firstName,
    lastName,
    isActive,
    isMentor,
    screenOut,
  }
}

export default connect(mapStateToProps)(VideoLinks)