import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { ReactComponent as CloseIcon } from '../../assets/icons/x-round.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-blue.svg'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import axios, { axiosDefault } from '../../axios-orders'
import { loadDynamicScript } from '../../helpers'
import Spinner from '../../assets/images/spinner.gif'
import { updateProfile } from '../../redux/actions/user'
import { SortBy } from '../../helpers'
import amplitude from 'amplitude-js'
import { amplitudeInstance } from '../../amplitude'

const CHARGE_BEE_SCRIPT = 'https://js.chargebee.com/v2/chargebee.js'

const UpdateProfileModal = ({
  open,
  email,
  userId,
  courses,
  allCourses,
  photoURL,
  lastName,
  isMentor,
  firstName,
  closeModal,
  edlyftCohort,
  updateProfile,
}) => {
  const [chargebeeInstance, setChargebeeInstance] = useState(null)
  const { handleSubmit, register } = useForm()
  const [uploadedPhoto, setUploadedPhoto] = useState({
    s3URL: null,
    cloudfrontURL: null,
  })
  const [isProcessingPhoto, setProcessingPhoto] = useState(false)
  const [isSubmittingForm, setSubmittingForm] = useState(false)

  useEffect(() => {
    // Load chargebee JS dynamically for students only
    loadChargeBee()
  }, [isMentor])

  const loadChargeBee = () => {
    if (!isMentor) {
      loadDynamicScript(
        {
          id: 'charge-bee',
          src: CHARGE_BEE_SCRIPT,
        },
        () => {
          try {
            window.Chargebee.init({
              site: 'edlyft',
            })
            setChargebeeInstance(window.Chargebee.getInstance())
          } catch (e) {}
        },
      )
    }
  }

  // Charge bee portal click handler
  const openChargeBeePortal = () => {
    try {
      // Send slack notification
      axios
        .post('/common/notify/subscriptionmanage', {
          email,
          last_name: lastName,
          first_name: firstName,
        })
        .catch(e => {
          console.log(e)
        })

      //open chargebee modal
      let cbPortal = chargebeeInstance.createChargebeePortal()
      cbPortal.open()
    } catch (e) {}
  }

  const submitForm = data => {
    let formData = {
      user_id: userId,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      photo_url:
        uploadedPhoto && uploadedPhoto.s3URL ? uploadedPhoto.s3URL : undefined,
      courses: data.courses ? data.courses : undefined,
    }

    // Dynamically add courses item as array for mentor update
    if (data.courses && isMentor === true) {
      formData['courses'] = [data.courses]
    }

    setSubmittingForm(true)

    axios
      .patch('/profile', formData)
      .then(({ data }) => {
        let updateData = {
          email: formData.email,
          lastName: formData.last_name,
          firstName: formData.first_name,
        }
        // Conditionally add profile photo to the local state data to set
        if (
          formData.photo_url &&
          uploadedPhoto &&
          uploadedPhoto.cloudfrontURL
        ) {
          updateData['photoURL'] = uploadedPhoto.cloudfrontURL
        }
        // Conditionally update courses and cohort
        if (
          data.user &&
          data.user.courses &&
          Array.isArray(data.user.courses)
        ) {
          updateData['courses'] = data.user.courses
          updateData['edlyftCohort'] = data.user.courses[0] // Use the first course as the default cohort
        }
        // Update local state
        updateProfile(updateData)
        // Update amplitude identity
        var identify = new amplitude.Identify().set('email', formData.email)
        // Conditionally set cohort if only it's an array of items
        if (Array.isArray(data.user.courses)) {
          identify.set('edlyftCohort', data.user.courses.join(', '))
        }
        amplitudeInstance.identify(identify)
        // Profile updated successully
        closeModal()
      })
      .catch(() => {
        alert('Error Updating Profile')
      })
      .finally(() => {
        setSubmittingForm(false)
      })
  }

  const uploadPhoto = e => {
    setProcessingPhoto(true)
    // Get the uploaded file
    const file = e.target.files[0]
    // Get the mimetype
    const mime = file.type
    // Get file extension
    const extension = file.name.split('.').pop()
    // Get presigned URL for notes upload
    axios
      .get('/common/pre-signed/user-avatar', {
        params: {
          mime,
          extension,
        },
      })
      .then(({ data }) => {
        const uploadURL = data.uploadURL
        const cloudfrontURL = data.cloudfrontURL
        const filename = data.filename
        // Create a new file with updated name
        const updatedFile = new File([file], filename, {
          type: file.type,
        })
        // Upload photos to S3
        axiosDefault
          .put(uploadURL, updatedFile, {
            headers: {
              'Content-Type': mime,
              'x-amz-acl': 'private',
            },
          })
          .then(() => {
            // Get the clean URL without query parameters
            // from the presigned URL
            const photoURL = uploadURL.split('?')[0]
            setUploadedPhoto({
              s3URL: photoURL,
              cloudfrontURL,
            })
          })
          .catch(() => {
            alert('Error uploading photo')
          })
          .finally(() => {
            setProcessingPhoto(false)
          })
      })
      .catch(() => {
        alert('Error uplaoding photo')
        setProcessingPhoto(false)
      })
  }

  let userAvatar
  // If user avatar is uploading, set a spinner
  if (isProcessingPhoto) {
    userAvatar = Spinner
  }
  // If there is an uploaded photo URL in the local
  // state, set that to user avatar
  // P.S this means avatar has been uploaded
  else if (uploadedPhoto && uploadedPhoto.cloudfrontURL) {
    userAvatar = uploadedPhoto.cloudfrontURL
  }
  // Set default avatar to value from the global state
  else {
    userAvatar = photoURL
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      closeOnEsc={false}
      closeOnOverlayClick={false}
      styles={{
        overlay: {
          zIndex: '2000',
        },
        modal: {
          width: '371px',
        },
      }}
      classNames={{
        overlay: 'py-0 pr-0',
        modal: 'max-w-full bg-white h-full p-6 mr-0 ml-auto overflow-auto',
      }}
      showCloseIcon={false}
    >
      <div className='relative'>
        <button
          onClick={closeModal}
          style={{ width: '25px', height: '25px' }}
          className='outline-none absolute top-0 right-0'
        >
          <CloseIcon />
        </button>
        <div className='pt-16'>
          <div
            style={styles.avatarWrapper}
            className='modal-update-profile__avatar-wrapper inline-block relative'
          >
            <span
              style={{
                width: '109px',
                height: '109px',
                backgroundImage: `url(${userAvatar})`,
              }}
              className='inline-block bg-center bg-cover rounded-full bg-gray-lighter'
            ></span>
            <span
              style={styles.editIcon}
              className='modal-update-profile__edit-icon absolute hidden'
            >
              <EditIcon />
            </span>
            <input
              onChange={uploadPhoto}
              accept='image/png,image/jpg,image/jpeg'
              type='file'
              className='absolute h-full w-full left-0 top-0 opacity-0'
            />
          </div>
          <div className='flex justify-center mt-6 mb-3'>
            <button
              disabled={isSubmittingForm}
              onClick={handleSubmit(submitForm)}
              className='appearance-none text-blue-normal leading-15 text-tiny'
            >
              Save Changes
            </button>
          </div>
          <div className='px-8'>
            <div className='pb-12 border-gray-lighter-hard border-b'>
              <div className='mb-4'>
                <label
                  htmlFor='first_name'
                  className='mb-1 leading-15 text-tiny'
                  style={{ color: '#A7A6A9' }}
                >
                  First Name
                </label>
                <input
                  defaultValue={firstName}
                  ref={register}
                  name='first_name'
                  id='first_name'
                  className='bg-gray-lightest form-input rounded'
                  type='text'
                />
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='last_name'
                  className='mb-1 leading-15 text-tiny'
                  style={{ color: '#A7A6A9' }}
                >
                  Last Name
                </label>
                <input
                  defaultValue={lastName}
                  ref={register}
                  name='last_name'
                  id='last_name'
                  className='bg-gray-lightest form-input rounded'
                  type='text'
                />
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='email'
                  className='mb-1 leading-15 text-tiny'
                  style={{ color: '#A7A6A9' }}
                >
                  Email Address
                </label>
                <input
                  defaultValue={email}
                  ref={register}
                  name='email'
                  readOnly
                  id='email'
                  className='bg-gray-lightest form-input rounded'
                  type='email'
                />
              </div>
              {isMentor === true && Array.isArray(allCourses) && (
                <div className='mb-4'>
                  <label
                    htmlFor='courses'
                    className='mb-1 leading-15 text-tiny'
                    style={{ color: '#A7A6A9' }}
                  >
                    Course Teaching
                  </label>
                  <select
                    defaultValue={edlyftCohort}
                    ref={register}
                    name='courses'
                    id='courses'
                    className='bg-gray-lightest form-input rounded'
                  >
                    {SortBy(
                      allCourses?.filter(course => course.live),
                      'courseNumber',
                    ).map(item => (
                      <option key={item.edlyftCohort} value={item.edlyftCohort}>
                        {item.courseNumber}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {isMentor === false && chargebeeInstance && (
              <div className='mt-16 mb-4'>
                <button
                  onClick={openChargeBeePortal}
                  className='appearance-none text-blue-normal leading-20 text-lg underline font-medium'
                >
                  Manage Subscription
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const styles = {
  avatarWrapper: {
    left: '50%',
    transform: 'translateX(-50%)',
  },
  editIcon: {
    width: '30px',
    height: '30px',
    top: '-2px',
    right: '-2px',
  },
}

const mapStateToProps = state => {
  const {
    user: {
      isMentor,
      photoURL,
      firstName,
      lastName,
      userId,
      email,
      courses,
      edlyftCohort,
    },
    edlyft: { courses: allCourses },
  } = state

  return {
    isMentor,
    firstName,
    lastName,
    photoURL,
    userId,
    allCourses,
    email,
    courses,
    edlyftCohort,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: data => dispatch(updateProfile(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfileModal)
