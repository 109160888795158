import React, { useState, useEffect } from 'react'

// Libraries
import axios, { express } from './axios-orders'
import Amplify from 'aws-amplify'
import amplitude from 'amplitude-js'
import { useAuth0 } from '@auth0/auth0-react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { GuestRoutes, checkSubscription } from './components/Login'
import { amplitudeInstance } from './amplitude'
import { connect } from 'react-redux'

// Pages
import Recordings from './components/Views/Recordings'
import SingleVideo from './components/Views/SingleVideo'
import Dashboard from './components/Views/Dashboard'
import ExamPrep from './components/Views/ExamPrep'
import ContactMentor from './components/Views/ContactMentor'
import Concept from './components/Views/Concept'
import StudentsFeedbacks from './components/Views/Dashboard/MentorFeedback/StudentsFeedbacks'
import PageLoader from './components/PageLoader'
import Booker from './components/Views/Booker'

// Redux store
import {
  updateProfile as updateProfileAction,
  fetchedUserInfo,
  fetchUserInfoError,
} from './redux/actions/user'
import { setCourses } from './redux/actions/edlyft'
import { fetchedAuth0Token, fetchAuth0TokenError } from './redux/actions/auth'
import { ToastContainer, toast } from 'react-toastify'

// Style
import './App.css'
import './styles/tailwind.output.css'
import './styles/vendors/toastify.css'
import '@edlyft/design-system/dist/esm/index.css'

// Configurations
import awsconfig from './aws-exports'
import PracticeProblemDetails from './components/Views/PracticeProblemDetails'
import TechXSessions from './components/Views/TechXSessions'
Amplify.configure(awsconfig)
const Onboarding = React.lazy(() => import('./components/Views/Onboarding'))
const ReturningStudent = React.lazy(() =>
  import('./components/Views/ReturningStudent'),
)
const UnrecognizedUserModal = React.lazy(() =>
  import('./components/Modal/UnrecognizedUserModal'),
)

const techxEmailDomain = /@techexchange\.in$/i
const adsCourseNumber = /^ADS/i

const identifyUserAsMember = () => {
  const identify = new amplitude.Identify().set('status', 'member')
  amplitudeInstance.identify(identify)
  amplitudeInstance.logEvent('landed on dashboard')
}

// App component
const App = props => {
  const {
    email,
    isMentor,
    isSubscribed,
    hasOnboarded,
    fetchedUserInfo,
    fetchUserInfoError,
    fetchedAuth0Token,
    setCourses,
    allCourses,
    fetchAuth0TokenError,
    subscriptionId,
    fetchedProfileData,
    updateProfile,
    userCourseList,
  } = props

  // state
  const [isAppReady, setAppReady] = useState(false)
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } =
    useAuth0()

  const params = new URLSearchParams(window.location.search)
  const subscriptionIdInURL = params.get('suid')

  // Check subscription ID
  const userSubId = subscriptionId || subscriptionIdInURL

  const isTechXUser =
    email.search(techxEmailDomain) > -1 ||
    userCourseList.some(
      course => course?.courseNumber?.search(adsCourseNumber) > -1,
    )

  // hooks
  useEffect(() => {
    // fetch edlyft active courses
    const FetchActiveCourses = async () => {
      try {
        let res = await axios.get('/common/courses')
        setCourses(res.data.data.courses)
      } catch (err) {
        // console.log('err fetching courses')
      }
    }
    // Only fetch the course if not in store previously or it is empty
    if (!allCourses || allCourses.length === 0) {
      FetchActiveCourses()
    }
  }, [])

  useEffect(() => {
    getAccessTokenSilently()
      .then(token => {
        // console.log(token, 'token')
        // Access token fetched successfully
        fetchedAuth0Token(token)
        // Set default authorization header
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        express.defaults.headers.common['Authorization'] = `Bearer ${token}`

        // Get account details
        newGetAccount()
      })
      .catch(e => {
        console.log(e)
        fetchAuth0TokenError()
      })
  }, [isAuthenticated])

  useEffect(() => {
    // validate subscription
    if (fetchedProfileData) {
      const options = {
        subscriptionId,
        updateProfile,
        setAppReady,
        userSubId,
      }
      checkSubscription(options)
    }
  }, [fetchedProfileData])

  const newGetAccount = async () => {
    if (isAuthenticated) {
      const userId = user.sub.split('|')[1]
      amplitudeInstance.setUserId(userId)
      axios
        .get(process.env.REACT_APP_PROFILE_GATEWAY, {
          params: {
            userId,
          },
        })
        .then(response => {
          // Handle Legacy `isMentor` field. Originally stored as string and not Boolean
          let isMentorLegacy =
            typeof response.data.content.isMentor === 'boolean'
              ? response.data.content.isMentor
              : false
          // Set amplitude identifier
          var identify = new amplitude.Identify()
            .set('isMentor', isMentorLegacy)
            .set('email', response.data.content.email) // response data doesn't have an email
          // Conditionally set edlyftCohort only if it's an array
          if (Array.isArray(response.data.content.courses)) {
            identify.set(
              'edlyftCohort',
              response.data.content.courses.join(', '),
            )
          }
          amplitudeInstance.identify(identify)
          // Dispatch redux profile fetched action
          fetchedUserInfo(response.data.content)
        })
        .catch(error => {
          console.log(error)
          fetchUserInfoError()
        })
    }
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (!isAuthenticated) {
    return <GuestRoutes />
  }

  if (!isAppReady) {
    return <PageLoader />
  }

  if (!hasOnboarded) {
    return (
      <React.Suspense fallback={null}>
        <BrowserRouter>
          <Onboarding />
        </BrowserRouter>
      </React.Suspense>
    )
  }

  if (!isSubscribed) {
    if (subscriptionId === 'invalid') {
      return (
        <React.Suspense fallback={null}>
          <ReturningStudent />
        </React.Suspense>
      )
    }

    return (
      <React.Suspense fallback={null}>
        <UnrecognizedUserModal open={true} onClose={() => {}} />
      </React.Suspense>
    )
  }

  identifyUserAsMember()

  return (
    <div className='App'>
      <main className='main-wrap'>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/recordings' component={Recordings} />
            <Route exact path='/bookings' component={Booker} />
            <Route exact path='/student-quotes' component={StudentsFeedbacks} />
            <Route path='/videos/:videoId' component={SingleVideo} />
            <Route path='/exam-prep' component={ExamPrep} />
            <Route path='/contact-mentor' component={ContactMentor} />
            <Route
              path='/practice-problem/:id'
              component={PracticeProblemDetails}
            />
            <Route path='/concept/:id' component={Concept} />
            {!isMentor && isTechXUser && (
              <Route exact path='/techx-sessions' component={TechXSessions} />
            )}
            <Route exact path='*' component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </main>
      <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />
    </div>
  )
}

const mapStateToProps = state => {
  const {
    user: {
      email,
      userId,
      isSubscribed,
      isMentor,
      hasOnboarded,
      subscriptionId,
      fetchedProfileData,
      courses = [],
    },
    edlyft: { courses: allCourses },
  } = state

  const userCourseList = courses.map(courseId =>
    allCourses.find(course => course.edlyftCohort === courseId),
  )

  return {
    email,
    userId,
    isSubscribed,
    isMentor,
    hasOnboarded,
    subscriptionId,
    allCourses,
    fetchedProfileData,
    userCourseList,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCourses: courses => dispatch(setCourses(courses)),
    updateProfile: data => dispatch(updateProfileAction(data)),
    fetchedUserInfo: user => dispatch(fetchedUserInfo(user)),
    fetchUserInfoError: () => dispatch(fetchUserInfoError()),
    fetchedAuth0Token: token => dispatch(fetchedAuth0Token(token)),
    fetchAuth0TokenError: () => dispatch(fetchAuth0TokenError()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
