import React, { useState } from 'react'
import { Row, Modal } from 'antd'

import Card from './Card'
import EmptyState from './EmptyState'
import QuestionSelectionStickyBar from './QuestionSelectionStickyBar'
import YourMentorHasBeenNotified from '../Modal/YourMentorHasBeenNotified'
import { amplitudeInstance } from '../../../../amplitude'
import { startConvoWithMentor } from '../../../../utils/api'
import { useMemo } from 'react'

const style = {
  title: {
    fontSize: '20px',
    lineHeight: '20px',
    color: '#666666',
    marginLeft: '0.5em',
  },
  container: {
    backgroundColor: '#f9f9f9',
    padding: '2em',
    borderRadius: '1em',
  },
}

const CardsView = ({
  filteredProblems,
  selectedTopics,
  setModalProblemId,
  mentor,
  user,
}) => {
  const [selectionMode, setSelectionMode] = useState(false)
  const [selectedProblemIds, setSelectedProblemIds] = useState([])
  const [questionSelectionLoading, setQuestionSelectionLoading] =
    useState(false)
  const [showModal, setShowModal] = useState(false)

  const addToSelectedProblems = problem => {
    setSelectedProblemIds([...selectedProblemIds, problem.id])
  }

  const removeFromSelectedProblems = problem => {
    setSelectedProblemIds(
      selectedProblemIds.filter(id => {
        return id !== problem.id
      }),
    )
  }

  const selectedProblems = useMemo(
    () =>
      filteredProblems.filter(problem =>
        selectedProblemIds.includes(problem.id),
      ),
    [selectedProblemIds, filteredProblems],
  )

  const emptyView = () => (
    <>
      <EmptyState />
    </>
  )

  const clickSubmitMentorHelp = () => {
    if (selectedProblemIds.length > 1) {
      setQuestionSelectionLoading(true)
      const mentorId = mentor.userId
      const studentId = user.userId

      // track click
      amplitudeInstance.logEvent('Clicked Discuss Problem w/ Mentor Button', {
        practiceProblemName: 'Exam Prep Homepage',
        mentorId,
        studentId,
      })

      const practiceProblemName = selectedProblems
        .map(card => {
          return card.title
        })
        .join(', ')
      startConvoWithMentor({ mentorId, studentId, practiceProblemName })
        .then(response => {
          setSelectedProblemIds([])
          setQuestionSelectionLoading(false)
          setSelectionMode(false)
          setShowModal(true)
        })
        .catch(err => {
          setSelectedProblemIds([])
          setQuestionSelectionLoading(false)
          setSelectionMode(false)
        })
    } else {
      clickCancelMentorHelp()
    }
  }

  const clickCancelMentorHelp = () => {
    setSelectedProblemIds([])
    setSelectionMode(false)
  }

  const cardsView = () => {
    let topicsText

    // Text to present on the cards view title
    Array.isArray(selectedTopics) && selectedTopics.length === 1
      ? (topicsText = selectedTopics[0])
      : (topicsText = selectedTopics.join(', '))

    return (
      <div style={style.container}>
        <Row>
          <h1 style={style.title}>Questions - {topicsText}</h1>
        </Row>
        <Row>
          {filteredProblems.map((problem, index) => (
            <Card
              key={'card-' + index}
              problem={problem}
              setModalProblemId={setModalProblemId}
              selectionMode={selectionMode}
              addToSelectedProblems={addToSelectedProblems}
              removeFromSelectedProblems={removeFromSelectedProblems}
            />
          ))}
        </Row>

        <QuestionSelectionStickyBar
          showBar={selectionMode}
          selectedProblems={selectedProblems}
          cancelClicked={clickCancelMentorHelp}
          submitClicked={clickSubmitMentorHelp}
          submitButtonLoading={questionSelectionLoading}
        />
        <Modal
          visible={showModal}
          footer={null}
          wrapClassName='exam-prep-modal'
          width={750}
        >
          <YourMentorHasBeenNotified
            onClick={() => {
              setShowModal(false)
            }}
          />
        </Modal>
      </div>
    )
  }

  if (!filteredProblems || filteredProblems.length < 1) {
    return emptyView()
  } else {
    return cardsView()
  }
}

export default CardsView
