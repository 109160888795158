import {
  ONBOARD_USER,
  SWITCH_COURSE,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_COMPLETE,
  UPDATE_PROFILE,
  UPDATE_EMAIL_PREFERENCES,
} from '../types'

const INITIAL_STATE = {
  edlyftCohort: '',
  firstName: '',
  lastName: '',
  isMentor: null,
  email: '',
  university: '',
  userId: '',
  hasOnboarded: null,
  dateAdded: '',
  photoURL: '',
  fetchedProfileData: null,
  fetchedProfileDataError: false,
  subscriptionId: null,
  isSubscribed: false,
  subscriptionStatus: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_PREFERENCES: 
      return {
        ...state,
        ...action.data,
      }
      
    case FETCH_USER_INFO_ERROR:
      return {
        ...state,
        fetchedProfileData: true,
        fetchedProfileDataError: true,
      }

    case FETCH_USER_INFO_COMPLETE:
      return {
        ...state,
        ...action.user,
        // isMentor is currently set as a string in the profile
        // data response.
        isMentor: action.user.isMentor === true ? true : false,
        fetchedProfileData: true,
        edlyftCohort: Array.isArray(action.user.courses) ? action.user.courses[0] : action.user.courses
      }

    case ONBOARD_USER:
      return {
        ...state,
        ...action.data,
        hasOnboarded: true,
        edlyftCohort: Array.isArray(action.data.courses) ? action.data.courses[0] : action.data.courses,
      }

    case SWITCH_COURSE:
      return {
        ...state,
        edlyftCohort: action.course,
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.data
      }

    default: return state
  }
}

export default reducer