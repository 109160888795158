import amplitude from 'amplitude-js'

import NewPageIcon from './NewPageIcon'
import SelectProblemIcon from './SelectProblemIcon'
import PlayIcon from '../assets/playIcon.svg'

import defaultPoster from '../assets/video-preview.jpg'
import { amplitudeInstance } from '../../../../amplitude'

const style = {
  footer: {
    display: 'flex',
    paddingTop: '1em',
    justifyContent: 'left',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    fontWeight: 400,
    fontSize: '16px',
    background: '#F4F8FE',
    padding: '8px',
    borderRadius: '5px',
    border: 0,
    height: '70px',
  },
  thumbnail: {
    display: 'inline-flex',
    maxWidth: '80px',
    maxHeight: '60px',
    borderRadius: '5px',
    marginRight: '10px',
    aspectRatio: '3/2',
    filter: 'blur(0.25rem) brightness(80%)',
  },
  playContainer: {
    display: 'inline-flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButton: {
    zIndex: 110,
    width: '30px',
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  newPageIcon: {
    position: 'absolute',
    right: '14px',
    bottom: '24px',
  },
}

const FooterSolved = props => {
  const {
    problem,
    setModalProblemId,
    selectionMode,
    addToSelectedProblems,
    removeFromSelectedProblems,
  } = props

  const clickPlaySolution = e => {
    setModalProblemId(problem.id)
    e.stopPropagation()

    // amplitude tracker
    const identify = new amplitude.Identify()
    amplitudeInstance.identify(identify)
    amplitudeInstance.logEvent('Clicked to Open Solution Video Modal', {
      ...problem,
    })
  }

  let thumbnailUrl
  if (problem.solutionVideoUrl) {
    thumbnailUrl = problem.solutionImageUrl
  } else {
    thumbnailUrl = defaultPoster
  }

  return (
    <div>
      <div className={'flex'} style={style.footer}>
        <div
          id='examprep-watch-solution'
          style={style.container}
          onClick={clickPlaySolution}
        >
          <div style={style.playContainer}>
            <img
              style={style.thumbnail}
              src={thumbnailUrl}
              alt={problem.title}
            ></img>
            <img style={style.playButton} alt='play' src={PlayIcon}></img>
          </div>
          <span>Watch Solution Video</span>
        </div>

        {/* icon when hovering over card */}
        {selectionMode === true ? (
          <SelectProblemIcon
            problem={problem}
            addToSelectedProblems={addToSelectedProblems}
            removeFromSelectedProblems={removeFromSelectedProblems}
          />
        ) : (
          <NewPageIcon style={style.newPageIcon} />
        )}
      </div>
    </div>
  )
}

export default FooterSolved
