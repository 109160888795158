import moment from 'moment-timezone'

const tzs = [
  'US/Pacific',
  'US/Alaska',
  'US/Arizona',
  'US/Central',
  'US/Eastern',
  'US/Hawaii',
  'US/Mountain',
  'America/Puerto_Rico',
]

export const timezones = tzs.map(tz => ({
  value: tz,
  text: moment.tz(tz).zoneName(),
  shortForm: moment.tz(tz).zoneAbbr(),
}))
