import axios from '../../axios-orders'

export const checkSubscription = (props) => {
  const { subscriptionId, updateProfile, setAppReady, userSubId } = props

  // subscription ids that are allowed access
  const validSubscriptionId = ['pass', 'scholarship', 'mentor']

  if (validSubscriptionId.includes(subscriptionId)) {
    updateProfile({
      isSubscribed: true,
    })
    // Set app ready state
    setAppReady(true)
  } else if (subscriptionId === 'invalid') {
    updateProfile({
      isSubscribed: false,
    })
    // Set app ready state
    setAppReady(true)
  } else {
    axios
      .get('/student/subscription', {
        params: {
          id: userSubId, // Use sub Id in state or URLm
        },
      })
      .then(({ data: { status } }) => {
        if (
          status === 'in_trial' ||
          status === 'active' ||
          status === 'non_renewing'
        ) {
          return updateProfile({
            isSubscribed: true,
          })
        }
        updateProfile({
          isSubscribed: false,
          subscriptionStatus: status,
        })
      })
      .catch(e => {
        updateProfile({
          isSubscribed: false,
        })
      })
      .finally(() => {
        setAppReady(true)
      })
  }
}
