import React from 'react'

const UploadedItem = ({
  title,
  className,
  onActionClick,
  disableAction,
  actionText,
  titleClass,
  actionButtonClass,
}) => (
    <div className={`mb-2 text-violet-dark flex items-center justify-between ${className}`}>
      <span className={`flex-1 block text-truncate ${titleClass}`}>{title}</span>
      {
        <button type="button" disabled={disableAction} onClick={onActionClick} className={actionButtonClass}>
          {actionText ? actionText : 'X Delete'}
        </button>
      }
    </div>
  )

export default UploadedItem
