import React, { useState } from 'react'
import Logo from '../../assets/images/edlyft-logo.png'
import { GoogleButton, FacebookButton, Button } from '../Form/Button'
import { useForm } from 'react-hook-form'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { BrowserRouter as Router, Link, withRouter} from "react-router-dom";

import config from '../../utils/config.json'
import { Auth } from 'aws-amplify';

// need to still add error states (like -- user with this email already exists)
//<script src="https://apis.google.com/js/platform.js" async defer></script>

const TERMS_LINK = 'https://edlyft.com/terms'
const PRIVACY_LINK = 'https://edlyft.com/privacy'

const Label = ({ text, htmlFor }) => (
  <label htmlFor={htmlFor} className="text-tiny leading-15 mb-1 block" style={{ color: 'rgba(102, 102, 102, 0.3)' }}>
    {text}
  </label>
)

async function signUp(username, password, email) {
  try {
      const { user } = await Auth.signUp({
          username,
          password,
          attributes: {
              email,          
          }
      });
      console.log(user);
  } catch (error) {
      console.log('error signing up:', error);
  }
}


const AuthForm = ({ title }) => {
  const [showPassword, setShowPassword] = useState(false)
  const { register, handleSubmit } = useForm()

  const handleFormSubmit = (data) => {
    signUp(data.email, data.password, data.email);
    console.log(data);
    //history.(location('/verify'));
    //Render verify component '/verify'
  }


  return (
    <Router>
    <div className="py-10 px-8">
      <img className="mb-16 mx-auto" style={{ width: '88px' }} src={Logo} />
      <h1 className="mb-6 font-medium text-8xl text-center text-center text-violet-dark leading-40">{title}</h1>
      <div style={{ maxWidth: '400px', boxShadow: '0px 10px 50px rgba(5, 76, 70, 0.03)', borderWidth: '1px' }} className="p-8 rounded-lg border-gray-lighter-hard">
        <GoogleButton className="mb-4 bg-gray-lightest" text="Sign Up with Google" />
        <FacebookButton className="mb-10" text="Sign Up with Facebook" style={{ backgroundColor: 'rgba(60, 90, 154, 0.1)' }} />
        <span className="leading-18 text-base text-gray-dark block text-center mb-8">Or Sign Up with Email:</span>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mb-4">
            <Label text="Email Address" htmlFor="email" />
            <input style={{ color: '#BFBFBF' }} placeholder="Enter your email address" className="font-light rounded form-input bg-gray-lightest" id="email" type="email" name="email" ref={register} />
          </div>
          <div className="mb-4">
            <Label text="Password" htmlFor="password" />
            <div className="relative flex items-center">
              <input style={{ color: '#BFBFBF' }} placeholder="Create a password" className="font-light rounded form-input bg-gray-lightest" id="password" type={showPassword ? "text" : "password"} name="password" ref={register} />
              <span style={{ right: '10px' }} onClick={() => setShowPassword(!showPassword)} className="absolute cursor-pointer">
                <EyeIcon />
              </span>
            </div>
          </div>
          <label className="mb-10">
            <input className="mr-2" type="checkbox" name="agree" />
            <span className="text-tiny leading-17 font-thin text-gray-dark">
              I agree to the <a className="font-medium underline" href={TERMS_LINK}>Terms of service</a> and <a className="font-medium underline" href={PRIVACY_LINK}>Privacy Policy </a>
            </span>
          </label>
          <Button className="bg-blue-normal block w-full" type="submit" text="Create New Account"/>
        </form>
      </div>
      <span className="mt-12 text-lg font-thin block text-center text-black">
        Already have an account? <a className="font-medium underline" href="/login"> Log in</a>
        {/* <Link to="/login" replace>Log in!!!!</Link> */}
      </span>


    </div>
    </Router>
  )
}

export default AuthForm;
// calling export default withRouter(AuthForm) caused an issue. Unlike with AuthFormLogin... 
// export default AuthForm
