import React from 'react'
import UserDropdown from './UserDropdown'
import CourseSwitcher from './CourseSwitcher'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left-blue.svg'
import MobileNav from './MobileNav'
import './index.css'
const Header = ({ courses, isMentor, photoURL, backLink, backLinkTitle, disableCourseSwitcher }) => {
  return (
    <>
      <div className="app-header flex items-center border-b-2 border-gray-thin bg-white py-6">
        <div className="flex items-center">
          <MobileNav />
          {
            backLink &&
            <Link to={backLink}>
              <div className="ml-4 flex items-center">
                <ArrowLeft />
                <span className="ml-3 leading-23 text-violet-dark font-medium text-xl">{backLinkTitle}</span>
              </div>
            </Link>
          }

        </div>
        <div className="flex-1 px-6">
          {
            !disableCourseSwitcher &&
            <>
              {
                (!isMentor && Array.isArray(courses) && courses.length > 1) &&
                <div className="flex justify-end">
                  <CourseSwitcher />
                </div>
              }
            </>
          }
        </div>
        <div className="app-header-right relative flex justify-evenly items-center border-l-1 border-gray-thin px-6" style={{ width: '270px' }}>
          <span style={{ height: '30px', width: '2px' }} className="bg-gray-thin absolute left-0 top-0 bottom-0 my-auto"/>
          <div className="flex items-center">
            <UserDropdown photoURL={photoURL}/>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { user } = state
  const { isMentor, courses, photoURL } = user

  return {
    isMentor,
    courses,
    photoURL
  }
}

export default connect(mapStateToProps)(Header)
