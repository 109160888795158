import { createStore } from 'redux'
import rootReducer from './reducers'

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

// CRA will never append this to the production app
// process.env.NODE_ENV is always set to 'production' when built
// ref https://create-react-app.dev/docs/deployment/#customizing-environment-variables-for-arbitrary-build-environments
if (process.env.NODE_ENV === 'development') {
  window.store = store
}

export default store