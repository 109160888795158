import { SET_EXAMS_PLAN_PAYLOAD, GET_EXAMS_PLAN_PAYLOAD } from '../types'

const INITIAL_STATE = {
  examType: '',
  dateOfExam: '',
  totalTasks: 0,
  tasksCompleted: 0
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXAMS_PLAN_PAYLOAD:
      return {
        ...state,
       ...action.exams
      }

    case GET_EXAMS_PLAN_PAYLOAD:
      return {
        ...state,
        examType: action.examType,
        dateOfExam: action.dateOfExam,
        totalTasks: action.totalTasks,
        tasksCompleted: action.tasksCompleted
      }

    default:
      return state
  }
}

export default reducer
