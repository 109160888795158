import React, { useState } from 'react'
import NavbarDropdown from '../navbar-dropdown'
import { ReactComponent as IconArrowDown } from '../../assets/icons/arrow-down.svg'
import { amplitudeInstance } from '../../amplitude'
import { useAuth0 } from '@auth0/auth0-react'
import { connect } from 'react-redux'
import UpdateProfileModal from '../Modal/UpdateProfileModal'
import EmailPreferenceModal from '../Modal/EmailPreferenceModal'

const DropdownLink = ({ title }) => (
  <span className="user-dropdown__nav__item px-4 py-3 block leading-20 font-medium text-lg text-gray-storm">
    {title}
  </span>
)

const UserDropdown = ({ isMentor, display, photoURL }) => {
  console.log(display)
  const { logout } = useAuth0()
  const [isSidebarOpen, toggleSidebar] = useState(false)
  const [isShowEmailPreferences, setIsShowEmailPreferences] = useState(false)

  // Handle user logout click
  const handleLogout = () => {
    amplitudeInstance.setUserId(null)
    logout({ returnTo: window.location.origin })
  }

  return (
    <>
        <NavbarDropdown>
          <NavbarDropdown.Toggle>
            <span className="avatar-img" style={{  backgroundImage: `url(${photoURL})` }} />
          </NavbarDropdown.Toggle>
          <NavbarDropdown.CSSTransitionMenu className="border-gray-lighter-thin rounded-md bg-white user-dropdown__nav right-0 z-40" timeout={200}>
            <span className="px-4 py-3 border-b border-gray-thin block leading-20 font-medium text-lg text-violet-dark">{isMentor ? 'Mentor' : 'Student'}</span>
            <NavbarDropdown.Item className="user-dropdown__nav__item" onClick={() => toggleSidebar(true)}>
              <DropdownLink title="Manage Account" />
            </NavbarDropdown.Item>
            <NavbarDropdown.Item className="user-dropdown__nav__item" onClick={() => setIsShowEmailPreferences(true)}>
              <DropdownLink title="Preferences" />
            </NavbarDropdown.Item>
            <NavbarDropdown.Item className="user-dropdown__nav__item" onClick={handleLogout}>
              <DropdownLink title="Logout" />
            </NavbarDropdown.Item>
          </NavbarDropdown.CSSTransitionMenu>
        </NavbarDropdown>
      <NavbarDropdown>
        <NavbarDropdown.Toggle className="user-dropdown">
          <button className="flex items-center">
            <span style={{ width: '16px', height: '9px' }} className="ml-3 text-violet-dark">
              <IconArrowDown />
            </span>
          </button>
        </NavbarDropdown.Toggle>
        <NavbarDropdown.CSSTransitionMenu className="border-gray-lighter-thin rounded-md bg-white user-dropdown__nav right-0 z-40" timeout={200}>
          <span className="px-4 py-3 border-b border-gray-thin block leading-20 font-medium text-lg text-violet-dark">{isMentor ? 'Mentor' : 'Student'}</span>
          <NavbarDropdown.Item className="user-dropdown__nav__item" onClick={() => toggleSidebar(true)}>
            <DropdownLink title="Manage Account" />
          </NavbarDropdown.Item>
          <NavbarDropdown.Item className="user-dropdown__nav__item" onClick={() => setIsShowEmailPreferences(true)}>
            <DropdownLink title="Preferences" />
          </NavbarDropdown.Item>
          <NavbarDropdown.Item className="user-dropdown__nav__item" onClick={handleLogout}>
            <DropdownLink title="Logout" />
          </NavbarDropdown.Item>
        </NavbarDropdown.CSSTransitionMenu>
      </NavbarDropdown>
      <UpdateProfileModal open={isSidebarOpen} closeModal={() => toggleSidebar(false)} />
      <EmailPreferenceModal open={isShowEmailPreferences} closeModal={() => setIsShowEmailPreferences(false)} />
    </>
  )
}

const mapStateToProps = state => {
  const { user: { isMentor } } = state

  return {
    isMentor,
  }
}

export default connect(mapStateToProps)(UserDropdown)
