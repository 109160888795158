export const MAPPING_OF_CLASS_CALENDARS = {
  cs61a:
    'https://calendar.google.com/calendar/b/0/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=ZWRseWZ0LmNvbV90OXZzdGhvcnZjZXM1ODJwY2g1NGVmYmM2NEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23009688&amp;title=Edlyft%20CS61A%20&amp;showTabs=0&amp;mode=WEEK&amp;showPrint=0&amp;showNav=1',
  test: 'https://calendar.google.com/calendar/embed?src=ZWRseWZ0LmNvbV90ODZsNDhqcGRxdmZ1a2RhanNoYjI5OG9zZ0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t%40group.calendar.google.com&ctz=America%2FLos_Angeles',
  test2:
    'https://calendar.google.com/calendar/b/0/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&src=ZWRseWZ0LmNvbV90OXZzdGhvcnZjZXM1ODJwY2g1NGVmYmM2NEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23009688&title=Edlyft%20CS61A%20&showTabs=0&mode=WEEK&showPrint=0&showNav=1',
  cs61b:
    'https://calendar.google.com/calendar/b/0/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=ZWRseWZ0LmNvbV9tY25uYmttNnZiM3MzMmlsc21qNXZsNnF1OEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23B39DDB&amp;title=Edlyft%2061b%20Sessions&amp;showPrint=0&amp;showTabs=0&amp;mode=WEEK',
  cs70: 'https://calendar.google.com/calendar/b/0/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=ZWRseWZ0LmNvbV90ODZsNDhqcGRxdmZ1a2RhanNoYjI5OG9zZ0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23D81B60&amp;title=CS70%20Sessions&amp;showPrint=0&amp;showTabs=0&amp;mode=WEEK',
}

export const MAPPING_OF_CLASS_SLACKLINKS = {
  CS61A: 'https://edlyft.slack.com/archives/C0169B9E59N',
  CS61B: 'https://edlyft.slack.com/archives/C015N37AU20',
  CS70: 'https://edlyft.slack.com/archives/C015G42R3DZ',
  Math54: 'https://edlyft.slack.com/archives/C01515JA3RV',
  Data8: 'https://edlyft.slack.com/archives/C015N38UPV2',
  CS32: 'https://edlyft.slack.com/archives/C01751EHJ57',
  PIC10A: 'https://edlyft.slack.com/archives/G016WEHJ3KN',
}

export const MENTOR_RECOMMENDED_TEXT = 'Mentor recommended'

export const ALL_TOPICS_TEXT = 'All Topics'
