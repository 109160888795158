import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Divider, Tooltip, Tabs, Tag } from 'antd'
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Modal from 'react-responsive-modal'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import amplitude from 'amplitude-js'
import withPageWrapper from '../../../hoc/AuthPageWrapper'
import { capitalizeFirstLetter } from '../../../utils/capitalize'
import TagLevel from '../ExamPrep/Topics/TagLevel'
import { useMentors } from '../../../hooks/useMentors'

import { amplitudeInstance } from '../../../amplitude'
import DiscussProblemMessage from '../ExamPrep/Modal/DiscussProblemMessage'
import Player from '../ExamPrep/Modal/Player'
import { usePracticeProblems } from '../../../hooks/usePracticeProblems'
import PutPracticeProblemModal from '../../Modal/PutPracticeProblemModal'

import { ReactComponent as FlagIcon } from '../ExamPrep/assets/flag.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg'
import { ReactComponent as LightBulbIcon } from './assets/lightbulb.svg'
import CommonSidebar from '../../ViewsContent/CommonSidebar'
import { Button } from '@edlyft/design-system'
import ProblemStar from './ProblemStar/index'
import { useEdlyftCohorts } from '../../../hooks/useEdlyftCohorts'
import { isCohortPracticeProblemStarred } from '../../../utils/isCohortPracticeProblemStarred'
import useMetricsApi, { NPPEvent } from '../../../hooks/useMetricsApi'
import { saveInteraction } from '../../../utils'

const { TabPane } = Tabs

const modalStyles = {
  header: {
    borderColor: 'var(--color-gray-lighter-hard)',
    paddingTop: '40px',
  },
  modal: {
    overlay: {
      zIndex: '2000',
    },
    modal: {
      maxWidth: '520px',
      borderRadius: '20px',
      minHeight: '400px',
      maxHeight: '628px',
    },
    closeButton: {
      right: '24px',
      top: '24px',
    },
  },
}

const UpdatePracticeProblemSuccessModal = ({ problemTitle, open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      center
      styles={modalStyles.modal}
      classNames={{
        modal:
          'w-full overflow-y-auto flex flex-col justify-center items-center gap-4 py-6 px-12 text-center',
        closeButton: 'outline-none',
      }}
      closeIcon={<CloseIcon />}
    >
      <FlagIcon className='text-xl mt-10' />
      <div className='font-bold text-4xl'>All Done!</div>
      <div className='flex-grow'>
        Your problem “{problemTitle}” has been updated successfully.
      </div>
      <button
        className='bg-blue-normal py-3 px-8 text-white rounded font-medium mb-4'
        onClick={() => setOpen(false)}
      >
        OK
      </button>
    </Modal>
  )
}

const UpdatePracticeProblemFailureModal = ({
  open,
  setOpen,
  onGoBackClick = () => {},
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      center
      styles={modalStyles.modal}
      classNames={{
        modal:
          'w-full overflow-y-auto flex flex-col justify-center items-center gap-4 py-6 px-12 text-center',
        closeButton: 'outline-none',
      }}
      closeIcon={<CloseIcon />}
    >
      <ErrorIcon className='text-xl mt-10' />
      <div className='font-bold text-4xl'>There was a problem</div>
      <div className='flex-grow'>
        There was an issue updating this problem. Please try again soon.
      </div>
      <button
        className='bg-blue-normal py-3 px-8 text-white rounded font-medium mb-4'
        onClick={onGoBackClick}
      >
        Go back
      </button>
    </Modal>
  )
}

const PracticeProblemDetails = ({
  userId,
  courses,
  edlyftCohort,
  isMentor,
  screenOut,
  isActive,
  firstName,
  lastName,
}) => {
  const [displayMessage, setDisplayMessage] = useState({})
  const [tab, setTab] = useState('problem')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [problemTitle, setProblemTitle] = useState()
  const [failureModalOpen, setFailureModalOpen] = useState(false)

  const [cohort] = useEdlyftCohorts([edlyftCohort])

  const openModal = () => {
    setEditModalOpen(true)
    setSuccessModalOpen(false)
    setFailureModalOpen(false)
  }
  const closeModal = () => setEditModalOpen(false)

  const onSuccess = problem => {
    setFailureModalOpen(false)
    setSuccessModalOpen(true)
    setProblemTitle(problem.title)
    setEditModalOpen(false)
  }
  const onError = errorMessage => {
    setSuccessModalOpen(false)
    setFailureModalOpen(true)
    console.error(errorMessage)
    setEditModalOpen(false)
  }

  const { id } = useParams()
  const { selectedProblem: problem, problems } = usePracticeProblems(
    edlyftCohort,
    id,
  )
  const { mentor } = useMentors(courses, edlyftCohort)
  const isProblemStarred = useMemo(
    () => isCohortPracticeProblemStarred(cohort, id),
    [cohort, id],
  )

  const difficultyTag = useMemo(
    () =>
      problem && <TagLevel level={capitalizeFirstLetter(problem.difficulty)} />,
    [problem],
  )

  // parsed practice problem data
  const { course, questionDescription } = useMemo(() => {
    if (!problem) return {}

    const { truncatedEdlyftCohort, questionDescription: description } = problem
    const splitEdlyftCohort = truncatedEdlyftCohort.split('_')

    const course =
      splitEdlyftCohort.length === 1
        ? splitEdlyftCohort[0]
        : splitEdlyftCohort[1]

    const questionDescription =
      typeof description === 'string' && description.length > 0
        ? description
        : 'Please solve the problem pictured below.'

    return { course, questionDescription }
  }, [problem])

  const { enqueue } = useMetricsApi()

  const handleBook1On1 = (mentorId, studentId, practiceProblemName) => {
    // track click
    const identify = new amplitude.Identify()
    amplitudeInstance.identify(identify)
    amplitudeInstance.logEvent(
      'Clicked Book 1:1 for Problem w/ Mentor Button',
      {
        practiceProblemName: practiceProblemName,
        mentorId: mentorId,
        studentId: studentId,
      },
    )

    window.open(mentor.calendlyLink, '_blank')
  }

  const onTabChange = tab => {
    const identify = new amplitude.Identify()
    amplitudeInstance.identify(identify)

    const eventName = `Clicked on ${
      tab === 'problem' ? 'problem' : 'solution'
    } tab`
    amplitudeInstance.logEvent(eventName, {
      cohort: edlyftCohort,
      id: problem.id,
      problemName: problem.title,
    })
    enqueue(NPPEvent)
    saveInteraction({
      resourcePoster: '',
      edlyftCohort,
      resourceType: 'practiceProblem',
      resourceId: problem.id,
      screenOut,
      userId,
      isActive,
      isMentor,
      resourceTitle: eventName,
      userName: `${firstName} ${lastName}`,
    })

    setTab(tab)
  }
  const onClickSeeSolution = () => {
    const identify = new amplitude.Identify()
    amplitudeInstance.identify(identify)

    const eventName = 'Clicked on See Solution of a Practice Problem'
    amplitudeInstance.logEvent(eventName, {
      cohort: edlyftCohort,
      id: problem.id,
      problemName: problem.title,
    })
    enqueue(NPPEvent)
    saveInteraction({
      resourcePoster: '',
      edlyftCohort,
      resourceType: 'practiceProblem',
      resourceId: problem.id,
      screenOut,
      userId,
      isActive,
      isMentor,
      resourceTitle: eventName,
      userName: `${firstName} ${lastName}`,
    })

    setTab('solution')
  }

  /**
   * SIDE EFFECTS
   */

  // landed on problem page amplitude event
  const problemLoadedRef = useRef()
  useEffect(() => {
    if (!problemLoadedRef.current && problem) {
      const identify = new amplitude.Identify()
      amplitudeInstance.identify(identify)
      amplitudeInstance.logEvent('Landed on Practice Problem Page', {
        cohort: edlyftCohort,
        id: problem.id,
        problemName: problem.title,
      })

      problemLoadedRef.current = problem
    }
  }, [problem, edlyftCohort])

  /**
   * COMPONENTS
   */
  const book1On1 = problem && mentor.calendlyLink && (
    <div className='bg-green-lightest p-5 rounded-xl text-gray-dark text-tiny'>
      <span className='font-light'>Still need help on this? </span>
      <button
        onClick={() => handleBook1On1(mentor.userId, userId, problem.title)}
        className='inline text-blue-normal font-medium'
      >
        Book 1:1 with your mentor
      </button>
    </div>
  )
  const helpMentor = <div className='mt-4'>{book1On1}</div>

  return problem ? (
    <>
      <DiscussProblemMessage
        isVisable={displayMessage.visable}
        isSuccessful={displayMessage.successful}
        redirectUrl={displayMessage.redirectUrl}
        closeCallback={() => {
          setDisplayMessage({ visable: false, successful: true })
        }}
      />
      {isMentor && (
        <>
          <PutPracticeProblemModal
            existingProblem={problem}
            open={editModalOpen}
            closeModal={closeModal}
            onSuccess={onSuccess}
            onError={onError}
          />
          <UpdatePracticeProblemSuccessModal
            problemTitle={problemTitle}
            open={successModalOpen}
            setOpen={setSuccessModalOpen}
          />
          <UpdatePracticeProblemFailureModal
            open={failureModalOpen}
            setOpen={setFailureModalOpen}
            onGoBackClick={openModal}
          />
        </>
      )}
      <div className='flex flex-no-wrap items-center'>
        <div className='flex-grow'>
          <div className='text-base flex gap-1 text-gray-dark-text'>
            <span className='font-medium'>{course}</span>
            <span className='font-medium'>|</span>
            {problem.onExams.map((exam, key) => (
              <span className='' key={key}>
                {exam}
              </span>
            ))}
          </div>
          <div className='flex gap-2 items-center'>
            <span className='text-2xl font-medium text-violet-dark'>
              {problem.title}
            </span>
            {difficultyTag}
          </div>
        </div>
        {isMentor ? (
          <button
            className='inline-flex gap-3 items-center border text-blue-normal bg-blue-lighter px-5 py-3 font-medium rounded'
            onClick={() => setEditModalOpen(true)}
          >
            <EditOutlined />
            Edit problem
          </button>
        ) : (
          <ProblemStar starred={isProblemStarred} />
        )}
      </div>
      <Tabs defaultActiveKey='problem' activeKey={tab} onChange={onTabChange}>
        <TabPane key='problem' tab='Problem'>
          <div className='flex items-stretch justify-start gap-6'>
            <div className='w-2/3'>
              <div className='text-violet-dark font-semibold mb-3'>
                Problem Description
              </div>
              <p>{questionDescription}</p>
              {problem.questionImageUrl && (
                <>
                  <div className='text-violet-dark font-semibold mb-3 mt-8'>
                    Problem to Solve
                  </div>
                  <img className='' src={problem.questionImageUrl} />
                </>
              )}
              {problem.hints && (
                <div className='bg-gray-lightest rounded-2xl p-5 flex gap-4 mt-8'>
                  <LightBulbIcon className='h-8' />
                  <div className='text-gray-dark'>
                    <div className='text-violet-dark font-medium mb-3'>
                      Hints
                    </div>
                    <div className='whitespace-pre-wrap'>{problem.hints}</div>
                  </div>
                </div>
              )}
            </div>
            <div className='w-1/3'>
              <div className='font-semibold text-violet-dark'>
                Related concepts
              </div>
              <div className='mt-4 text-gray-dark'>
                {problem.concepts.map((concept, key) => (
                  <Tag
                    color='var(--color-blue-lightest)'
                    style={{
                      color: 'var(--color-gray-dark)',
                      borderRadius: 100,
                    }}
                    className=' rounded-full'
                    key={key}
                  >
                    {concept}
                  </Tag>
                ))}
              </div>
              <div className='mt-6 font-semibold text-gray-disabled flex gap-1 items-center'>
                <span className=' text-violet-dark'>Relavant exams</span>
                <Tooltip
                  color='white'
                  overlayInnerStyle={{
                    borderRadius: 10,
                    padding: '0.75rem 0.875rem',
                  }}
                  title={
                    <span className='text-gray-dark-text'>
                      These are exams where you’ll likely see a question like
                      this
                    </span>
                  }
                >
                  <ExclamationCircleOutlined className='cursor-pointer' />
                </Tooltip>
              </div>
              <div className='mt-4'>
                <div>
                  <span className='font-semibold text-gray-dark-text'>
                    {course}{' '}
                  </span>
                  <span className='text-gray-dark'>
                    {problem.onExams.join(', ')}
                  </span>
                </div>
              </div>
              {helpMentor}
            </div>
          </div>
        </TabPane>
        <TabPane key='solution' tab='Solution'>
          <div className='flex items-stretch justify-start gap-6'>
            <div className='w-2/3'>
              {problem.solutionDescription && (
                <>
                  <div className='text-violet-dark font-semibold mb-3'>
                    Solution Description
                  </div>
                  <p>{problem.solutionDescription}</p>
                </>
              )}
              {problem.solutionImageUrl && (
                <>
                  <div className='text-violet-dark font-medium mb-3'>
                    Solution image
                  </div>
                  <img src={problem.solutionImageUrl} />
                </>
              )}
              {problem.solutionVideoUrl && (
                <>
                  <div className='text-violet-dark font-medium mb-3 mt-12'>
                    Solution video
                  </div>
                  <Player problem={problem} />
                </>
              )}
            </div>
            <div className='w-1/3'>{helpMentor}</div>
          </div>
        </TabPane>
      </Tabs>
      <Divider />
      {tab === 'problem' && (
        <div className='w-full flex flex-wrap justify-between items-baseline bg-blue-lightest p-6 gap-3'>
          <span className='text-center sm:text-left flex-grow text-gray-70 leading-22 font-medium'>
            Are you done solving this problem?
          </span>
          <Button
            className='px-6 py-3 text-lg leading-16 rounded-md mx-auto'
            onClick={onClickSeeSolution}
          >
            See solution
          </Button>
        </div>
      )}
    </>
  ) : !problem && problems.length > 0 ? (
    <div className='text-10xl font-bold font text-gray-dark text-center tracking-wider'>
      404
    </div>
  ) : (
    <div></div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  const {
    userId,
    courses,
    edlyftCohort,
    photoURL: userPhotoURL,
    isMentor,
    screenOut,
    isActive,
    firstName,
    lastName,
  } = user

  return {
    userId,
    courses,
    edlyftCohort,
    userPhotoURL,
    isMentor,
    screenOut,
    isActive,
    firstName,
    lastName,
  }
}

export default connect(mapStateToProps)(
  withPageWrapper(
    PracticeProblemDetails,
    'practice-problem',
    '/exam-prep',
    'Exam Prep',
    CommonSidebar,
  ),
)
