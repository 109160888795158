import React, { useState, useEffect, useRef } from 'react'
import axios from '../../axios-orders'
import ResourceInd from '../ResourceInd'
import ResourceListFormFilter from '../ResourceInd/FormFilter'
import UploadRecordingModal from '../Modal/UploadRecordingModal'
import RecordingsPlaceholder from '../ContentLoaders/SingleRecordingLoader'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { currentEdlyftTerm } from '../../config'
import { amplitudeInstance } from '../../amplitude'
import { getCommonCohortTitle } from '../../helpers'
import { stripLastString } from '../../utils/stripString'

const ArchivedRecordings = React.lazy(() => import('../ArchivedRecordings'))
const ArchivedRecordingsLoader = React.lazy(() =>
  import('../ContentLoaders/ArchivedRecordingsLoader')
)

const SECOND_COLUMN_WIDTH = 283
const COLUMN_SPACING = 64

// isMentor is used to track re-renders with useEffect
const RecordingList = ({ isMentor, edlyftCohort }) => {
  const [recordings, setRecordings] = useState([])
  const [fetchingItems, setFetchingItems] = useState(true)
  const [isUploadModalOpen, setUploadModalOpen] = useState(false)
  const [uploadData, setUploadData] = useState({})
  const [tags, setTags] = useState([])
  const cancelSource = useRef(null)
  const location = useLocation()
  // Get search query
  const params = new URLSearchParams(location.search)
  const term = params.get('term') || currentEdlyftTerm?.type
  const selectedTag = params.get('tag') || ''
  const titleQuery = params.get('query') || ''
  const isArchive = term !== currentEdlyftTerm?.type
  let debounceTimer = null

  // Handle upload modal close
  const closeUploadModal = () => {
    setUploadData({})
    setUploadModalOpen(false)
  }

  const openUploadModal = (data) => {
    setUploadData(data)
    setUploadModalOpen(true)
  }

  useEffect(() => {
  
    // Use axios cancelToken to abort a request to prevent memory leaks
    // when attempting to setState after component is unmounted
    cancelSource.current = axios.CancelToken.source()
    setFetchingItems(true)
    // Fetch the session videos
    const selectedCourseTitle =  `${stripLastString(edlyftCohort, '_')}_${term}`

    axios
      .get('/common/recordings', {
        cancelToken: cancelSource.current.token,
        params: {
          term,
          edlyftCohort: isArchive
            ? selectedCourseTitle
            : edlyftCohort,
          tag: selectedTag,
          q: titleQuery,
        },
      })
      .then(({ data: { data, tags } }) => {
        // Sort by recordings date
        const sortedData = data.sort((a, b) =>
          a.sessionDate < b.sessionDate ? 1 : -1
        )
        setRecordings(sortedData)
        setTags(tags)
        setFetchingItems(false)
      })
      .catch((error) => {
        // Handle error
        console.log(error)
      })
    window.addEventListener('scroll', handleOnScroll)

    return () => {
      cancelSource.current.cancel('Cleanup function: cancel request')
      window.removeEventListener('scroll', handleOnScroll)
      window.clearTimeout(debounceTimer)
    }
  }, [isMentor, term, edlyftCohort, selectedTag, titleQuery])

  const getTitle = () => {
    let title = 'Course Recordings'
    // Check if term is defined
    if (term) {
      // Uppercase first letter of term from URL
      const formattedTerm =
        term.charAt(0).toUpperCase() +
        term.slice(1).replace(/([0-9]{4})/, ' $1') // Add space before year
      title += ' (' + formattedTerm + ')'
    }
    return title
  }

  const handleOnScroll = () => {
    // Debounce event so there's a slight delay before firing event
    if (debounceTimer) {
      window.clearTimeout(debounceTimer)
    }

    debounceTimer = window.setTimeout(function () {
      // run your actual function here
      amplitudeInstance.logEvent('Scrolls Recording List', {
        course: edlyftCohort,
      })
    }, 500)
  }
  return (
    <div>
      {!fetchingItems && (
        <ResourceListFormFilter tags={tags} title={getTitle()} />
      )}
      <div className='flex flex-wrap -mx-8'>
        <div className='px-8 w-auto flex-1'>
          {!fetchingItems && (
            <>
            {!recordings[0]? 
              <div>
              There are no recordings for this term. Check a term closer to the present moment.
              </div> :
              recordings.map((item) => (
                <div className='mb-10' key={item['video-id']}>
                  <ResourceInd
                    videoId={item['video-id']}
                    title={item.title}
                    description={item.description}
                    sessionDate={item.sessionDate}
                    videoURL={item.s3BucketPath}
                    openUploadModal={openUploadModal}
                    notes={item.notes}
                    edlyftCohort={edlyftCohort}
                    tags={item.tags}
                    links={item.links}
                    isMentor={isMentor}
                    isPrivate={item.isPrivate}
                    posterUrl={item.posterUrl}
                    duration={item.duration}
                  />
                </div>
              ))}
            </>
          )}
          {fetchingItems && <RecordingsPlaceholder count={3} />}
        </div>
        <div
          className='px-8'
          style={{ width: `${SECOND_COLUMN_WIDTH + COLUMN_SPACING}px` }}
        >
          <div className='mb-8'>
            <React.Suspense fallback={null}>
              {fetchingItems && <ArchivedRecordingsLoader />}
              {!fetchingItems && <ArchivedRecordings />}
            </React.Suspense>
          </div>
        </div>
      </div>
      <UploadRecordingModal
        open={isUploadModalOpen}
        closeModal={closeUploadModal}
        data={uploadData}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    user: { edlyftCohort },
  } = state

  return {
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(RecordingList)
