import React, { useCallback } from 'react'
import { Modal } from 'react-responsive-modal'
import FilePreviewer from 'react-file-previewer'
import 'react-responsive-modal/styles.css'
import 'react-file-previewer/dist/styles.css'
import '../../styles/components/modal-file-preview.css'
import mime from 'mime-types'

const FilePreviewModal = ({ open, closeModal, url }) => {
  let mimeType = 'application/pdf'
  let fileName = 'download'
  let fileExtension = 'pdf'

  try {
    let fileURL = url ? url.split('?')[0] : ''
    fileExtension = fileURL ? fileURL.split('.').pop() : 'pdf'
    mimeType = mime.lookup(fileExtension)
    fileName = fileURL
      ? fileURL.split('/').pop()
      : `${fileName}.${fileExtension}`
  } catch (e) {}

  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      styles={{
        overlay: {
          zIndex: '2000',
        },
        modal: {
          backgroundColor: 'transparent',
        },
      }}
      classNames={{
        modal: 'max-w-full w-full',
      }}
      showCloseIcon={true}
    >
      {fileExtension !== 'pptx' && (
        <FilePreviewer
          file={{
            url,
            mimeType,
            name: fileName,
          }}
        />
      )}
      {fileExtension === 'pptx' && (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
          width='100%'
          height='600px'
          frameBorder='0'
        ></iframe>
      )}
    </Modal>
  )
}

export default FilePreviewModal
