import React from 'react'
import Logo from '../../assets/images/edlyft-logo.png'
import NavbarDropdown from '../navbar-dropdown'
import { Link } from 'react-router-dom'
import SidebarContent from '../Sidebar/SidebarContent'
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg'

const MobileNav = () => {
  return (
    <div className="vlg:hidden flex items-center pl-6">
      <Link to="/dashboard">
        <img src={Logo} style={{ width: '80px', height: '40px', minWidth: '80px' }} alt="" />
      </Link>
      <NavbarDropdown style={{ display: 'inline-flex' }}>
        <NavbarDropdown.Toggle className="inline-block" style={{ width: '41px', height: '41px' }}>
          <button className="ml-2">
            <MenuIcon />
          </button>
        </NavbarDropdown.Toggle>
        <NavbarDropdown.CSSTransitionMenu style={{ top: '65px', width: '220px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} className="pt-4 border-gray-lighter-thin rounded-md bg-gray-lightest border-gray-light left-0 z-40" timeout={200}>
          <SidebarContent />
        </NavbarDropdown.CSSTransitionMenu>
      </NavbarDropdown>
    </div>
  )
}

export default MobileNav
