import React, { useRef, useState, useEffect } from 'react'
import { ReactComponent as IconMicrophone } from '../../assets/icons/microphone.svg'
import { ReactComponent as IconBin } from '../../assets/icons/bin.svg'
import { ReactComponent as IconPlay } from '../../assets/icons/play.svg'
import { ReactComponent as IconStop } from '../../assets/icons/stop.svg'
import { ReactComponent as IconPause } from '../../assets/icons/pause.svg'
import { ReactComponent as IconRecord } from '../../assets/icons/record.svg'
import { ReactComponent as IconReload } from '../../assets/icons/reload.svg'
import IconFrequency from '../../assets/images/visualizer.gif'
import { ReactMediaRecorder } from 'react-media-recorder'
import ReactAudioPlayer from 'react-audio-player'
import axios, { axiosDefault } from '../../axios-orders'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { amplitudeInstance } from '../../amplitude'
import { format, addSeconds } from 'date-fns'
import { useStopwatch } from 'react-timer-hook'

// 5 minute to seconds
const MAXIMUM_TIME = 300

// This props update timer i.e re-renders and uodate time using a stopwatch
// feature
const Timer = ({ paused, onEnd, maxTime, isControlled, setMaxTime }) => {
  const { start, pause, reset, seconds, minutes } = useStopwatch({
    autoStart: !isControlled,
  })
  // Compute total elapsed time in seconds
  const totalTimeInSeconds = seconds + (minutes * 60)
  // Determine stop and start of the timer
  useEffect(() => {
    if (paused) {
      pause()
    } else {
      start()
    }
  }, [paused])

  useEffect(() => {
    // Everytime component re-renders, check if time has elapsed
    checkTimeElapsed()
    // Check if a maximum time function props is set
    if (setMaxTime) {
      setMaxTime(totalTimeInSeconds)
    }
  })

  const checkTimeElapsed = () => {
    // Check if the total time elapsed is greater
    // than maximum time of the recording
    if (totalTimeInSeconds > maxTime) {
      // If it's true, we reset the time back to 0
      reset()
      // Check if an `onEnd` function is pass as props before calling
      if (onEnd) {
        onEnd(totalTimeInSeconds)
      }
    }
  }

  // Pad time string with a leading `0`.
  // e.g 2:0 becomes 02:00
  const leftFillNum = (num, targetLength) => {
    return num.toString().padStart(targetLength, 0);
  }

  return (
    <span style={{ color: '#8C8C8C' }} className="leading-13 text-sm">
      {`${leftFillNum(minutes, 2)}:${leftFillNum(seconds, 2)}`}/{format(addSeconds(new Date(0), maxTime), 'mm:ss')}
    </span>
  )
}

const PracticeProblemRecord = ({
  title,
  userId,
  problemId,
  edlyftCohort,
}) => {
  let audioRef = useRef(null)
  let recordingRef = useRef(() => null)
  const routeParams = useParams()
  let [counter, setCounter] = useState(3)
  const [isPlaying, setPlaying] = useState(false)
  const [recordState, setRecordState] = useState()
  const [recordingData, setRecordingData] = useState(null)
  const [maximumRecordTime, setMaxRecordTime] = useState(MAXIMUM_TIME)

  const amplitudeData = {
    cohort: edlyftCohort,
    practiceProblemTitle: title,
  }

  const uploadRecording = (mediaBlobUrl) => {
    // Recording is saved in .wav format
    const file = new File([mediaBlobUrl], 'recording.wav', {
      type: mediaBlobUrl.type,
      lastModified: new Date().getTime(),
    })
    // Get the mimetype
    const mime = file.type
    // Get file extension
    const extension = file.name.split('.').pop()
    // Get presigned URL for notes upload
    axios.get('/common/pre-signed/practice-problem-recording', {
      params: {
        mime,
        extension,
      }
    }).then(({ data }) => {
      const uploadURL = data.uploadURL
      const filename = data.filename
      // Create a new file with updated name
      const updatedFile = new File([file], filename, {
        type: file.type
      })
      // Upload video to S3
      axiosDefault.put(uploadURL, updatedFile, {
        headers: {
          'Content-Type': mime,
          'Content-Disposition': 'attachment',
          'x-amz-acl': 'private',
        }
      }).then(() => {
        // Get the clean video URL without query parameters
        // from the presigned URL
        const noteURL = uploadURL.split('?')[0]
        return saveRecording(noteURL)
      }).catch(e => {
        // Handle S3 upload errors
        console.log(e)
      })
    }).catch(e => {
      // Handle presigned URL error
      console.log(e)
    })
  }

  const saveRecording = (recordingLink) => {
    axios.post('/common/practice-problems/recording', {
      userId,
      problemId,
      recordingLink,
      cohort: edlyftCohort,
      conceptId: routeParams.id,
    }).then(({ data: { id, dateCreated } }) => {
      // Set the uploaded recording data
      setRecordingData({
        id,
        dateCreated,
      })
    }).catch(e => {
      // Handleerror
      console.log(e)
    })
  }

  const startRecording = () => {
    // Set recording state
    setRecordingData(null)
    setRecordState('starting')
    const interval = setInterval(() => {
      if (counter === 1) {
        setRecordState('recording')
        recordingRef.current.startRecording()
        setCounter(3)
        // Clear interval
        return clearInterval(interval)
      }
      setCounter(--counter)
    }, 1000)
  }

  const doneRecording = () => {
    amplitudeInstance.logEvent('Clicked Done Recording for Practice Problem', amplitudeData)
    setRecordState('done')
  }

  const toggleRecording = () => {
    if (!isPlaying) {
      amplitudeInstance.logEvent('Clicked Play Recording for Practice Problem', amplitudeData)
    }
    // Toggle recording play
    try {
      if (isPlaying) {
        audioRef.current.audioEl.current.pause()
      } else {
        audioRef.current.audioEl.current.play()
      }
    } catch (e) {
      console.log(e)
    }
    setPlaying(!isPlaying)
  }

  const deleteRecording = () => {
    setRecordState(null)
    axios.delete('/common/practice-problems/recording', {
      data: recordingData
    }).catch(e => {
      // Handleerror
      console.log(e)
    })
  }

  const recordAgain = () => {
    deleteRecording()
    startRecording()
  }

  return (
    <div style={styles.encouragementBlock} className="pl-3 pr-5 py-5 rounded flex items-center overflow-hidden">
      {
        (!recordState || recordState === 'starting') &&
        <>
          <span className="leading-15 text-sm font-medium text-gray-darkest flex-1">
            Talk through how you arrived at your answer or got stuck. This will help solidify your understanding.
          </span>
          <div className="ml-3 relative" style={styles.rightWrapper}>
            <span style={styles.microphoneCircle} className={`${recordState === 'starting' ? 'practice-problem__record__circe' : null} absolute rounded-full left-0`}></span>
            {
              !recordState &&
              <button onClick={() => {
                amplitudeInstance.logEvent('Clicked Mic Button for Practice Problem', amplitudeData)
                startRecording()
              }} style={styles.microphoneIconWrapper} className="ml-4 relative text-blue-normal">
                <div style={styles.microphoneIcon} className="absolute rounded-full inline-block">
                  <IconMicrophone />
                </div>
              </button>
            }
            {
              recordState === 'starting' &&
              <span className="rounded-full flex items-center justify-center bg-blue-normal text-white" style={styles.rightWrapper}>{counter}</span>
            }
          </div>
        </>
      }
      {
        recordState &&
        <ReactMediaRecorder
          audio
          onStop={uploadRecording}
          render={({
            clearBlobUrl,
            mediaBlobUrl,
            stopRecording,
            pauseRecording,
            resumeRecording,
            startRecording: startMediaRecorderRecording,
          }) => {
            recordingRef.current['startRecording'] = startMediaRecorderRecording
            return (
              <div className={`${recordState === 'recording' || recordState === 'done' || recordState === 'paused' ? 'flex' : 'hidden'} w-full flex-col items-center justify-center`}>
                <ReactAudioPlayer
                  src={mediaBlobUrl}
                  controls={false}
                  ref={(element) => {
                    audioRef.current = element
                  }}
                  onEnded={() => setPlaying(false)}
                />
                <div className="w-full flex items-center justify-between">
                  {
                    (recordState === 'recording' || recordState === 'done' || recordState === 'paused') &&
                    <>
                      {
                        recordState !== 'done' &&
                        <Timer
                          maxTime={MAXIMUM_TIME}
                          paused={recordState === 'paused'}
                          onEnd={() => {
                            setRecordState('done')
                            stopRecording()
                          }}
                          setMaxTime={setMaxRecordTime}
                        />
                      }
                      {
                        recordState === 'done' &&
                        <Timer
                          isControlled
                          maxTime={maximumRecordTime}
                          paused={!isPlaying}
                        />
                      }
                      <div className="relative flex-1 mx-2 ">
                        <div style={styles.frequencyBlock} className="bg-blue-normal"></div>
                        <img
                          className="inline-block h-full w-full absolute left-0 top-0 w-full px-4"
                          alt="Visualizer"
                          style={{ ...styles.frequency, ...(recordState === 'paused' ? { opacity: '0.1' } : {}) }}
                          src={IconFrequency}
                        />
                        {
                          recordState === 'paused' &&
                          <div className="absolute w-full h-full flex items-center justify-center top-0 bottom-0 left-0 right-0">
                            <span className="text-blue-normal leading-13 text-sm">Paused</span>
                          </div>
                        }
                      </div>
                    </>
                  }
                  {
                    (recordState === 'recording' || recordState === 'paused') &&
                    <>
                      <button
                        title="Stop Recording"
                        onClick={() => {
                          doneRecording()
                          // Stop media recording
                          stopRecording()
                        }}
                        className="leading-13 font-bold text-sm text-blue-normal">
                        <span className="inline-block align-middle" style={styles.controlIcon}>
                          <IconStop />
                        </span>
                      </button>
                      {
                        recordState !== 'paused' &&
                        <button
                          title="Pause Recording"
                          onClick={() => {
                            //Pause the recording
                            pauseRecording()
                            setRecordState('paused')
                          }}
                          className="ml-3 leading-13 font-bold text-sm text-blue-normal">
                          <span className="inline-block align-middle" style={styles.controlIcon}>
                            <IconPause />
                          </span>
                        </button>
                      }
                      {
                        recordState === 'paused' &&
                        <button
                          title="Resume Recording"
                          onClick={() => {
                            //Resume the recording
                            resumeRecording()
                            setRecordState('recording')
                          }}
                          className="ml-3 leading-13 font-bold text-sm text-blue-normal">
                          <span className="inline-block align-middle" style={styles.controlIcon}>
                            <IconRecord />
                          </span>
                        </button>
                      }
                    </>
                  }
                  {
                    recordState === 'done' &&
                    <button onClick={toggleRecording} className="leading-13 font-bold text-sm text-blue-normal">
                      <span className="inline-block align-middle" style={styles.controlIcon}>
                        {isPlaying ? <IconStop /> : <IconPlay />}
                      </span>
                      <span className="ml-2">{isPlaying ? 'Stop' : 'Play'}</span>
                    </button>
                  }
                </div>
                {
                  recordState === 'done' &&
                  <div className="w-full mt-2 flex items-center justify-between">
                    <button onClick={() => {
                      clearBlobUrl()
                      recordAgain()
                    }} className="leading-22 font-medium text-sm">
                      <span className="inline-block align-middle" style={styles.controlIcon}>
                        <IconReload />
                      </span>
                      <span className="ml-2 text-gray-darkest">Record Again</span>
                    </button>
                    <button disabled={!recordingData} onClick={() => {
                      clearBlobUrl()
                      deleteRecording()
                    }} className="leading-22 font-medium text-sm">
                      <span className="inline-block align-middle" style={styles.controlIcon}>
                        <IconBin />
                      </span>
                      <span className="ml-2 text-gray-darkest">Delete Recording</span>
                    </button>
                  </div>
                }
              </div>
            )
          }}
        />
      }
    </div>
  )
}

const ICON_WIDTH = 36

const styles = {
  controlIcon: {
    width: '17px',
    height: '17px',
  },
  frequencyBlock: {
    height: '32px',
    borderRadius: '20px',
    opacity: '0.1',
  },
  microphoneIconWrapper: {
    top: '7px',
    left: '2px',
  },
  microphoneCircle: {
    minWidth: `${ICON_WIDTH}px`,
    minHeight: `${ICON_WIDTH}px`,
    maxWidth: '83px',
    maxHeight: '83px',
    backgroundColor: 'rgba(66, 144, 243, 0.1)',
  },
  encouragementBlock: {
    backgroundColor: '#ECF4FE',
    height: '80px',
  },
  // Microphone icon SVG viewBox is larger than design
  // dimensions. Little CSS tweak to make it match the
  // style
  microphoneIcon: {
    top: '50%',
    left: '50%',
    width: `${ICON_WIDTH * 2.5}px`,
    height: `${ICON_WIDTH * 2.5}px`,
    transform: 'translateX(-50%) translateY(-50%)'
  },
  rightWrapper: {
    width: `${ICON_WIDTH}px`,
    height: `${ICON_WIDTH}px`,
  },
}

const mapStateToProps = state => {
  const {
    user: {
      userId,
      edlyftCohort,
    }
  } = state

  return {
    userId,
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(PracticeProblemRecord)