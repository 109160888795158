import React from 'react'
import withPageWrapper from '../../../../hoc/AuthPageWrapper'

import { connect } from 'react-redux'
import { samBackend } from '../../../../axios-orders'
import moment from 'moment'
import StudentQuoteBox from './StudentQuoteBox'

const styles = {
  section: {
    background: 'rgba(64, 129, 235, 0.05)',
    border: '1px solid rgba(64, 129, 235, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  sectionTitle: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '-0.03em',
    color: '#4290F3',
  },
  pageTitle: {
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '-0.03em',
    color: '#2C2543',
  },
  feedbackBox: {
    background: '#FFFFFF',
    border: '1px solid rgba(66, 144, 243, 0.48)',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  showMoreBtn: {
    width: '395px',
    height: '35px',
    background: 'rgba(66, 144, 243, 0.1)',
    border: '1.2px solid #4290F3',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4290F3',
  },
}

const loadingView = () => (
  <div>
    <h2>Loading...</h2>
  </div>
)

const noFeedbackView = () => (
  <div>
    <h1 className='mt-5' style={styles.pageTitle}>
      <span style={{ opacity: '0.3' }}>Dashboard</span> / Student Session Quotes
    </h1>
    <section style={styles.section} className='p-5 mt-10'>
      <h2>There are no feedbacks at the moment</h2>
    </section>
  </div>
)

const sessionDateFormat = dateString => {
  return moment(dateString).format('MMMM Do, YYYY')
}

const feedbacksOfSessionView = session => (
  <div>
    <h2 className={'mb-5 font-bold'} style={{ fontSize: '16px' }}>
      Session on {sessionDateFormat(session.session_date)}
    </h2>
    <div className={'mb-5'}>
      {session.quotes.map((quote, key) => (
        <div style={styles.feedbackBox} className='p-3 mb-2' key={key}>
          <StudentQuoteBox text={quote.quote_text} />
        </div>
      ))}
    </div>
  </div>
)

const studentFeedbacksView = feedbacks => (
  <div>
    <h1 className='mt-5' style={styles.pageTitle}>
      <span style={{ opacity: '0.3' }}>Dashboard</span> / Student Session Quotes
    </h1>
    <section style={styles.section} className='p-5 mt-10 mb-10'>
      <h2 style={styles.sectionTitle} className={'mb-5'}>
        Student Quotes
      </h2>

      {Object.keys(feedbacks).map(session_id => (
        <div key={session_id} className={'mb-5'}>
          {feedbacksOfSessionView(feedbacks[session_id])}
        </div>
      ))}
    </section>
  </div>
)

const StudentsFeedbacks = props => {
  // react hooks
  const { edlyftCohort } = props
  const [sessionsFeedbacks, setSessionsFeedbacks] = React.useState(null)

  React.useEffect(() => {
    samBackend
      .get('/mentor-feedbacks', {
        params: {
          course_code: edlyftCohort,
        },
      })
      .then(response => {
        const sessions = response.data.sessions
        setSessionsFeedbacks(sessions)
      })
      .catch(error => {
        setSessionsFeedbacks({})
        console.error(error)
      })
  }, [edlyftCohort])

  if (!sessionsFeedbacks) {
    return loadingView()
  }

  if (Object.keys(sessionsFeedbacks).length < 1) {
    return noFeedbackView()
  }

  return studentFeedbacksView(sessionsFeedbacks)
}

const mapDispatchToProps = dispatch => {
  return {}
}

const mapStateToProps = state => {
  const {
    user: { edlyftCohort },
  } = state
  return {
    edlyftCohort: edlyftCohort,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPageWrapper(StudentsFeedbacks, 'dashboard', '/', 'Dashboard'))
