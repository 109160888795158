import figure from '../assets/emptyState.svg'

const EmptyState = () => (
  <div style={styles.container}>
    <img src={figure} alt={"Empty"} style={styles.figure}></img>
    <h1>No problems here yet</h1>
    <h4>Be sure to check back soon for updates</h4>
  </div>
)

const styles = {
  container: {
    background: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "4em"
  },
  figure: {
    maxWidth: "150px",
    paddingTop: "2em",
    paddingBottom: "2em"
  }
}

export default EmptyState;