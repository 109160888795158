import { omit } from 'lodash';
import React, { forwardRef } from 'react'
import './styles.css'

const RadioPillInput = ({ text, value, curr, name, onChange, className, required, ...props }) => {
  const val = value ?? text;

  const omittedProps = omit(props, ['className', 'type', 'checked']);

  return (
    <label className={`radio-pill-input bg-gray-lightest hover:bg-blue-lighter font-normal rounded-full flex items-center gap-2 p-1 pl-2 ${className || ''}`}>
      <span className='flex-grow'>{text}</span>
      <input
        required={required}
        value={val}
        onChange={onChange}
        name={name}
        className='radio-pill-input__input'
        type='radio'
        checked={curr === val}
        {...omittedProps}
      />
    </label>
  )
};

export const UnstyledRadioPillInput = forwardRef(({ text, value, name, checked, onChange, onBlur, className, required, toggleable, ...props }, ref) => {
  const val = value ?? text;

  const omittedProps = omit(props, ['className', 'type', 'checked']);

  return (
    <label className={`radio-pill-input inline-flex items-center gap-2 ${className || ''}`}>
      <span className='flex-grow'>{text}</span>
      <input
        ref={ref}
        required={required}
        value={val}
        onChange={onChange}
        name={name}
        className='radio-pill-input__input'
        type={toggleable ? 'checkbox' : 'radio'}
        checked={checked}
        {...omittedProps}
      />
    </label>
  )
});

export default RadioPillInput;