import React from 'react'
import {ReactComponent as RoundCloseIcon} from "../../../assets/icons/x-round.svg";
import {ReactComponent as FlagIcon} from "../../../assets/icons/flag.svg";
import {Modal} from 'react-responsive-modal'

const styles = {
    modal: {
        overlay: {
            zIndex: '2000'
        },
        modal: {
            padding: 0,
            maxWidth: '526px',
            borderRadius: '20px',
        },
        closeButton: {
            right: '40px',
            top: '24px',
        }
    },
    okButton: {
        width: '160px',
        height: '40px',
        backgroundColor: '#4290F3',
        borderRadius: '4px',
        color: 'white',
        display: 'block',
        margin: '0px auto'
    },
    timestampsBG: {

    },
    modalContainer: {
        marginTop: '50px',
        padding: '20px',
    },
    modalTitle: {
        fontWeight: '500',
        fontSize: '26px',
        lineHeight: '130%',
        textAlign: 'center',
        letterSpacing: '-0.03em',
        color: '#2C2543'
    },
    modalDescription: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#2C2543',
        margin: '20px 50px',
    }
}

const SuccessModal = ({ onClose, open, mentorName }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            blockScroll={false}
            animationDuration={100}
            center
            styles={styles.modal}
            classNames={{
                modal: 'w-full',
                closeButton: 'outline-none'
            }}
            closeIcon={(
                <span style={{ width: '34px', height: '34px' }}>
                  <RoundCloseIcon />
                </span>
            )}
        >
            <div style={styles.modalContainer}>
                <div className={"flex flex-row justify-center align-center m-10"}>
                    <FlagIcon />
                </div>
                <h2 style={styles.modalTitle}>All Done! <br /> We’ve notified your mentor</h2>
                <p style={styles.modalDescription}>
                    {mentorName} has been notified that you signed up for time. You should receive a calendar invite for your appointment within the week.
                </p>
                <button style={styles.okButton} onClick={onClose}>Got it</button>
            </div>
        </Modal>
    )
}

export default SuccessModal
