import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import {
  PictureTwoTone,
  VideoCameraTwoTone,
  LoadingOutlined,
} from '@ant-design/icons'
import { Tabs, Spin, Divider } from 'antd'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ReactComponent as RoundCloseIcon } from '../../../assets/icons/close-icon.svg'
import RadioPillInput, { UnstyledRadioPillInput } from '../../RadioPillInput'
import TagInput from './TagInput'
import DroppableFileInput from '../../DroppableFileInput'
import { express } from '../../../axios-orders'
import s3Adapter from '../../../utils/s3/antdUploadAdapter'

import 'react-responsive-modal/styles.css'
import './index.css'
import { getFilename, getUrlPath } from '../../../utils/url'
import { starPracticeProblem } from '../../../utils/api/starPracticeProblem'
import { amplitudeInstance } from '../../../amplitude'
import { pick } from 'lodash'
import {
  addPracticeProblem,
  updatePracticeProblem,
} from '../../../redux/actions/problems'
import StarProblem from './StarProblem'
import { useEdlyftCohorts } from '../../../hooks/useEdlyftCohorts'
import { isCohortPracticeProblemStarred } from '../../../utils/isCohortPracticeProblemStarred'
import { useUpdateCohortStarredPracticeProblems } from '../../../hooks/useUpdateCohortStarredPracticeProblems'

const s3ObjSchema = () =>
  yup
    .object()
    .shape({
      url: yup.string().required(),
      key: yup.string().required(),
    })
    .nullable()
const schema = yup
  .object()
  .shape({
    title: yup.string().required('Please enter a problem title'),
    edlyftCohort: yup.string().required(),
    questionDescription: yup.string(),
    questionImage: s3ObjSchema(),
    difficulty: yup.string().required('Please select a difficulty level'),
    concepts: yup
      .array()
      .required()
      .min(1, 'Please select tags for this problem'),
    hints: yup.string(),
    solutionDescription: yup.string(),
    solutionImage: s3ObjSchema(),
    solutionVideo: s3ObjSchema(),
    onExams: yup
      .array()
      .required()
      .min(1, 'Please select at least 1 relevant exam')
      .max(3, 'Please select at most 3 relevant exams'),
  })
  .test(function (values) {
    const isValid = ['questionImage', 'questionDescription'].some(
      field => values[field],
    )
    if (isValid) return true
    return this.createError({
      path: 'questionContent',
      type: 'some',
      message:
        'Missing problem description or image. Please enter description or upload image',
    })
  })
  .test(function (values) {
    const isValid = ['solutionImage', 'solutionDescription'].some(
      field => values[field],
    )
    if (isValid) return true
    return this.createError({
      path: 'solutionContent',
      type: 'some',
      message:
        'Missing solution description or image. Please enter description or upload image',
    })
  })
  .required()

const { TabPane } = Tabs

const getSignedImageRequest = async (name, contentType) => {
  const response = await express.get('/practice-problem/s3/signed-request', {
    params: {
      contentType,
    },
  })
  if (response.status !== 200) {
    console.error(`Invalid file ${name}`)
    console.error(response.data)
    return
  }

  return response.data
}

const toPracticeProblemAPIObject = problem => {
  const {
    questionImageUrl,
    solutionImageUrl,
    solutionVideoUrl,
    id,
    ...restProblemProps
  } = problem

  return {
    ...restProblemProps,
    questionImage: questionImageUrl
      ? {
          url: questionImageUrl,
          key: getUrlPath(questionImageUrl),
        }
      : null,
    solutionImage: solutionImageUrl
      ? {
          url: solutionImageUrl,
          key: getUrlPath(solutionImageUrl),
        }
      : null,
    solutionVideo: solutionVideoUrl
      ? {
          url: solutionVideoUrl,
          key: getUrlPath(solutionVideoUrl),
        }
      : null,
  }
}

const PutPracticeProblemModal = ({
  open,
  closeModal,
  edlyftCohort,
  existingProblem,
  addPracticeProblem,
  updatePracticeProblem,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const defaultFormValues = existingProblem
    ? toPracticeProblemAPIObject(existingProblem)
    : undefined
  const {
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
  })
  const updateStarredPracticeProblemsInCache =
    useUpdateCohortStarredPracticeProblems()

  const [tab, setTab] = useState('problem')

  const initialFileValues = useRef({})
  const [questionFile, setQuestionFile] = useState()
  const [solutionImageFile, setSolutionImageFile] = useState()
  const [solutionVideoFile, setSolutionVideoFile] = useState()

  const [uploadStatus, setUploadStatus] = useState({
    questionImage: false,
    solutionImage: false,
    solutionVideo: false,
  })
  const uploading = !Object.values(uploadStatus).every(loading => !loading)

  const [cohort] = useEdlyftCohorts([edlyftCohort])

  const isProblemStarred = useMemo(
    () =>
      !!existingProblem &&
      isCohortPracticeProblemStarred(cohort, existingProblem.id),
    [cohort, existingProblem],
  )

  const starProblem = async ({ edlyftCohort, practiceProblemId }) => {
    const { starredPracticeProblems } = await starPracticeProblem({
      edlyftCohort,
      practiceProblemId,
    })

    updateStarredPracticeProblemsInCache(edlyftCohort, starredPracticeProblems)
  }

  const triggerProblemValidation = async () => {
    if (
      await trigger([
        'title',
        'questionDescription',
        'hints',
        'questionImage',
        'concepts',
        'difficulty',
        'questionContent',
      ])
    ) {
      setTab('solution')
    }
  }

  const onChangeTab = async key => {
    if (key === 'problem') {
      setTab('problem')
    } else {
      await triggerProblemValidation()
    }
  }

  const goToSolutionTab = async e => {
    e.preventDefault()
    await triggerProblemValidation()
  }

  const resetFileObjects = useCallback(() => {
    const { questionFile, solutionImageFile, solutionVideoFile } =
      initialFileValues.current
    setQuestionFile(questionFile)
    setSolutionImageFile(solutionImageFile)
    setSolutionVideoFile(solutionVideoFile)
  }, [])
  const clearForm = useCallback(
    values => {
      setTab('problem')
      reset(values)
      resetFileObjects()
    },
    [reset, resetFileObjects],
  )

  const postPracticeProblem = useCallback(
    async ({ isStarred, ...problem }) => {
      amplitudeInstance.logEvent('Clicked Save Practice Problem', {
        Cohort: edlyftCohort,
      })

      try {
        const response = await express.post('/practice-problem', problem)
        if (response.status !== 201) {
          const errorMessage = JSON.stringify(response.data, null, 2)
          console.error(errorMessage)
          onError(errorMessage)
          return
        }
        isStarred &&
          (await starProblem({
            edlyftCohort,
            practiceProblemId: response.data.id,
          }))
        clearForm()
        addPracticeProblem(response.data)
        onSuccess(response.data)
      } catch (e) {
        console.error(e)
        onError(e.message)
      }
    },
    [onSuccess, onError, edlyftCohort, addPracticeProblem, clearForm],
  )

  const onSuccessHandler = data => {
    onSuccess(data)

    const newDefaultValues = toPracticeProblemAPIObject(data)

    clearForm(newDefaultValues)
  }

  const putPracticeProblem = useCallback(
    async ({ isStarred, ...problem }) => {
      const changedFields = Object.entries(dirtyFields)
        .filter(([_, value]) => value)
        .map(([key, _]) => key)
      const putBody = pick(problem, changedFields)

      try {
        isStarred !== isProblemStarred &&
          (await starProblem({
            edlyftCohort,
            practiceProblemId: existingProblem.id,
          }))

        if (!Object.keys(putBody).length) {
          onSuccessHandler(existingProblem)

          return
        }

        const response = await express.put(
          `/practice-problem/${existingProblem.id}`,
          putBody,
        )
        if (response.status !== 200) {
          const errorMessage = JSON.stringify(response.data, null, 2)
          console.error(errorMessage)
          onError(errorMessage)
          return
        }
        updatePracticeProblem(existingProblem.id, response.data)

        onSuccessHandler(response.data)
      } catch (e) {
        console.error(e)
        onError(e.message)
      }
    },
    [
      onSuccess,
      onError,
      dirtyFields,
      updatePracticeProblem,
      clearForm,
      existingProblem,
    ],
  )
  const onSubmit = handleSubmit(
    existingProblem ? putPracticeProblem : postPracticeProblem,
  )

  useEffect(() => {
    const fetchFiles = async () => {
      const { questionImageUrl, solutionImageUrl, solutionVideoUrl } =
        existingProblem
      // On MS edge, the http cache and CORS don't work well together, don't rely on cache first
      const fetchOptions = {
        method: 'GET',
        cache: 'no-cache',
      }

      if (questionImageUrl) {
        const questionFileResponse = await fetch(questionImageUrl, fetchOptions)
        const questionFileObj = {
          name: getFilename(questionImageUrl),
          size: (await questionFileResponse.blob()).size,
        }
        initialFileValues.current.questionFile = questionFileObj
        setQuestionFile(questionFileObj)
      }
      if (solutionImageUrl) {
        const solutionImageResponse = await fetch(
          solutionImageUrl,
          fetchOptions,
        )
        const solutionImageFileObj = {
          name: getFilename(solutionImageUrl),
          size: (await solutionImageResponse.blob()).size,
        }
        initialFileValues.current.solutionImageFile = solutionImageFileObj
        setSolutionImageFile(solutionImageFileObj)
      }
      if (solutionVideoUrl) {
        const solutionVideoResponse = await fetch(
          solutionVideoUrl,
          fetchOptions,
        )
        const solutionVideoFileObj = {
          name: getFilename(solutionVideoUrl),
          size: (await solutionVideoResponse.blob()).size,
        }
        initialFileValues.current.solutionVideoFile = solutionVideoFileObj
        setSolutionVideoFile(solutionVideoFileObj)
      }
    }

    if (existingProblem) {
      fetchFiles()
    }
  }, [existingProblem])

  const controlledTagInput = (
    <>
      <Controller
        control={control}
        name='concepts'
        defaultValue={[]}
        render={({ onChange, value: concepts }, { invalid }) => {
          const updateTags = tag => {
            if (!concepts.includes(tag)) {
              onChange([...concepts, tag])
            }
          }
          const deleteTag = tagToDelete => {
            onChange(concepts.filter(tag => tag !== tagToDelete))
          }

          return (
            <div
              className={`border rounded-lg ${
                errors.concepts ? 'border-red-normal' : 'border-gray-focus'
              }`}
            >
              <TagInput
                invalid={invalid}
                tags={concepts}
                onSelect={updateTags}
                onDelete={deleteTag}
              />
            </div>
          )
        }}
      />
      {errors.concepts && (
        <p className='text-red-normal font-normal'>{errors.concepts.message}</p>
      )}
    </>
  )

  const controlledDifficultyInputs = (
    <>
      <div className='flex justify-start flex-wrap gap-3'>
        <Controller
          control={control}
          name='difficulty'
          defaultValue=''
          render={({ value, name, onBlur, onChange }) => {
            const sharedProps = {
              required: true,
              curr: value,
              name,
              onBlur,
              onChange,
            }
            return (
              <>
                <RadioPillInput
                  {...sharedProps}
                  className='bg-green-lightest font-medium text-gray-70'
                  text='Easy'
                  value='easy'
                />
                <RadioPillInput
                  {...sharedProps}
                  className='bg-yellow-lightest font-medium text-gray-70'
                  text='Medium'
                  value='medium'
                />
                <RadioPillInput
                  {...sharedProps}
                  className='bg-red-lightest font-medium text-gray-70'
                  text='Hard'
                  value='hard'
                />
              </>
            )
          }}
        />
      </div>
      {errors.difficulty && (
        <p className='text-red-normal font-normal'>
          {errors.difficulty.message}
        </p>
      )}
    </>
  )

  const spinner = (
    <Spin style={{ color: 'inherit' }} indicator={<LoadingOutlined spin />} />
  )

  return (
    <Modal
      visible={open}
      onCancel={closeModal}
      onOk={closeModal}
      footer={null}
      wrapClassName='npp-modal'
      className='font-sans'
      title={
        <div className='pt-1 text-violet-dark text-3xl font-medium'>
          {existingProblem ? 'Edit problem' : 'Add Problem'}
        </div>
      }
      closeIcon={<RoundCloseIcon className='inline' />}
    >
      <form onSubmit={onSubmit}>
        <input
          type='hidden'
          name='edlyftCohort'
          value={edlyftCohort}
          ref={register()}
        />
        <Tabs
          style={{
            overflow: 'visible',
          }}
          activeKey={tab}
          onChange={onChangeTab}
        >
          <TabPane tab='Problem' key='problem'>
            <div className='my-6 r'>
              <h3 className='text-violet-dark font-medium'>
                <label htmlFor='problemTitle'>Problem Title</label>
              </h3>
              <input
                className={`w-full text-base text-justify resize-none ${
                  errors.title
                    ? 'border-red-normal'
                    : 'hover:border-gray-suit border-gray-lightest'
                } bg-gray-lightest shadow-sm hover:ring-gray-suit outline-none border border-1 p-2 leading-5 font-light rounded`}
                id='problemTitle'
                name='title'
                placeholder='Enter problem title'
                ref={register()}
              />
              {errors.title && (
                <p className='text-red-normal font-normal'>
                  {errors.title.message}
                </p>
              )}
            </div>

            <Divider />
            <h2 className='text-violet-dark font-medium'>Problem Details</h2>
            <p className='text-gray-70 mt-2'>
              Enter either a problem description or upload an image. You can
              enter both if available
            </p>

            <div className='my-6'>
              <h3 className='text-violet-dark font-medium'>
                <label htmlFor='problemDescription'>Problem Description</label>
              </h3>
              <textarea
                className={`w-full text-base text-justify resize-none ${
                  errors.questionDescription || errors.questionContent
                    ? 'border-red-normal'
                    : 'hover:border-gray-suit border-gray-lightest'
                } bg-gray-lightest shadow-sm hover:ring-gray-suit outline-none border border-1 p-2 leading-5 font-light rounded`}
                id='problemDescription'
                name='questionDescription'
                placeholder='Enter a description of the problem'
                ref={register()}
              ></textarea>
              {errors.questionDescription && (
                <p className='text-red-normal font-normal'>
                  {errors.questionDescription.message}
                </p>
              )}
            </div>

            <div className='my-6'>
              <label htmlFor='problemQuestionImage'>
                <h3 className='text-violet-dark font-medium'>
                  Upload Problem Image
                </h3>
                <p className='text-gray-normal text-sm'>
                  Please ensure the entire problem shows on 1 image without the
                  solution
                </p>
              </label>
              <Controller
                control={control}
                name='questionImage'
                defaultValue={null}
                render={({ name, onBlur, onChange }) => {
                  const onUpload = file => setQuestionFile(file)
                  const onDelete = () => {
                    setQuestionFile(null)
                    onChange(null)
                  }

                  return (
                    <div onBlur={onBlur}>
                      <DroppableFileInput
                        accept='image/png,image/jpeg'
                        id='problemQuestionImage'
                        style={{
                          borderColor:
                            errors.questionImage || errors.questionContent
                              ? 'var(--color-red)'
                              : 'var(--color-gray-focus)',
                        }}
                        file={questionFile}
                        onUpload={onUpload}
                        onDelete={onDelete}
                        onLoading={loading =>
                          setUploadStatus(prev => ({
                            ...prev,
                            [name]: loading,
                          }))
                        }
                        action={async file => {
                          const { signedRequest, key, url } =
                            await getSignedImageRequest(file.name, file.type)
                          onChange({ key, url })
                          return signedRequest
                        }}
                        headers={{
                          'x-amz-acl': 'public-read',
                        }}
                        customRequest={s3Adapter}
                      >
                        <div className='mx-5 gap-4 flex flex-row justify-center'>
                          <PictureTwoTone
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              justifyItems: 'stretch',
                            }}
                          />
                          <span className='ant-upload-text text-gray-70 flex-grow text-left'>
                            Select the image for this problem
                          </span>
                          <span
                            className='ant-upload-text text-violet-lightest font-medium uppercase'
                            style={{ letterSpacing: 1 }}
                          >
                            .JPG .JPEG .PNG
                          </span>
                        </div>
                      </DroppableFileInput>
                    </div>
                  )
                }}
              />
              {errors.questionImage && (
                <p className='text-red-normal font-normal'>
                  {errors.questionImage.message}
                </p>
              )}
              <input type='hidden' name='questionContent' ref={register()} />
              {errors.questionContent && (
                <p className='text-red-normal font-normal'>
                  {errors.questionContent.message}
                </p>
              )}
            </div>

            <Divider />

            <div className='my-6'>
              <h3 className='text-violet-dark font-medium'>
                <label htmlFor='problemConceptTagsInput'>Concept Tags</label>
              </h3>
              {controlledTagInput}
            </div>

            <div className='my-6'>
              <h3 className='text-violet-dark font-medium'>Difficulty Level</h3>
              {controlledDifficultyInputs}
            </div>

            <div className='my-6'>
              <h3 className='text-violet-dark font-medium'>
                <label htmlFor='hints'>Hints</label>
              </h3>
              <textarea
                className={`w-full text-base text-justify resize-none ${
                  errors.hints
                    ? 'border-red-normal'
                    : 'hover:border-gray-suit border-gray-lightest'
                } bg-gray-lightest shadow-sm hover:ring-gray-suit outline-none border border-1 p-2 leading-5 font-light rounded`}
                id='hints'
                name='hints'
                placeholder='Enter some hints for the problem'
                ref={register()}
              ></textarea>
              {errors.hints && (
                <p className='text-red-normal font-normal'>
                  {errors.hints.message}
                </p>
              )}
            </div>
          </TabPane>
          <TabPane tab='Solution' key='solution'>
            <Divider />
            <h2 className='text-violet-dark font-medium'>Solution Details</h2>
            <p className='text-gray-70 mt-2'>
              Enter either a solution description or upload an image. You can
              enter both if available
            </p>
            <div className='my-6'>
              <h3>
                <label htmlFor='solutionDescription'>
                  Solution Description
                </label>
              </h3>
              <textarea
                className={`w-full text-base text-justify resize-none ${
                  errors.solutionDescription || errors.solutionContent
                    ? 'border-red-normal'
                    : 'hover:border-gray-suit border-gray-lightest'
                } bg-gray-lightest shadow-sm hover:ring-gray-suit outline-none border border-1 p-2 leading-5 font-light rounded`}
                id='solutionDescription'
                name='solutionDescription'
                placeholder='Enter a brief narration of the solution here'
                ref={register()}
              ></textarea>
              {errors.solutionDescription && (
                <p className='text-red-normal font-normal'>
                  {errors.solutionDescription.message}
                </p>
              )}
            </div>

            <div className='my-6'>
              <label htmlFor='problemSolutionImage'>
                <span>Upload Solution Image</span>
                <p className='text-gray-normal text-sm'>
                  Please ensure the entire solution shows on 1 image
                </p>
              </label>
              <Controller
                control={control}
                name='solutionImage'
                defaultValue={null}
                render={({ name, onBlur, onChange }) => {
                  const onUpload = file => setSolutionImageFile(file)
                  const onDelete = () => {
                    setSolutionImageFile(null)
                    onChange(null)
                  }

                  return (
                    <div onBlur={onBlur}>
                      <DroppableFileInput
                        accept='image/png,image/jpeg'
                        id='problemSolutionImage'
                        style={{
                          borderColor:
                            errors.solutionImage || errors.solutionContent
                              ? 'var(--color-red)'
                              : 'var(--color-gray-focus)',
                        }}
                        file={solutionImageFile}
                        onUpload={onUpload}
                        onDelete={onDelete}
                        onLoading={loading =>
                          setUploadStatus(prev => ({
                            ...prev,
                            [name]: loading,
                          }))
                        }
                        action={async file => {
                          const { signedRequest, key, url } =
                            await getSignedImageRequest(file.name, file.type)
                          onChange({ key, url })
                          return signedRequest
                        }}
                        headers={{
                          'x-amz-acl': 'public-read',
                        }}
                        customRequest={s3Adapter}
                      >
                        <div className='mx-5 gap-4 flex flex-row justify-center'>
                          <PictureTwoTone
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              justifyItems: 'stretch',
                            }}
                          />
                          <span className='ant-upload-text text-gray-70 flex-grow text-left'>
                            Select the image for this problem
                          </span>
                          <span
                            className='ant-upload-text text-violet-lightest font-medium uppercase'
                            style={{ letterSpacing: 1 }}
                          >
                            .JPG .JPEG .PNG
                          </span>
                        </div>
                      </DroppableFileInput>
                    </div>
                  )
                }}
              />
              {errors.solutionImage && (
                <p className='text-red-normal font-normal'>
                  {errors.solutionImage.message}
                </p>
              )}
              <input type='hidden' name='solutionContent' ref={register()} />
              {errors.solutionContent && (
                <p className='text-red-normal font-normal'>
                  {errors.solutionContent.message}
                </p>
              )}
            </div>

            <Divider />

            <div className='my-6'>
              <label htmlFor='problemSolutionVideo'>
                <span>Upload Solution Video</span>
                <p className='text-gray-normal text-sm'>
                  Less than 5min recording of yourself explaining the solution
                </p>
              </label>
              <Controller
                control={control}
                name='solutionVideo'
                defaultValue={null}
                render={({ name, onBlur, onChange }) => {
                  const onUpload = file => setSolutionVideoFile(file)
                  const onDelete = () => {
                    setSolutionVideoFile(null)
                    onChange(null)
                  }

                  return (
                    <div onBlur={onBlur}>
                      <DroppableFileInput
                        accept='video/mp4,video/quicktime'
                        id='problemSolutionVideo'
                        style={{
                          borderColor: errors.solutionVideo
                            ? 'var(--color-red)'
                            : 'var(--color-gray-focus)',
                        }}
                        file={solutionVideoFile}
                        fileIcon={<VideoCameraTwoTone />}
                        onUpload={onUpload}
                        onDelete={onDelete}
                        onLoading={loading =>
                          setUploadStatus(prev => ({
                            ...prev,
                            [name]: loading,
                          }))
                        }
                        action={async file => {
                          const { signedRequest, key, url } =
                            await getSignedImageRequest(file.name, file.type)
                          onChange({ key, url })
                          return signedRequest
                        }}
                        headers={{
                          'x-amz-acl': 'public-read',
                        }}
                        customRequest={s3Adapter}
                        validateFile={file => {
                          const above100MB = file.size > 100_000_000
                          if (above100MB) {
                            return 'Video must be less than 100MB'
                          }
                          return true
                        }}
                      >
                        <div className='mx-5 gap-4 flex flex-row justify-center'>
                          <VideoCameraTwoTone
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              justifyItems: 'stretch',
                            }}
                          />
                          <span className='ant-upload-text text-gray-70 flex-grow text-left'>
                            Select the video for this problem
                          </span>
                          <span
                            className='ant-upload-text text-violet-lightest font-medium uppercase'
                            style={{ letterSpacing: 1 }}
                          >
                            .MOV .MP4 | 100mb or less
                          </span>
                        </div>
                      </DroppableFileInput>
                    </div>
                  )
                }}
              />
              {errors.solutionVideo && (
                <p className='text-red-normal font-normal'>
                  {errors.solutionVideo.message}
                </p>
              )}
            </div>

            <div className='my-6'>
              <span>Related Exams</span>
              <p className='text-gray-normal text-sm'>
                Please select all exams that apply to this problem
              </p>
              <div>
                <Controller
                  control={control}
                  name='onExams'
                  defaultValue={[]}
                  render={({
                    onChange: onChangeController,
                    onBlur,
                    value: onExams,
                  }) => {
                    const updateTags = tag => {
                      if (!onExams.includes(tag)) {
                        onChangeController([...onExams, tag])
                      }
                    }
                    const deleteTag = tagToDelete => {
                      onChangeController(
                        onExams.filter(tag => tag !== tagToDelete),
                      )
                    }
                    const onChange = e => {
                      if (e.target.checked) {
                        updateTags(e.target.value)
                      } else {
                        deleteTag(e.target.value)
                      }
                    }
                    const sharedProps = { onChange, toggleable: true }

                    return (
                      <div className='flex gap-5' onBlur={onBlur}>
                        <UnstyledRadioPillInput
                          text='Midterm 1'
                          checked={onExams.includes('Midterm 1')}
                          className='w-1/4 border border-gray-focus p-3 rounded'
                          {...sharedProps}
                        />
                        <UnstyledRadioPillInput
                          text='Midterm 2'
                          checked={onExams.includes('Midterm 2')}
                          className='w-1/4 border border-gray-focus p-3 rounded'
                          {...sharedProps}
                        />
                        <UnstyledRadioPillInput
                          text='Midterm 3'
                          checked={onExams.includes('Midterm 3')}
                          className='w-1/4 border border-gray-focus p-3 rounded'
                          {...sharedProps}
                        />
                        <UnstyledRadioPillInput
                          text='Finals'
                          checked={onExams.includes('Finals')}
                          className='w-1/4 border border-gray-focus p-3 rounded'
                          {...sharedProps}
                        />
                      </div>
                    )
                  }}
                />
              </div>
              {errors.onExams && (
                <p className='text-red-normal font-normal'>
                  {errors.onExams.message}
                </p>
              )}
            </div>
          </TabPane>
        </Tabs>

        <div className='flex flex-row justify-between my-6'>
          <Controller
            control={control}
            name='isStarred'
            defaultValue={isProblemStarred || false}
            render={({ value, onChange }) => (
              <StarProblem
                starred={value}
                onClick={() => {
                  onChange(!value)
                }}
              />
            )}
          />
          {tab === 'problem' ? (
            <button
              disabled={uploading || isSubmitting}
              className='bg-blue-normal py-3 px-8 text-white rounded font-medium'
              onClick={goToSolutionTab}
              type='button'
            >
              {isSubmitting ? spinner : 'Next'}
            </button>
          ) : (
            <button
              disabled={uploading || isSubmitting}
              className='bg-blue-normal py-3 px-8 text-white rounded font-medium'
              type='submit'
            >
              {isSubmitting ? spinner : 'Save'}
            </button>
          )}
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = state => {
  const {
    user: { isMentor, edlyftCohort },
  } = state

  return {
    isMentor,
    edlyftCohort,
  }
}

const mapDispatchToProps = dispatch => ({
  addPracticeProblem: problem => dispatch(addPracticeProblem(problem)),
  updatePracticeProblem: (id, updatedProblem) =>
    dispatch(updatePracticeProblem(id, updatedProblem)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PutPracticeProblemModal)
