import { ADD_PRACTICE_PROBLEM, SET_PRACTICE_PROBLEMS, UPDATE_PRACTICE_PROBLEM } from '../types'

export const setPracticeProblems = data => {
  return {
    type: SET_PRACTICE_PROBLEMS,
    data,
  }
}

export const addPracticeProblem = newProblem => {
  return {
    type: ADD_PRACTICE_PROBLEM,
    newProblem,
  }
}

export const updatePracticeProblem = (id, updatedProblem) => {
  return {
    type: UPDATE_PRACTICE_PROBLEM,
    id,
    updatedProblem,
  }
}