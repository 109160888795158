import { useEffect, useState } from "react";
import { getStudentMentors } from "../utils";

export function useMentors(courses, edlyftCohort, index = 0) {
  const [mentor, setMentor] = useState({});
  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    getStudentMentors(courses)
      .then(({ mentors }) => {
        if (!mentors) return;
        const validMentors = mentors.filter(mentor => mentor.courses.includes(edlyftCohort));
        setMentors(validMentors);
      });
  }, [courses, edlyftCohort]);

  useEffect(() => {
    if (index >= mentors.length) return;
    setMentor(mentors[index]);
  }, [mentors, index]);

  return { mentor };
}