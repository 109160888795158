import { Tag } from "antd";

const TagLevel = (props) => {
  let color;
  const { level } = props
  switch (level) {
    case "Easy":
      color = "#e0f5e9"
      break;

    case "Medium":
      color = "#fff7e1"
      break;

    case "Hard":
      color = "#ffdfe0"
      break;
  
    default:
      break;
  }

  return (
    <Tag color={color} style={{color: "#000000", borderRadius: "15px"}}>{level}</Tag>
  )
}

export default TagLevel