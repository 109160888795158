import React, { useState, useEffect } from 'react'
import 'plyr/dist/plyr.css'
import Player from "./Player"
import amplitude from 'amplitude-js'

import NewPageIcon from '../Cards/NewPageIcon'
import TagLevel from '../Topics/TagLevel';

import DiscussProblemMessage from './DiscussProblemMessage'
import { amplitudeInstance } from '../../../../amplitude'

import BookTimeBottom from './BookTimeBottom'
import DiscussWithBottom from './DiscussWithBottom'
import { startConvoWithMentor } from '../../../../utils/api';
import { capitalizeFirstLetter } from '../../../../utils/capitalize'

const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    background: "#f3f8fd",
    borderRadius: "5px",
    padding: "15px 15px 0 15px",
    marginBottom: "1em",
  },
  problemContainer: {
    paddingBottom: "1em",
    display: "flex",
    justifyContent: "left",
    alignItems: "baseline",
  },
  problem: {
    display: "inline",
    paddingRight: "0.5em"
  },
  newPageIcon: {
    display: "inline",
    position: "relative",
    top: "3px",
  },
  player: {
    paddingBottom: "1em",
    background: 0,
  },
}

const PlaySolution = ({ problem, user, mentor }) => {
  const [ displayMessage, setDisplayMessage] = useState({})
  const [ isLoadingDiscussProblem, setIsLoadingDiscussProblem] = useState(false)
  const [ didClickDiscussProblem, setDidClickDiscussProblem ] = useState(false)

  const identify = new amplitude.Identify()
  amplitudeInstance.identify(identify)

  useEffect(() => {
    setTimeout(() => {
      // reset modal once animation completed
      setIsLoadingDiscussProblem(false)
      setDidClickDiscussProblem(false)
      setDisplayMessage({})
    }, 500);
  }, [problem]);

  const discussProblemClick = (mentorId, studentId, practiceProblemName) => {
    // track click
    amplitudeInstance.logEvent("Clicked Discuss Problem w/ Mentor Button", {
      practiceProblemName: practiceProblemName,
      mentorId: mentorId,
      studentId: studentId
    })

    setIsLoadingDiscussProblem(true)

    startConvoWithMentor({ mentorId, studentId, practiceProblemName })
      .then((response) => {
        const data = JSON.parse(response.data.body)
        const channel = data["channel"]

        setDidClickDiscussProblem(true)
        setIsLoadingDiscussProblem(false)
        setDisplayMessage({
          visable: true,
          successful: true,
          redirectUrl: `https://edlyft.slack.com/messages/${channel}`
        })
      })
      .catch((err) => {
        setDidClickDiscussProblem(true)
        setIsLoadingDiscussProblem(false)
        setDisplayMessage({
          visable: true,
          successful: false,
        })
      })
  }

  return (
    <>
      <DiscussProblemMessage 
        isVisable={displayMessage.visable} 
        isSuccessful={displayMessage.successful} 
        redirectUrl={displayMessage.redirectUrl}
        closeCallback={() => {setDisplayMessage({visable: false, successful: true})}}/>
      <h1 className={"font-medium text-3xl leading-28 text-violet-dark"}>Solution Video</h1>

      <div>
        <div style={style.container}>
          <h5 className={"font-light text-base text-gray-normal leading-20 mb-0"}>Problem Title</h5>
          <div style={style.problemContainer}>
            <h4 style={style.problem}>{problem?.title}</h4>
            <TagLevel level={problem ? capitalizeFirstLetter(problem.difficulty) : ''} />
            <NewPageIcon style={style.newPageIcon} openUrl={`/practice-problem/${problem.id}`}/>
          </div>
        </div>

        <div style={style.player} className='player-wrapper relative rounded-md overflow-hidden'>
          <Player problem={problem}/>
        </div>

        <>
          { didClickDiscussProblem ? (
            <BookTimeBottom 
              mentorId={mentor?.id} 
              studentId={user?.id} 
              practiceProblemName={problem?.title} 
            />
          ) : (
            <DiscussWithBottom 
              discussCallback={() => {
                discussProblemClick(mentor?.userId, user?.userId, problem?.title)
              }} 
              isLoadingDiscussProblem={isLoadingDiscussProblem}  
            />
          )}
        </>

      </div>
    </>
  )
}

export default PlaySolution;