import { Tooltip as AntdTooltip } from 'antd'

const Tooltip = ({ tooltipText, children }) => {
  return (
    <AntdTooltip
      color='white'
      overlayInnerStyle={{
        borderRadius: 10,
        padding: '0.75rem 0.875rem',
      }}
      title={<span className='text-gray-dark-text'>{tooltipText}</span>}
    >
      {children}
    </AntdTooltip>
  )
}

export default Tooltip
