import React from 'react'
import { Button } from './FormButton'
import { useForm } from 'react-hook-form'
import { Auth } from 'aws-amplify';

const Label = ({ text, htmlFor }) => (
  <label htmlFor={htmlFor} className="leading-15 text-tiny" style={{ color: '#8B8896' }}>{text}</label>
)

async function confirmSignUp(username, code) {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error) {
    console.log('error confirming sign up', error);
  }
}

const VerificationCode = (props) => {
  const { register, handleSubmit } = useForm()

  const handleFormSubmit = (data) => {
    confirmSignUp(data.verification_code, data.verification_code)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="mb-4">
        <Label text="Verification Code" htmlFor="text" />
        <input style={{ color: '#BFBFBF' }} placeholder="Enter verification code" className="font-light rounded form-input bg-gray-lightest" id="verification" type="text" name="verification_code" ref={register} />
      </div>
      <Button className="bg-blue-normal block w-full" type="submit" text="Verify Your Account" />
    </form>
  )
}

export default VerificationCode