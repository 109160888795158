import icon from '../assets/new-page-icon.svg';

const newPageStyle = {
  width: "18px",
  cursor: "pointer",
}

const NewPageIcon = (props) => {
  const {style, openUrl} = props

  return (
    <div onClick={(e) => {
      if (openUrl) window.open(openUrl, '_blank');
      e.stopPropagation()
    }} style={{ ...newPageStyle, ...style }}>
      <img src={icon} alt={"open in new page"} className={"card-hover-newpage"}/>
    </div>
  )
}

export default NewPageIcon;