import {
  ONBOARD_USER,
  SWITCH_COURSE,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_COMPLETE,
  UPDATE_PROFILE,
  UPDATE_EMAIL_PREFERENCES,
} from "../types";

export const onboardUser = (data) => {
  return {
    type: ONBOARD_USER,
    data,
  };
};

export const fetchUserInfoError = () => {
  return {
    type: FETCH_USER_INFO_ERROR,
  };
};

export const fetchedUserInfo = (user) => {
  return {
    type: FETCH_USER_INFO_COMPLETE,
    user,
  };
};

export const switchCourse = (course) => {
  return {
    type: SWITCH_COURSE,
    course,
  };
};

export const updateProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    data,
  };
};

export const updateEmailPreferences = (data) => ({
  type: UPDATE_EMAIL_PREFERENCES,
  data,
});
