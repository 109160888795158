import React from 'react'
import moment from 'moment'
import ShowMoreText from "react-show-more-text";

export default function StudentQuoteBox(props) {
    const { text, collectionDate } = props

    const textDiv = (text) => (
      <>
	<ShowMoreText>{text}</ShowMoreText>
      </>
    )

    const collectionDateDiv = () => {
      if (collectionDate) {
	return(
	  <div className="mt-5">
	    <span className="font-bold">- {moment(collectionDate).format('MMMM Do, YYYY')}</span>
	  </div>
	)
      } else {
	return(
	  <></>
	)
      }
    }

    return (
      <div className='flex flex-col'>
	<div className="font-light text-base">
	  { textDiv(text) }
	</div>

	{ collectionDateDiv() }

      </div>
    )
}
