import React, { useState } from 'react'
import { Typography, Col, Row } from 'antd'
import Modal from 'react-responsive-modal'
import { PlusOutlined } from '@ant-design/icons'
import 'react-responsive-modal/styles.css'

import figure from './assets/exam-prep-figure.svg'
import { ReactComponent as FlagIcon } from './assets/flag.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg'
import PutPracticeProblemModal from '../../Modal/PutPracticeProblemModal'

const { Title, Text } = Typography

const titleRowStyle = {
  textAlign: 'left',
  alignItems: 'stretch',
  marginBottom: '2em',
  backgroundImage: `url(${figure}), linear-gradient(rgb(240,252,252), rgb(240,252,252))`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
}

const modalStyles = {
  header: {
    borderColor: 'var(--color-gray-lighter-hard)',
    paddingTop: '40px',
  },
  modal: {
    overlay: {
      zIndex: '2000',
    },
    modal: {
      maxWidth: '520px',
      borderRadius: '20px',
      minHeight: '400px',
      maxHeight: '628px',
    },
    closeButton: {
      right: '24px',
      top: '24px',
    },
  },
}

const CreatePracticeProblemSuccessModal = ({ problemTitle, open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      center
      styles={modalStyles.modal}
      classNames={{
        modal:
          'w-full overflow-y-auto flex flex-col justify-center items-center gap-4 py-6 px-12 text-center',
        closeButton: 'outline-none',
      }}
      closeIcon={<CloseIcon />}
    >
      <FlagIcon className='text-xl mt-10' />
      <div className='font-bold text-4xl'>All Done!</div>
      <div className='flex-grow'>
        Your problem “{problemTitle}” has been added successfully.
      </div>
      <button
        className='bg-blue-normal py-3 px-8 text-white rounded font-medium mb-4'
        onClick={() => setOpen(false)}
      >
        OK
      </button>
    </Modal>
  )
}

const CreatePracticeProblemFailureModal = ({
  open,
  setOpen,
  onGoBackClick = () => {},
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      center
      styles={modalStyles.modal}
      classNames={{
        modal:
          'w-full overflow-y-auto flex flex-col justify-center items-center gap-4 py-6 px-12 text-center',
        closeButton: 'outline-none',
      }}
      closeIcon={<CloseIcon />}
    >
      <ErrorIcon className='text-xl mt-10' />
      <div className='font-bold text-4xl'>There was a problem</div>
      <div className='flex-grow'>
        There was an issue creating this problem. Please try again soon.
      </div>
      <button
        className='bg-blue-normal py-3 px-8 text-white rounded font-medium mb-4'
        onClick={onGoBackClick}
      >
        Go back
      </button>
    </Modal>
  )
}

const ExamPrepTitle = ({ user }) => {
  const isMentor = user?.isMentor ?? false;
  
  const [putPracticeProblemModalOpen, setPutPracticeProblemModalOpen] =
    useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [problemTitle, setProblemTitle] = useState()
  const [failureModalOpen, setFailureModalOpen] = useState(false)

  const openModal = () => {
    setPutPracticeProblemModalOpen(true)
    setSuccessModalOpen(false)
    setFailureModalOpen(false)
  }
  const closeModal = () => setPutPracticeProblemModalOpen(false)

  const onSuccess = problem => {
    setPutPracticeProblemModalOpen(false)
    setFailureModalOpen(false)
    setSuccessModalOpen(true)
    setProblemTitle(problem.title)
  }
  const onError = errorMessage => {
    setPutPracticeProblemModalOpen(false)
    setSuccessModalOpen(false)
    setFailureModalOpen(true)
    console.error(errorMessage)
  }

  return (
    <>
      <Row style={titleRowStyle} className='px-10 py-6'>
        <Col span={18}>
          <div style={{ maxWidth: 260 }}>
            <Title level={3}>Exam Prep</Title>
            <Text>Create and update study resources for Edlyft students</Text>
          </div>
        </Col>

        <Col
          span={6}
          className='flex flex-column justify-end items-end'
          style={{ display: 'flex' }}
        >
          {isMentor && (
            <button
              onClick={openModal}
              className='bg-blue-normal hover:bg-blue-light font-medium text-white inline-flex items-center p-3 gap-3 rounded'
            >
              <PlusOutlined className='text-lg' />
              Add Problem
            </button>
          )}
        </Col>
      </Row>
      {isMentor && (
        <>
          <PutPracticeProblemModal
            open={putPracticeProblemModalOpen}
            openModal={openModal}
            closeModal={closeModal}
            onSuccess={onSuccess}
            onError={onError}
          />
          <CreatePracticeProblemSuccessModal
            problemTitle={problemTitle}
            open={successModalOpen}
            setOpen={setSuccessModalOpen}
          />
          <CreatePracticeProblemFailureModal
            open={failureModalOpen}
            setOpen={setFailureModalOpen}
            onGoBackClick={openModal}
          />
        </>
      )}
    </>
  )
}

export default ExamPrepTitle
