const {
  SET_PRACTICE_PROBLEMS,
  ADD_PRACTICE_PROBLEM,
  UPDATE_PRACTICE_PROBLEM,
} = require('../types')

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRACTICE_PROBLEMS:
      return [...action.data]
    case ADD_PRACTICE_PROBLEM:
      const { newProblem } = action
      return [newProblem, ...state]
    case UPDATE_PRACTICE_PROBLEM:
      const { id, updatedProblem } = action
      const removedOldProblem = state.filter(problem => problem.id !== id)
      return [updatedProblem, ...removedOldProblem]
    default:
      return state
  }
}

export default reducer
