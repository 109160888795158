import React from 'react'
import { Typography, Button } from 'antd';

const { Text } = Typography;

const style = {
  container: {
    position: "fixed",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, .65)",
    paddingTop: "45px",
    transition: "all 300ms ease-in-out",
    zIndex: 100,
    justifyContent: "center",
  },
  containerVisable: {
    display: "flex",
    opacity: "1",
  },
  containerNotVisable: {
    display: "none",
    opacity: "0",
  },
  box: {
    display: "flex",
    position: "absolute",
    borderStyle: "solid",
    borderRadius: "10px",
    fontSize: "18px",
    margin: 0,
    padding: "10px",
    width: "800px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  boxFailed: {
    borderColor: "rgba(202, 120, 120, 0.3)",
    background: "#FAF1F1",
  }, 
  boxSuccessful: {
    borderColor: "rgba(120, 202, 149, 0.3)",
    background: "#F1FAF4",
  },
  button: {
    color: "#ffffff"
  },
  buttonSuccess: {
    background: "#78CA95"
  },
  buttonFailed: {
    background: "#ca7878"
  },
  text: {
    paddingLeft: "1em"
  }
}

const DiscussProblemMessage = (props) => {
  const { isVisable, isSuccessful, redirectUrl, closeCallback } = props
  let containerStyle, boxStyle, buttonStyle, boxText;

  if (isVisable) {
    containerStyle = {
      ...style.container,
      ...style.containerVisable
    }
  } else {
    containerStyle = {
      ...style.container,
      ...style.containerNotVisable
    }
  }

  if (isSuccessful) {
    boxText = "Awesome! A message has been started in Slack with your mentor. Check it out."
    boxStyle = {
      ...style.box,
      ...style.boxSuccessful
    }
    buttonStyle = {
      ...style.button,
      ...style.buttonSuccess
    }
  } else {
    boxText = "Oh! An error occured trying to reach mentor."
    boxStyle = {
      ...style.box,
      ...style.boxFailed
    }
    buttonStyle = {
      ...style.button,
      ...style.buttonFailed
    }
  }

  const clickGotIt = () => {
    if (redirectUrl) {
      window.open(redirectUrl, '_blank').focus();
    }
    closeCallback()
  }

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <Text strong style={style.text}>{boxText}</Text>
        <Button size={"large"} style={buttonStyle} onClick={clickGotIt}>
          Got it
        </Button>
      </div>
    </div>
  )
}

export default DiscussProblemMessage