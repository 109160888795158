import React from 'react'
import { Button } from '../Form/Button'
import 'plyr/dist/plyr.css'
import VideoPlayer from 'plyr-react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import defaultPoster from '../../assets/images/video-preview.jpg'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/x-round.svg'
import MathJax from 'react-mathjax-preview'

const PracticeHintModal = ({
  open,
  hint,
  hintLink,
  totalHint,
  closeModal,
  availableHint,
  setAvailableHint,
}) => {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      styles={styles.modal}
      classNames={{
        modal: 'max-w-full w-full',
      }}
      closeIcon={(
        <span>
          <CloseIcon />
        </span>
      )}
    >
      <div>
        <div className="border-b px-10" style={styles.header}>
          <h2 className="text-violet-dark leading-28 text-3xl font-medium">Hints - ( {availableHint} / {totalHint} Left)</h2>
        </div>
        <div className="px-10 py-12">
          <span className="block mb-10 leading-28 text-violet-dark font-medium text-3xl">Notes to point you in the right direction:</span>
          <div className="mb-6 text-left font-thin leadind-36 text-4xl">
            <div className="overflow-x-auto">
              <MathJax math={hint} />
            </div>
          </div>
          {
            availableHint === 0 &&
            <div className="border-t border-gray-lighter-soft pt-10 pb-12">
              <span className="block mb-3 leading-28 text-violet-dark font-medium text-3xl">Video hint from Edlyft team:</span>
              <div className="mx-auto rounded overflow-hidden mb-12">
                <VideoPlayer
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: hintLink,
                        type: 'video/mp4',
                      }
                    ],
                    poster: defaultPoster,
                  }}
                  options={{
                    speed: {
                      selected: 1,
                      options: [1, 1.5, 2]
                    },
                    ratio: '16:9',
                  }}
                />
              </div>
              <div className="flex items-center">
                <span className="rounded-full inline-block mr-3" style={styles.logoWrapper}>
                  <Logo />
                </span>
                <span className="font-thin leading-25 text-lg text-gray-dark">Edlyft Admin Member</span>
              </div>
            </div>
          }
          <div className="mt-10 flex items-center flex-col">
            <Button onClick={closeModal} style={styles.gotItButton} className="bg-blue-normal max-w-full mx-auto" text="Got It!" />
            {
              availableHint === 1 &&
              <button onClick={() => setAvailableHint(0)} className="text-center mt-10 text-blue-normal underline leading-20 font-medium text-lg">I need another hint</button>
            }
          </div>
        </div>
      </div>
    </Modal >
  )
}

const styles = {
  logoWrapper: {
    width: '36px',
  },
  gotItButton: {
    width: '330px',
    display: 'block',
  },
  header: {
    borderColor: 'var(--color-gray-lighter-hard)',
    paddingTop: '30px',
    paddingBottom: '22px',
  },
  modal: {
    overlay: {
      zIndex: '2000'
    },
    modal: {
      padding: 0,
      maxWidth: '732px',
      borderRadius: '20px',
    },
    closeButton: {
      right: '40px',
      top: '24px',
      width: '31px',
      height: '31px',
    }
  }
}

export default PracticeHintModal
