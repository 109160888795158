import { useEffect, useMemo } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { express } from '../axios-orders'
import { setPracticeProblems } from '../redux/actions/problems'

/**
 * This hook is responsible for loading and selecting practice problems.
 * It uses the react-redux hooks to integrate with the global store.
 *
 * By default the { @link https://react-redux.js.org/api/hooks#useselector useSelector } uses deep equality.
 * I set it to shallow equality to be consistent with react state equality behavior
 *
 * @summary This hook is responsible for loading and selecting practice problems
 *
 * @export
 * @param {string} edlyftCohort
 * @param {string} problemId
 * @return {{ selectedProblem: Object, problems: Array }}
 */
export function usePracticeProblems(edlyftCohort, problemId) {
  const dispatch = useDispatch()
  const problems = useSelector(state => state.problems, shallowEqual)

  useEffect(() => {
    const fetchPracticeProblems = async () => {
      const response = await express.get('/practice-problem', {
        params: {
          edlyftCohort,
        },
      })
      dispatch(setPracticeProblems(response.data))
    }

    if (edlyftCohort && edlyftCohort !== '') {
      fetchPracticeProblems()
    }
  }, [edlyftCohort])

  const selectedProblem = problems.find(problem => problem.id === problemId)

  return { selectedProblem, problems }
}
