import React from 'react'
import SidebarItem from './SidebarItem'
import { ReactComponent as IconHat } from '../../assets/icons/graduation-hat.svg'
import { ReactComponent as IconPlay } from '../../assets/icons/play-box.svg'
import { ReactComponent as IconStudy } from '../../assets/icons/exam-prep.svg'
import { ReactComponent as IconCalendar } from '../../assets/icons/calendar.svg'
import { ReactComponent as IconContactMentor } from '../../assets/icons/contact-mentor.svg'
import { amplitudeInstance } from '../../amplitude'
import { Page } from '../../contexts/page'
import { connect } from 'react-redux'

const techxEmailDomain = /@techexchange\.in$/i
const adsCourseNumber = /^ADS/i

const SidebarContent = ({
  email,
  isMentor,
  className,
  edlyftCohort,
  userCourseList,
}) => {
  const isTechXUser =
    email.search(techxEmailDomain) > -1 ||
    userCourseList.some(
      course => course?.courseNumber?.search(adsCourseNumber) > -1,
    )
  return (
    <Page.Consumer>
      {({ currentPageName }) => (
        <div className={`app-sidebar__content ${className ?? ''}`}>
          <SidebarItem
            isActive={currentPageName === 'dashboard'}
            route='/dashboard'
            title='Dashboard'
            icon={<IconHat />}
          />
          {!isMentor && (
            <SidebarItem
              isActive={currentPageName === 'contact-mentor'}
              route='/contact-mentor'
              title='Contact Mentor'
              icon={<IconContactMentor />}
            />
          )}
          <SidebarItem
            isActive={currentPageName === 'recordings'}
            route='/recordings'
            title='Recordings'
            icon={<IconPlay />}
            onClick={() => {
              amplitudeInstance.logEvent('Clicks Recordings Tab')
            }}
          />
          <SidebarItem
            isActive={currentPageName === 'exam-prep'}
            route='/exam-prep'
            title='Exam Prep'
            icon={<IconStudy />}
            onClick={() => {
              amplitudeInstance.logEvent('Clicks Study Tab', {
                cohort: edlyftCohort,
              })
            }}
            newItem={true}
          />
          {!isMentor && isTechXUser && (
            <SidebarItem
              isActive={currentPageName === 'techx-sessions'}
              route='/techx-sessions'
              title='All sessions'
              icon={<IconCalendar />}
            />
          )}
        </div>
      )}
    </Page.Consumer>
  )
}

const mapStateToProps = state => {
  const {
    user: { email, isMentor, edlyftCohort, courses = [] },
    edlyft: { courses: allCourses },
  } = state

  const userCourseList = courses.map(courseId =>
    allCourses.find(course => course.edlyftCohort === courseId),
  )

  return {
    email,
    isMentor,
    edlyftCohort,
    userCourseList,
  }
}

export default connect(mapStateToProps)(SidebarContent)
