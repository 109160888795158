import React, { useEffect, useRef, useState, useMemo } from 'react'
import axios from '../../axios-orders'
import withAmplitudeVideoLog from '../../hoc/AmplitudeVideoLog'
import VideoPlayer from 'plyr-react'
import 'plyr/dist/plyr.css'
import { Link } from 'react-router-dom'
import defaultPoster from '../../assets/images/video-preview.jpg'
import { connect } from 'react-redux'
import withPageWrapper from '../../hoc/AuthPageWrapper'
import SingleVideoSidebar from '../ViewsContent/SingleVideoSidebar'
import SingleVideoTabs from '../ViewsContent/SingleVideoTabs'
import Tag from '../TagItem'
import { saveInteraction } from '../../utils'
import { validDateObject } from '../../helpers'
import { format, utcToZonedTime } from 'date-fns-tz'
import { defaultTimezone } from '../../config'
import useMetricsApi, { RecordingEvent } from '../../hooks/useMetricsApi'
import e from 'cors'

const SingleVideo = ({
  match,
  videoPlayHandler,
  edlyftCohort,
  isMentor,
  isActive,
  screenOut,
  userId,
  firstName,
  lastName,
}) => {
  const [videoData, setVideoData] = useState({})
  const [fetchingItem, setFetchingItem] = useState(true)
  const cancelSourceVideo = useRef(null)
  const videoPlayerRef = useRef()
  const videoId = match.params.videoId

  const date =
    videoData?.sessionDate &&
    format(
      utcToZonedTime(validDateObject(videoData?.sessionDate)),
      'do MMMM, yyyy',
      {
        timeZone: defaultTimezone,
      },
    )

  useEffect(() => {

    // Fetch video data
    const fetchVideo = () => {
      axios
        .get('/common/single-recording', {
          cancelToken: cancelSourceVideo.current.token,
          params: {
            'video-id': videoId,
          },
        })
        .then(({ data }) => {
          const {
            classID,
            sessionDate,
            title,
            description,
            s3BucketPath,
            vttUrl,
            posterUrl,
            notes,
            cloudfrontURL,
            tags,
            isPrivate,
            links,
          } = data

          setVideoData({
            title,
            classID,
            description,
            sessionDate: new Date(sessionDate),
            s3BucketPath,
            vttUrl,
            posterUrl,
            cloudfrontURL,
            notes,
            tags,
            links,
            isPrivate,
            vidId: data['video-id'],
          })

          setFetchingItem(false)

          const event = new CustomEvent('video-timestamps-ready', {
            detail: {
              timestamps: data.hasOwnProperty('allTimestamps')
                ? data.allTimestamps
                : [],
              sessionDate,
              videoId,
              classID,
              timestampsConfirmationCount: data.hasOwnProperty(
                'timestampsConfirmationCount'
              )
                ? data.timestampsConfirmationCount
                : 0,
            },
          })

          window.dispatchEvent(event)
        })
        .catch((error) => {
          // Handle error
          console.log(error)
        })
    }

    // Use axios cancelToken to abort a request to prevent memory leaks
    // when attempting to setState after component is unmounted
    cancelSourceVideo.current = axios.CancelToken.source()
    // Fetch the single video data
    fetchVideo()

    return () => {
      cancelSourceVideo.current.cancel(
        'Cleanup function: cancel video request'
      )
    }
  }, [videoId])

  const { enqueue } = useMetricsApi()
  useEffect(() => {

    const handleTimestampClick = (e) => {
      if (videoPlayerRef?.current) {
        e.stopImmediatePropagation()
        const player = videoPlayerRef?.current
        if (player.plyr.playing) {
          player.plyr.pause()
          player.plyr.currentTime = e.detail.timestamp
          player.plyr.play()
        } else {
          player.plyr.currentTime = e.detail.timestamp
          player.plyr.play()
        }
      }
    }

    const playHandler = (event) => {
      if (event.target.classList.contains('plyr')) {
        saveInteraction({
          resourcePoster: '',
          edlyftCohort,
          resourceType: 'video',
          resourceId: videoData.vidId,
          screenOut,
          userId,
          isMentor,
          isActive,
          resourceTitle: videoData.title,
          userName: `${firstName} ${lastName}`,
        });
        videoPlayHandler(event.target.querySelector('video'), videoId, {
          source: 'video page',
          course: edlyftCohort,
        })
        enqueue(RecordingEvent)
      }
    }

    try {
      document.addEventListener('play', playHandler)
      window.addEventListener('play-from-timestamp', handleTimestampClick)
    } catch (e) {
      console.error(e);
    }

    return () => {
      try {
        document.removeEventListener('play', playHandler)
        window.removeEventListener('play-from-timestamp', handleTimestampClick)
      } catch (e) {
        console.error(e)
      }
    }
  }, [videoPlayHandler, videoId, firstName, lastName, userId, isActive, isMentor, edlyftCohort, screenOut, videoData, enqueue])

  const player = useMemo(() => {
    if (Object.keys(videoData).length === 0) return null

    const options = {
      speed: {
        selected: 1,
        options: [1, 1.25, 1.5, 2],
      },
      ratio: '16:9',
      controls: [
        'rewind',
        'play',
        'fast-forward',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'fullscreen',
        'settings',
      ],
    }

    if (videoData.vttUrl) {
      options.previewThumbnails = {
        enabled: true,
        src: videoData.vttUrl,
      }
    }

    return (
      <VideoPlayer
        key={options?.previewThumbnails?.src ?? 'no-vtt'}
        source={{
          type: 'video',
          title: videoData.title,
          sources: [
            {
              src: videoData.cloudfrontURL,
              type: 'video/mp4',
            },
          ],
          poster: videoData.posterUrl ?? defaultPoster,
        }}
        ref={videoPlayerRef}
        options={options}
      />
    )
  }, [videoData])

  if (fetchingItem) {
    return <p>Fetching video data...</p>
  }

  return (
    <div>
      <div className='flex justify-between leading-23 font-medium text-xl text-violet-dark'>
        <div>
          <Link to='/recordings' style={{ opacity: '0.3' }}>
            Recordings
          </Link>
          <span className='mx-2'>/</span>
          <span>{videoData.title}</span>
        </div>

        <div>
          <span className='text-sm font-normal'>Session Date:</span>
          <span className='text-sm font-light'>
            {' '}
            {date}
          </span>
        </div>
      </div>
      <div className='flex -mx-3'>
        <div className='flex-1 px-3 md:w-3/5 lg:w-2/3'>
          <div className='mt-8 mb-10'>
            <div className='player-wrapper relative rounded-md overflow-hidden'>
              {player}
            </div>
            {Array.isArray(videoData?.tags) && videoData.tags.length > 0 && (
              <div className='mt-3'>
                <span className='text-black text-sm leading-13 font-medium mr-2'>
                  Tags:
                </span>
                {videoData.tags.map((tag, index) => (
                  <span key={index} className='inline-block mr-2'>
                    <Tag
                      style={{
                        background: 'rgba(249, 215, 114, 0.3)',
                        border: '0.7px solid #F9D772',
                      }}
                      text={tag}
                    />
                  </span>
                ))}
              </div>
            )}
          </div>
          <SingleVideoTabs
            videoId={videoId}
            notes={videoData?.notes}
            title={videoData?.title}
            description={videoData?.description}
            sessionDate={videoData?.sessionDate}
            s3BucketPath={videoData?.s3BucketPath}
            tags={videoData?.tags}
            links={videoData?.links}
            isPrivate={videoData?.isPrivate}
            cloudfrontURL={videoData?.cloudfrontURL}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    user: { edlyftCohort, isMentor, isActive, screenOut, userId, firstName, lastName },
  } = state

  return {
    edlyftCohort,
    isMentor,
    isActive,
    screenOut,
    userId,
    firstName,
    lastName,
  }
}

export default connect(mapStateToProps)(
  withAmplitudeVideoLog(
    withPageWrapper(
      SingleVideo,
      'single-video',
      '/recordings',
      'Course Recordings',
      SingleVideoSidebar
    )
  )
)
