import React, { useState, useEffect, useMemo } from 'react'
import { Modal } from 'antd'

import { usePracticeProblems } from '../../../hooks/usePracticeProblems'
import amplitude from 'amplitude-js'
import { Tabs } from 'antd'
import CardsView from './Cards/CardsView'
import ExamPrepTitle from './ExamPrepTitle'
import FeaturedTopicsView from './Topics/FeaturedTopicsView'
import PlaySolution from './Modal/PlaySolution'
import EmptyState from './Cards/EmptyState'

import { amplitudeInstance } from '../../../amplitude'
import { useEdlyftCohorts } from '../../../hooks/useEdlyftCohorts'
import { getStudentMentors } from '../../../utils'
import { ALL_TOPICS_TEXT, MENTOR_RECOMMENDED_TEXT } from '../../constants'

const { TabPane } = Tabs

const ALL_QUESTIONS = 'All Problems'

const listProblemsByExam = (cohortItem, exam) => {
  if (exam === ALL_QUESTIONS) return cohortItem
  return cohortItem.filter(card =>
    card.onExams
      .map(exam => exam.trim().toLowerCase())
      .includes(exam.trim().toLowerCase()),
  )
}

const ExamPrep = props => {
  let { edlyftCohort, user, mentorsObject, setMentors } = props
  const mentors = mentorsObject.mentors

  // set amplitude interaction tracking
  const identify = new amplitude.Identify()
  amplitudeInstance.identify(identify)

  const [selectedExam, setSelectedExam] = useState(ALL_QUESTIONS)
  const [selectedTopics, setSelectedTopics] = useState([
    MENTOR_RECOMMENDED_TEXT,
  ])
  const [modalProblemId, setModalProblemId] = useState(null)

  // pleasssssee refactor this out once 'Mentors' is set properly on Redux
  const [loadingMentors, setLoadingMentors] = useState(false)

  const { problems } = usePracticeProblems(edlyftCohort)
  const [cohort] = useEdlyftCohorts([edlyftCohort])

  // Get derived state
  const loaded = cohort && problems.length > 0

  const { tags: topics = [], exams: cohortExams = [] } = cohort || {}
  const exams = [ALL_QUESTIONS, ...cohortExams]

  const modalProblem = problems.find(problem => problem.id === modalProblemId)

  const examProblems = useMemo(
    () => listProblemsByExam(problems, selectedExam),
    [problems, selectedExam],
  )

  const mentorRecommendedProblems = useMemo(() => {
    const { starredPracticeProblems = [] } = cohort || {}

    return examProblems.filter(problem =>
      starredPracticeProblems.includes(problem.id),
    )
  }, [cohort, examProblems])

  // get the memoized filtered cards
  const filteredProblems = useMemo(() => {
    if (selectedTopics.includes(ALL_TOPICS_TEXT)) {
      return examProblems
    }

    if (selectedTopics.includes(MENTOR_RECOMMENDED_TEXT)) {
      if (examProblems.length && !mentorRecommendedProblems.length) {
        setSelectedTopics([ALL_TOPICS_TEXT])
      }

      return mentorRecommendedProblems
    }

    const normalizedSelectedTopics = selectedTopics.map(topic =>
      topic.trim().toLowerCase(),
    )
    const filteredProblems = examProblems.filter(problem => {
      const normalizedProblemConcepts = problem.concepts.map(concept =>
        concept.trim().toLowerCase(),
      )
      return normalizedSelectedTopics.some(topic =>
        normalizedProblemConcepts.includes(topic),
      )
    })

    return filteredProblems
  }, [selectedTopics, examProblems, mentorRecommendedProblems])

  useEffect(() => {
    const fetchMentors = async () => {
      setLoadingMentors(true)
      const { mentors, courseNumbers } = await getStudentMentors([edlyftCohort])
      if (mentors.length) {
        setMentors(mentors, courseNumbers)
      }
    }

    if (!loadingMentors) {
      fetchMentors()
    }
  })

  const filterTopic = topic => {
    let newTopics

    // amplitude tracking
    amplitudeInstance.logEvent('Clicked Featured Filters', {
      cohort: edlyftCohort,
      filter: topic,
    })

    // when clicking "All Topics", clear the select topics filter
    if (topic === ALL_TOPICS_TEXT) {
      setSelectedTopics([ALL_TOPICS_TEXT])
    } else if (topic === MENTOR_RECOMMENDED_TEXT) {
      setSelectedTopics([MENTOR_RECOMMENDED_TEXT])
    } else {
      // if clicked an already selected topic, remove it from the filter
      if (selectedTopics.includes(topic)) {
        selectedTopics.splice(selectedTopics.indexOf(topic), 1)
        newTopics = [...selectedTopics]

        if (newTopics.length < 1) {
          newTopics.push(ALL_TOPICS_TEXT)
        }

        setSelectedTopics([...newTopics])
      } else {
        // remove 'all topics' and simply add the new topic to
        // the selectedTopics array
        if (selectedTopics.includes(ALL_TOPICS_TEXT)) {
          selectedTopics.splice(selectedTopics.indexOf(ALL_TOPICS_TEXT), 1)
        }

        if (selectedTopics.includes(MENTOR_RECOMMENDED_TEXT)) {
          selectedTopics.splice(
            selectedTopics.indexOf(MENTOR_RECOMMENDED_TEXT),
            1,
          )
        }
        newTopics = selectedTopics

        setSelectedTopics([...newTopics, topic])
      }
    }
  }

  const setExamTab = exam => {
    setSelectedExam(exam)
    setSelectedTopics([ALL_TOPICS_TEXT])

    // amplitude tracking
    amplitudeInstance.logEvent('Clicked Exam Tab', {
      cohort: edlyftCohort,
      exam_title: exam,
    })
  }

  if (!loaded) {
    // to be implemented when we will have designs for it
    return (
      <div>
        <ExamPrepTitle user={user} />
        <EmptyState />
      </div>
    )
  } else {
    return (
      <div>
        <ExamPrepTitle user={user} />

        <Tabs defaultActiveKey='1' onChange={setExamTab} centered={true}>
          {exams.map(exam => (
            <TabPane
              tab={
                <span style={{ fontSize: '16px', fontFamily: 'Circular Std' }}>
                  {exam}
                </span>
              }
              key={exam}
              animated={true}
            >
              <FeaturedTopicsView
                topics={topics}
                filterTopic={filterTopic}
                selectedTopics={selectedTopics}
                hasMentorRecommendedProblems={mentorRecommendedProblems.length}
              />

              <CardsView
                mentor={mentors[0]}
                user={user}
                filteredProblems={filteredProblems}
                selectedTopics={selectedTopics}
                setModalProblemId={setModalProblemId}
              />
            </TabPane>
          ))}
        </Tabs>

        <Modal
          visible={modalProblemId}
          footer={null}
          wrapClassName={'exam-prep-modal'}
          onCancel={() => {
            setModalProblemId(null)
          }}
          width={750}
        >
          <PlaySolution
            problem={modalProblem}
            mentor={mentors[0]}
            user={user}
          />
        </Modal>
      </div>
    )
  }
}

export default ExamPrep
