import React from 'react'
import PeopleIcon from '../../../assets/icons/people.svg'
import Illustration from '../../../assets/icons/Illustration.svg'
import Illustration2 from '../../../assets/icons/Illustration2.svg'

const styles = {
  title: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '25px',
    letterSpacing: '-0.03em',
    color: '#2C2543',
  },
  pane: {
    background: '#F9F9F9',
    border: '1px solid #F4F6F3',
    boxSizing: 'border-bo',
    borderRadius: '5px',
    margin: '25px 0px',
    padding: '20px',
  },
  paneTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '-0.03em',
    color: '#2C2543',
  },
  paneTxt: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#2C2543',
    margin: '20px 0',
  },
  paneImage1: {
    width: '100%',
    height: '80px',
    backgroundImage: `url(${Illustration})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  paneImage2: {
    width: '100%',
    height: '80px',
    backgroundImage: `url(${PeopleIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  paneImage3: {
    width: '100%',
    height: '80px',
    backgroundImage: `url(${Illustration2})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}

const BookingsSidebar = () => {
  return (
    <div style={{ width: '290px' }}> </div>
  )
}

export default BookingsSidebar
