import React from 'react'
import AuthSlider from './AuthSlider'
import SlideImage1 from '../../assets/images/auth-slide-1.png'
import SlideImage2 from '../../assets/images/auth-slide-2.png'
import SlideImage3 from '../../assets/images/auth-slide-3.png'
import AuthFormInterim from './AuthFormInterm'

const SLIDE_CAPTION = 'Join Edlyft to get the help you need with your STEM courses.'
const SLIDES = [
  {
    title: 'Welcome!',
    caption: SLIDE_CAPTION,
    image: SlideImage1
  },
  {
    title: 'Need help with Homework?',
    caption: SLIDE_CAPTION,
    image: SlideImage2
  },
  {
    title: 'Meet other Students',
    caption: SLIDE_CAPTION,
    image: SlideImage3
  },
]

const GetStarted = () => {
  return (
    <div className="flex flex-wrap bg-gray-lightest h-screen overflow-scroll">
      <div className="w-full md:h-screen md:max-h-screen pt-16 pb-6 md:w-1/2 lg:w-3/5">
        <AuthSlider slides={SLIDES} />
      </div>
      <div className="max-w-full w-full md:w-1/2 lg:w-2/5 flex items-center justify-center">
        <AuthFormInterim/>
      </div>
    </div>
  )
}

export default GetStarted