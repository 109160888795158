
/**
 * Gets the path of a url without the starting '/'
 *
 * @export
 * @param {string | URL} url
 * @return {string} 
 */
export function getUrlPath(url) {
  const urlObject = url instanceof URL ? url : new URL(url)
  return urlObject.pathname.substring(1)
}

/**
 * Get's the file name from the url
 *
 * @export
 * @param {string | URL} url
 * @return {string} 
 */
export function getFilename(url) {
  const urlObject = url instanceof URL ? url : new URL(url)
  const splitFilePath = urlObject.pathname.split('/')
  return splitFilePath[splitFilePath.length - 1]
}