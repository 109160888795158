import React from 'react'
import ContentLoader from 'react-content-loader'

const SingleRecoringLoader = ({ count = 1, hideHeader }) => {
  return (
    <>
      {
        !hideHeader &&
        <div className="mb-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 284 24"
            backgroundColor="#EEEEF0"
            foregroundColor="#f9f9f9"
            style={{
              maxWidth: '100%',
              width: '284px',
              maxHeight: '24px',
              height: 'auto'
            }}
          >
            <rect x="0" y="0" rx="4" ry="4" width="284" height="24" />
          </ContentLoader>
        </div>
      }
      {
        Array.from(new Array(count)).map((item, index) => (
          <div key={index} className="mb-3">
            <ContentLoader
              speed={2}
              viewBox="0 0 800 145"
              backgroundColor="#EEEEF0"
              foregroundColor="#f9f9f9"
              style={{
                maxWidth: '100%',
                width: '800px',
                maxHeight: '145px',
                height: 'auto'
              }}
            >
              <rect x="0" y="10" rx="4" ry="4" width="136" height="125" />
              <rect x="155" y="10" rx="4" ry="4" width="113" height="25" />
              <rect x="155" y="44" rx="4" ry="4" width="625" height="17" />
              <rect x="155" y="68" rx="4" ry="4" width="484" height="17" />
              <rect x="155" y="95" rx="4" ry="4" width="103" height="12" />
              <rect x="275" y="95" rx="4" ry="4" width="103" height="12" />
              <rect x="155" y="123" rx="4" ry="4" width="241" height="12" />
              <circle cx="768" cy="22" r="13" />
            </ContentLoader>
          </div>
        ))
      }
    </>
  )
}

export default SingleRecoringLoader

