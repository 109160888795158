import { SET_EDLYFT_COURSES, GET_EDLYFT_COURSES } from '../types'

export const setCourses = courses => {
  return {
    type: SET_EDLYFT_COURSES,
    courses,
  }
}

export const getCourses = courses => {
  return {
    type: GET_EDLYFT_COURSES,
    courses,
  }
}
