import { Card, Divider, Typography } from 'antd'

import TopicRect from '../Topics/TopicRect'
import TagLevel from '../Topics/TagLevel'
import amplitude from 'amplitude-js'

import FooterUnsolved from './FooterUnsolved'
import FooterSolved from './FooterSolved'

import { amplitudeInstance } from '../../../../amplitude'
import { capitalizeFirstLetter } from '../../../../utils/capitalize'
import { useHistory } from 'react-router-dom'
import CheckIcon from '../../../CheckIcon'
import { starPracticeProblem } from '../../../../utils/api/starPracticeProblem'
import { useMemo, useState } from 'react'
import { useUpdateCohortStarredPracticeProblems } from '../../../../hooks/useUpdateCohortStarredPracticeProblems'
import { useEdlyftCohorts } from '../../../../hooks/useEdlyftCohorts'
import { isCohortPracticeProblemStarred } from '../../../../utils/isCohortPracticeProblemStarred'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import useMetricsApi, { NPPEvent } from '../../../../hooks/useMetricsApi'
import { saveInteraction } from '../../../../utils'

const { Title } = Typography

const styles = {
  card: {
    margin: '0.75em',
    width: '350px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
  },

  topics: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '110px',
    overflowY: 'hidden',
    alignContent: 'baseline',
  },

  titleLevelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

const selectQuestion = card => {
  // when clicking on the card on
  // selection mode, select the question
}

const QuestionCard = ({
  problem,
  setModalProblemId,
  selectionMode,
  addToSelectedProblems,
  removeFromSelectedProblems,
  edlyftCohort,
  isMentor,
  screenOut,
  isActive,
  firstName,
  lastName,
  userId,
}) => {
  const updateStarredPracticeProblems = useUpdateCohortStarredPracticeProblems()

  const [cohort] = useEdlyftCohorts([edlyftCohort])

  const isProblemStarred = useMemo(
    () => isCohortPracticeProblemStarred(cohort, problem.id),
    [cohort, problem],
  )

  const [isStarred, setIsStarred] = useState(isProblemStarred)

  useEffect(() => {
    setIsStarred(isProblemStarred)
  }, [isProblemStarred])

  const history = useHistory()
  const { enqueue } = useMetricsApi()

  const openQuestion = () => {
    // report click to amplify
    const identify = new amplitude.Identify()
    amplitudeInstance.identify(identify)
    amplitudeInstance.logEvent('Clicked Question (Problem)', {
      ...problem,
    })
    enqueue(NPPEvent)
    saveInteraction({
      resourcePoster: '',
      edlyftCohort,
      resourceType: 'practiceProblem',
      resourceId: problem.id,
      screenOut,
      userId,
      isActive,
      isMentor,
      resourceTitle: 'Clicked Practice Problem Card',
      userName: `${firstName} ${lastName}`,
    })

    history.push(`/practice-problem/${problem.id}`)
  }

  const onCardClick = e => {
    e.stopPropagation()
    if (!selectionMode) {
      openQuestion(problem)
    } else {
      selectQuestion(problem)
    }
  }

  const star = async e => {
    e.stopPropagation()

    try {
      setIsStarred(isStarred => !isStarred)

      const { starredPracticeProblems } = await starPracticeProblem({
        edlyftCohort: cohort.edlyftCohort,
        practiceProblemId: problem.id,
      })

      updateStarredPracticeProblems(
        cohort.edlyftCohort,
        starredPracticeProblems,
      )
    } catch (error) {
      setIsStarred(isProblemStarred)
    }
  }

  const getCheckIconTooltipText = (isMentor, checked) => {
    if (isMentor && !checked) {
      return 'Click to recommend to your cohort'
    }

    if (!isMentor && checked) {
      return 'Your mentor recommended this problem for you'
    }

    return null
  }

  const getCheckIcon = (checked, isMentor, onClick) => {
    if (!isMentor && !checked) {
      return null
    }

    const onClickHandler = isMentor ? onClick : () => {}
    const tooltipText = getCheckIconTooltipText(isMentor, checked)

    return (
      <CheckIcon
        checked={checked}
        isMentor={isMentor}
        tooltipText={tooltipText}
        onClick={onClickHandler}
      />
    )
  }

  return (
    <div onClick={onCardClick} style={{ zIndex: '0' }}>
      <Card
        bordered={true}
        hoverable={true}
        style={styles.card}
        className='card-hover-override'
      >
        {/* card title */}
        <div style={styles.title}>
          <Title level={5}>{problem.title}</Title>
          <div style={styles.titleLevelContainer}>
            <TagLevel level={capitalizeFirstLetter(problem.difficulty)} />
            {getCheckIcon(isStarred, isMentor, star)}
          </div>
        </div>

        <Divider />

        {/* topics */}
        <div style={styles.topics}>
          <TopicRect key='Topics' text='Topics' noBackground />
          {Array.isArray(problem.concepts) ? (
            problem.concepts.map(concept => (
              <TopicRect key={concept} text={concept} selected={false} />
            ))
          ) : (
            <></>
          )}
        </div>

        {/* card's footer */}
        {!problem.solutionVideoUrl ? (
          <FooterUnsolved
            problem={problem}
            selectionMode={selectionMode}
            addToSelectedProblems={addToSelectedProblems}
            removeFromSelectedProblems={removeFromSelectedProblems}
          />
        ) : (
          <></>
        )}

        {problem.solutionVideoUrl ? (
          <FooterSolved
            problem={problem}
            setModalProblemId={setModalProblemId}
            selectionMode={selectionMode}
            addToSelectedProblems={addToSelectedProblems}
            removeFromSelectedProblems={removeFromSelectedProblems}
          />
        ) : (
          <></>
        )}
      </Card>
    </div>
  )
}

const mapStateToProps = ({
  user: { edlyftCohort, isMentor, screenOut, isActive, firstName, lastName, userId },
}) => ({
  edlyftCohort,
  isMentor,
  screenOut,
  isActive,
  firstName,
  lastName,
  userId,
})

export default connect(mapStateToProps)(QuestionCard)
