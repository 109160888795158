import React, { useState } from 'react'
import Logo from '../../assets/images/edlyft-logo.png'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '../Form/Button'
import { amplitudeInstance } from '../../amplitude'
import { useLocation } from 'react-router-dom'

const AuthFormInterim = () => {
  const { loginWithRedirect } = useAuth0()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const handleAuth = () => {
    amplitudeInstance.logEvent('Clicks Get Started')
    loginWithRedirect({
      appState: {
        suid: params.get('suid'),
      }
    })
  }

  return (
    <div className="bg-white my-10 px-4 py-12 rounded-lg" style={{ width: '300px' }}>
      <img className="mb-12 mx-auto" style={{ width: '100px' }} src={Logo} alt={"logo"} />
      <Button className="bg-blue-normal w-full" onClick={handleAuth} text="Get Started" />
    </div>
  )
}

export default AuthFormInterim