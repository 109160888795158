import AlertSimple from './components/AlertSimple'
import { toast } from 'react-toastify'

/**
 * Load JS script dynamically
 *
 * @param {Function} callback
 */
export const loadDynamicScript = (data, callback) => {
  const existingScript = document.getElementById('charge-bee')

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = data.src // URL for the third-party library being loaded.
    script.id = data.id
    if (data.attributes) {
      Object.entries(data.attributes).forEach(([attribute, value]) => {
        script.setAttribute(attribute, value)
      })
    }
    document.body.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}

/**
 * Helper function to get accurate date object either from string
 * or number
 *
 * @param {String|Number} date - Date timestamp or valid dtae string
 * @returns {Object}
 */
export const validDateObject = date => {
  // Check if date is a number or it's a string that contains only nyumbers
  if (
    typeof date === 'number' ||
    (typeof date === 'string' && /^\d+$/.test(date))
  ) {
    return new Date(Number(date))
  }

  return new Date(date)
}

/**
 * Handle debounce
 *
 * @param {Function} callback
 * @param {Number} delay - Time in milliseconds
 */
export const debounce = (func, delay, immediate) => {
  let timerId
  return (...args) => {
    const boundFunc = func.bind(this, ...args)
    clearTimeout(timerId)
    if (immediate && !timerId) {
      boundFunc()
    }
    const calleeFunc = immediate
      ? () => {
          timerId = null
        }
      : boundFunc
    timerId = setTimeout(calleeFunc, delay)
  }
}

/**
 * Convert snake case to title case
 *
 * @param {String} str
 *
 * @returns {String}
 */
export const snakeToTitle = str => {
  let sentence = str.toLowerCase().split('_')
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(' ')
}

/**
 * Get common cohort value. e.g Different schools with different
 * course codes but same curriculum
 *
 * @param {String} cohort
 *
 * @returns {String}
 */
export const getCommonCohortTitle = (cohort, allCourses) => {
  // Get a list of all courses that match the cohort passed
  const cohortData = allCourses.find(item => item.edlyftCohort === cohort)
  if (cohortData) {
    return cohortData.courseNumber
  }
  // Retun back the default cohort passed
  return cohort
}

export const getMentorToBook = (mentors, edlyftCohort) => {
  const mentor = mentors?.find(mentor => mentor.courses.includes(edlyftCohort))
  if (mentor) return mentor
  else return {}
}

export const SortBy = (arr, sortParam) =>
  arr.sort((current, next) => {
    var orderA = current[sortParam]?.toString()?.toUpperCase() // ignore upper and lowercase
    var orderB = next[sortParam]?.toString()?.toUpperCase()
    if (orderA < orderB) {
      return -1
    } else if (orderA > orderB) {
      return 1
    } else {
      return 0
    }
  })

export const AlertActionSuccess = (successMessage, closeText = 'Got It') => {
  return toast(
    <AlertSimple title={<div>{successMessage}</div>} closeText={closeText} />,
  )
}
