import figure from '../assets/chat-with-mentor.svg'
import { Button } from 'antd';

const style = {
  container: {
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fdf8f0",
  },
  startConversation: {
    height: "135px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "1em",
  },
  conversationInfo: {
    display: "flex",
    width: "300px",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    marginRight: "5em"
  },
  button: {
    borderRadius: "5px",
  },
}

const DiscussWithBottom = (props) => {
  const { discussCallback, isLoadingDiscussProblem } = props

  return (
    <div style={style.container}>
      <div style={style.startConversation}>
        <img src={figure} alt={"chat with mentor"}></img>
      </div>
      <div style={style.conversationInfo}>
        <p>Need help with the problem? Click below to get your mentor’s help.</p>
        <Button 
          type="primary" 
          size={"large"} 
          style={style.button} 
          onClick={discussCallback}
          loading={isLoadingDiscussProblem}
        >
          Discuss problem with mentor
        </Button>
      </div>
    </div>

  )
}

export default DiscussWithBottom;