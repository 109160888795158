import CheckIcon from '../../../CheckIcon'
import { Container, Tooltip } from './styles'

const ProblemStar = ({ starred }) => {
  return starred ? (
    <Container>
      <Tooltip>Mentor recommended</Tooltip>
      <CheckIcon width='25px' height='24px' checked={true} />
    </Container>
  ) : null
}

export default ProblemStar
