import axios from '../../axios-orders'

export const saveInteraction = async interactionData => {
  const { isActive, isMentor, ...otherData } = interactionData

  if (isActive && !isMentor) {
    try {
      const { data } = await axios.post('/student/resource-interactions', {
        ...otherData,
        interactionDate: Date.now(),
      })
      return { data }
    } catch (e) {
      console.log(e.message)
      return { e }
    }
  }
}
