import { Button } from 'antd';
import amplitude from 'amplitude-js'

import { amplitudeInstance } from '../../../../amplitude'
import questionFigure from '../assets/question-mark.svg'

const style = {
  container: {
    display: "flex",
    borderRadius: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F1FAF4",
  },
  startConversation: {
    height: "135px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "1em",
  },
  button: {
    borderRadius: "5px",
  },
}

const BookTimeBottom = (props) => {
  const { mentorId, mentorCalendlyLink, studentId, practiceProblemName } = props
  const identify = new amplitude.Identify()
  amplitudeInstance.identify(identify)

  const clickHandle = (mentorId, studentId, practiceProblemName) => {

    // track click
    amplitudeInstance.logEvent("Clicked Book 1:1 for Problem w/ Mentor Button", {
      practiceProblemName: practiceProblemName,
      mentorId: mentorId,
      studentId: studentId
    })

    window.open(mentorCalendlyLink, '_blank')
  }

  if (!mentorCalendlyLink) return null

  return (
    <div style={style.container}>
      <div style={style.startConversation}>
        <img src={questionFigure} alt={"book time with mentor"}></img>
        <span>Still need help on this?</span>
      </div>
      <div style={{margin: "2em"}}>
        <Button
          type="primary"
          size={"large"}
          style={style.button}
          onClick={() => {
            clickHandle(mentorId, studentId, practiceProblemName)
          }}
        >
          Book 1:1 with your mentor
        </Button>
      </div>
    </div>
  )
}

export default BookTimeBottom;