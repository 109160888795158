import React from 'react'
import { ReactComponent as PlayIcon } from '../../assets/icons/play-round.svg'
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg'
import SingleNote from './SingleNote'
import { Link } from 'react-router-dom'
import defaultPoster from '../../assets/images/video-preview.jpg'
import { amplitudeInstance } from '../../amplitude'
import { format, utcToZonedTime } from 'date-fns-tz'
import { defaultTimezone } from '../../config'
import { validDateObject } from '../../helpers'
import Tag from '../TagItem'
import VideoLinks from './VideoLinks'

const WIDTH = '135px'
const ICON_SIZE = '24px'

const ResourceInd = ({
  title,
  videoId,
  description,
  sessionDate,
  isMentor,
  duration,
  openUploadModal,
  notes,
  videoURL,
  links,
  edlyftCohort,
  tags,
  hidePlayIcon,
  dateText,
  noteText,
  isPrivate,
  posterUrl,
}) => {
  // Add typechecking for date
  const localeTime = utcToZonedTime(validDateObject(sessionDate))
  let date
  let modalSessionDate

  try {
    date = format(localeTime, 'do MMMM, yyyy', {
      timeZone: defaultTimezone,
    })

    modalSessionDate = format(localeTime, 'yyyy-MM-dd', {
      timeZone: defaultTimezone,
    })
  } catch (e) {}

  const handleUploadModalClick = () => {
    // Open upload modal to edit recording
    openUploadModal({
      videoId,
      rawSessionDate: sessionDate,
      sessionDate: modalSessionDate,
      description,
      videoTitle: title,
      notes,
      video: videoURL
        ? {
            name: title,
            url: videoURL,
          }
        : null,
      tags,
      links,
      isPrivate,
    })
  }

  const handleOnNavigate = () => {
    amplitudeInstance.logEvent('Clicks Recording in List', {
      videoId,
      course: edlyftCohort,
    })
  }

  return (
    <div className='flex'>
      <Link
        onClick={handleOnNavigate}
        to={`/videos/${videoId}`}
        style={{
          width: WIDTH,
          minWidth: WIDTH,
          height: '124px',
          backgroundImage: `url(${posterUrl}), url(${defaultPoster})`,
        }}
        className='bg-cover bg-center inline-block relative bg-gray-lighter rounded'
      >
        <div className='text-white w-full h-full flex  flex-col-reverse'>
          {!hidePlayIcon && (
            <span className='absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center'>
              <PlayIcon />
            </span>
          )}
          {duration && (
            <div className='ml-2 mb-2 bg-gray-video  rounded  w-1/3'>
              <div className='p-1 text-center font-normal text-white opacity-100  text-sm leading-15'>
                {duration}
              </div>
            </div>
          )}
        </div>
      </Link>
      <div className='ml-6 flex-1' style={{ wordBreak: 'break-all' }}>
        <div className='flex justify-between mb-2'>
          <h2 className='leading-23 text-xl'>
            <Link
              onClick={handleOnNavigate}
              className='text-violet-dark'
              to={`/videos/${videoId}`}
            >
              {title}
            </Link>
          </h2>

          {isMentor && (
            <button
              onClick={handleUploadModalClick}
              style={{
                minWidth: ICON_SIZE,
                width: ICON_SIZE,
                height: ICON_SIZE,
                minHeight: ICON_SIZE,
              }}
              className='ml-2 bg-gray-lighter rounded-full'
            >
              <span className='flex items-center justify-center'>
                <PencilIcon />
              </span>
            </button>
          )}
        </div>
        <p className='line-clamp mb-3 font-light text-base text-gray-normal leading-20'>
          {description}
        </p>
        <div className='mb-2 relative flex items-baseline'>
          <div className='text-sm mr-4'>
            <span className='text-darkest leading-13 font-medium mr-1'>
              {dateText || 'Session Date:'}
            </span>
            <span className='text-gray-normal'>{date}</span>
          </div>
          {Array.isArray(tags) && tags.length > 0 && (
            <div className='text-sm'>
              <span className='text-darkest leading-13 font-medium mr-2'>
                Tags:
              </span>
              {tags.slice(0, 2).map((tag, index) => (
                <span key={index} className='inline-block mr-2'>
                  <Tag
                    className='text-xs'
                    style={{
                      background: 'rgba(249, 215, 114, 0.3)',
                      border: '0.7px solid #F9D772',
                    }}
                    text={tag}
                  />
                </span>
              ))}
            </div>
          )}
        </div>
        {Array.isArray(notes) && notes.length > 0 && (
          <div className=''>
            <SingleNote notes={notes} title={noteText} />
          </div>
        )}
        {Array.isArray(links) && links.length > 0 && (
          <VideoLinks links={links} edlyftCohort={edlyftCohort} />
        )}
      </div>
    </div>
  )
}

export default ResourceInd
