import { connect } from 'react-redux'
import { Modal } from 'react-responsive-modal'
import { useHistory } from 'react-router-dom'
import withPageWrapper from '../../../hoc/AuthPageWrapper'

import CommonSidebar from '../../ViewsContent/CommonSidebar'
import { getMentorToBook } from '../../../helpers'

import { ReactComponent as CalendlyIcon } from './assets/calendly.svg'
import { ReactComponent as EmailIcon } from './assets/email.svg'
import { ReactComponent as DiscordIcon } from './assets/discord.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-x.svg'

import './styles.css'
import useMetricsApi, {
  MentorInteractionEvent,
} from '../../../hooks/useMetricsApi'
import { setStudentMentors } from '../../../redux/actions/mentors'
import { useEffect } from 'react'
import { getStudentMentors } from '../../../utils'

const ContactMentor = ({
  mentors,
  courses,
  edlyftCohort,
  edlyftCourses,
  setMentors,
}) => {
  const history = useHistory()
  const { enqueue } = useMetricsApi()

  useEffect(() => {
    if (mentors.length) return

    const fetchMentors = async () => {
      const { mentors, courseNumbers } = await getStudentMentors(courses)

      if (mentors.length) {
        setMentors(mentors, courseNumbers)
      }
    }

    fetchMentors()
  }, [mentors, courses])

  const mentor = getMentorToBook(mentors || [], edlyftCohort)
  const cohort = edlyftCourses.find(
    cohort => cohort.edlyftCohort === edlyftCohort,
  )

  if (!mentor || !cohort) return null

  return (
    <Modal
      open={true}
      onClose={() => {
        history.push('/')
      }}
      center
      styles={{
        overlay: {
          zIndex: '2000',
        },
        modal: {
          width: '371px',
        },
      }}
      classNames={{
        overlay: 'py-0 pr-0',
        modal: 'max-w-full bg-white p-6 overflow-auto rounded-xl',
      }}
      closeIcon={<CloseIcon className='close-button' />}
    >
      <div className='container'>
        <img
          className='profile-pic'
          src={mentor.photoURL}
          alt='Mentor profile pic'
        />
        <div className='information-container'>
          <h1 className='name'>
            {mentor.firstName} {mentor.lastName}
          </h1>
          <div className='subtitle-container'>
            {cohort.university && (
              <>
                <h2 className='subtitle'>{cohort.university}</h2>
                <h2 className='subtitle'>&#8226;</h2>
              </>
            )}
            <h2 className='subtitle'>{cohort.edlyftCohort}</h2>
          </div>
        </div>
      </div>
      <div className='buttons-container'>
        {mentor.calendlyLink ? (
          <a
            className='button'
            target='_blank'
            rel='noreferrer nofollow'
            href={mentor.calendlyLink}
            onClick={() => {
              enqueue(MentorInteractionEvent)
            }}
          >
            <CalendlyIcon className='button-icon' />
            Book time on Calendly
          </a>
        ) : null}
        {mentor.discordUserId ? (
          <a
            className='button'
            target='_blank'
            rel='noreferrer nofollow'
            href={`https://discord.com/channels/@me/${mentor.discordUserId}`}
            onClick={() => {
              enqueue(MentorInteractionEvent)
            }}
          >
            <DiscordIcon className='button-icon' />
            Message directly on Discord
          </a>
        ) : null}
        {mentor.email ? (
          <a
            className='button'
            target='_blank'
            rel='noreferrer nofollow'
            href={`mailto:${mentor.email}`}
            onClick={() => {
              enqueue(MentorInteractionEvent)
            }}
          >
            <EmailIcon className='button-icon' />
            Send an email
          </a>
        ) : null}
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  const {
    user,
    mentors: { mentors },
    edlyft: { courses: edlyftCourses },
  } = state

  const { edlyftCohort } = user
  // const mentor = getMentorToBook(mentors.mentors || [], edlyftCohort)

  // const cohort = courses.find(cohort => cohort.edlyftCohort === edlyftCohort)

  return {
    edlyftCohort,
    user,
    mentors,
    courses: user.courses,
    edlyftCourses,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMentors: (mentors, courseNumbers) =>
      dispatch(setStudentMentors(mentors, courseNumbers)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withPageWrapper(
    ContactMentor,
    'contact-mentor',
    undefined,
    undefined,
    CommonSidebar,
  ),
)
