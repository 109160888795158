const TopicRect = props => {
  const { text, selected, noBackground, onClick, insideStyle } = props
  let onTopicClick

  if (!onClick) {
    onTopicClick = () => {
      console.log(`${text} was clicked, but no callback`)
    }
  } else {
    onTopicClick = onClick
  }

  let style = {
    display: 'inline-flex',
    border: '2px solid #4290F300',
    padding: '8px 12px 8px 12px',
    background: '#F4F8FE',
    borderRadius: '15px',
    textAlign: 'center',
    marginRight: '1em',
    marginBottom: '1em',
    flexShrink: '0',
    transition: 'border-color 0.3s ease-out',
    cursor: 'pointer',
  }

  if (selected) {
    style = {
      ...style,
      border: '2px solid #4290F3',
      transition: 'border-color 0.3s ease-out',
    }
  }

  if (noBackground) {
    style = { ...style, background: 0, padding: 0, border: 0, borderRadius: 0 }
  }

  if (insideStyle) {
    style = { ...style, ...insideStyle }
  }

  return (
    <div
      style={style}
      onClick={() => {
        onTopicClick(text)
      }}
    >
      {text}
    </div>
  )
}

export default TopicRect
