import { express } from '../../axios-orders'

export const postEmailAllMessage = async (
  { mentorId, edlyftCohort, receivers, subject, body },
  options = {},
) =>
  await express.post(
    '/cohort/email',
    {
      mentorId,
      edlyftCohort,
      receivers,
      subject,
      body,
    },
    options,
  )
