import React from 'react'
import { Button } from './Form/Button'

const AlertSimple = ({ closeToast, type, closeText, title, message, style = {} }) => {

  let mainClass
  let mainStyle
  let closeButtonClass

  switch (type) {
    case 'error':
      mainClass = 'bg-red-lightest'
      mainStyle = {
        border: '1px solid rgba(235, 100, 105, 0.45)'
      }
      closeButtonClass = 'bg-red-normal'
    break;
  
    default:
      mainClass = 'bg-green-lightest'
      mainStyle = {
        border: '1px solid rgba(120, 202, 149, 0.45)'
      }
      closeButtonClass = 'bg-green-normal'
      break;
  }

  return (
    <div style={{...mainStyle, ...style}} className={`p-2 flex justify-between rounded-md border-green-normal ${mainClass}`}>
      <div className="my-0 mx-3">
        {
          title &&
          <div className="mb-0 font-medium leading-30 text-black text-2xl">{title}</div>
        }
        {
          message &&
          <div className="leading-23 font-light text-lg text-black">{message}</div>
        }
      </div>
      <div>
        <Button style={{ padding: '6px 12px' }} className={`rounded whitespace-no-wrap ${closeButtonClass}`} onClick={closeToast} text={closeText} />
      </div>
    </div>
  )
}

export default AlertSimple
