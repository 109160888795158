import { StarMarked, StarUnmarked } from './styles'

import Tooltip from '../Tooltip'

const CheckIcon = ({ checked, tooltipText, width, height, onClick }) => {
  const Icon = checked ? StarMarked : StarUnmarked

  return tooltipText ? (
    <Tooltip tooltipText={tooltipText}>
      <Icon width={width} height={height} onClick={onClick} />
    </Tooltip>
  ) : (
    <Icon width={width} height={height} onClick={onClick} />
  )
}

export default CheckIcon
