// import { Button } from 'antd';
import NewPageIcon from './NewPageIcon'
import SelectProblemIcon from './SelectProblemIcon'

// import amplitude from 'amplitude-js';
// import { amplitudeInstance } from '../../../../amplitude'

const style = {
  container: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    paddingTop: "1em",
    height: "80px",
  },
  button: {
    border: 0,
    color: "rgb(240, 252, 252)",
    fontWeight: 600,
    fontSize: "16px",
  }
}

const FooterUnsolved = (props) => {
  const { 
    problem,
    selectionMode, 
    addToSelectedProblems, 
    removeFromSelectedProblems
  } = props
  return (
    <div>
      <div style={style.container}>
        { selectionMode === true 
          ? <SelectProblemIcon 
              problem={problem}
              addToSelectedProblems={addToSelectedProblems}
              removeFromSelectedProblems={removeFromSelectedProblems}
            />
          : <NewPageIcon />
        }
        
      </div>
    </div>
  )
}

export default FooterUnsolved;