import React, { useState } from 'react'
import { connect } from 'react-redux'
import UploadRecordingModal from '../Modal/UploadRecordingModal'
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-clear.svg'
import { format } from 'date-fns'
import { validDateObject } from '../../helpers'

const SessionOverview = React.lazy(() => import('../SessionOverview'))
const NotesPreview = React.lazy(() => import('../NotesPreview'))
const ICON_SIZE = '24px'

const SingleVideoTabs = ({
  tags,
  notes,
  title,
  videoId,
  isMentor,
  lastName,
  firstName,
  isPrivate,
  sessionDate,
  description,
  links,
  s3BucketPath,
  cloudfrontURL,
  edlyftCohort,
}) => {
  const [activeTab, setActiveTab] = useState('session-overview')
  const [isUploadModalOpen, setUploadModalOpen] = useState(false)

  const handleUploadModalClick = () => {
    // Open upload modal to edit recording
    setUploadModalOpen(true)
  }

  let formattedDate
  try {
    formattedDate = format(validDateObject(sessionDate), 'yyyy-MM-dd') ?? null
  } catch (e) {
    formattedDate = null
  }

  // Edlyft cohort that need a space between then number and text
  const cohortWithSpace = ['Math54', 'Data100', 'Math33a', 'Math61']
  // Initialize with original cohort text
  let formattedCourse = edlyftCohort
  // Check if cohort needs a space
  if (cohortWithSpace.includes(edlyftCohort)) {
    // Split by first occurence of digit and concatenate with a space
    // Math54 => 'Math 54', Math33a => 'Math 33a'
    formattedCourse = edlyftCohort.split(/(\d.+)/).join(' ')
  }
  const ATTENDANCE_URL = process.env.REACT_APP_ATTENDANCE_FORM
  const rateLink = 'https://airtable.com/shrCHZQiD8a7gAjxV'

  return (
    <>
      <div className='items-center flex justify-between'>
        <div className='font-medium leading-28 text-3xl'>
          <button
            style={{ opacity: activeTab === 'session-overview' ? 1 : 0.3 }}
            className={`pb-1 text-violet-dark mr-4 ${
              activeTab === 'session-overview'
                ? 'border-b border-violet-dark'
                : ''
            }`}
            onClick={() => setActiveTab('session-overview')}
          >
            Session Overview
          </button>
          <button
            style={{ opacity: activeTab === 'notes' ? 1 : 0.3 }}
            className={`pb-1 text-violet-dark relative ${
              activeTab === 'notes' ? 'border-b border-violet-dark' : ''
            }`}
            onClick={() => setActiveTab('notes')}
          >
            Notes
            {
              <>
                {Array.isArray(notes) && (
                  <span
                    style={{
                      right: '-13px',
                      top: '-3px',
                      width: '14px',
                      height: '14px',
                    }}
                    className='text-sm rounded-full bg-blue-normal flex items-center justify-center absolute text-white leading-14'
                  >
                    {notes.length}
                  </span>
                )}
              </>
            }
          </button>
        </div>
        {!isMentor && (
          <a
            href={rateLink}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-normal hover:underline font-bold leading-20 text-lg inline-block'
          >
            Rate Session
          </a>
        )}
        {isMentor && (
          <div className='flex items-center gap-2'>
            <button
              onClick={async () => {
                try {
                  const response = await fetch(cloudfrontURL)
                  const blob = await response.blob()
                  let tempUrl = URL.createObjectURL(blob);
                  const aTag = document.createElement("a");
                  aTag.href = tempUrl;
                  aTag.download = new URL(cloudfrontURL).pathname.replace(/^.*[\\\/]/, '');
                  document.body.appendChild(aTag);
                  aTag.click();
                  URL.revokeObjectURL(tempUrl);
                  aTag.remove();
                } catch {}
              }}
              style={{
                minWidth: ICON_SIZE,
                width: ICON_SIZE,
                height: ICON_SIZE,
                minHeight: ICON_SIZE,
              }}
              className='bg-gray-lighter rounded-full'
            >
              <span className='flex items-center justify-center'>
                <DownloadIcon className='w-6 h-6' />
              </span>
            </button>
            <button
              onClick={handleUploadModalClick}
              style={{
                minWidth: ICON_SIZE,
                width: ICON_SIZE,
                height: ICON_SIZE,
                minHeight: ICON_SIZE,
              }}
              className='bg-gray-lighter rounded-full'
            >
              <span className='flex items-center justify-center'>
                <PencilIcon />
              </span>
            </button>
          </div>
        )}
      </div>
      <div className='mt-6'>
        {activeTab === 'session-overview' && (
          <React.Suspense fallback={null}>
            <SessionOverview
              edlyftCohort={edlyftCohort}
              description={description}
              notes={notes}
              links={links}
            />
          </React.Suspense>
        )}

        {activeTab === 'notes' && (
          <React.Suspense fallback={null}>
            <NotesPreview edlyftCohort={edlyftCohort} notes={notes} />
          </React.Suspense>
        )}
      </div>
      <UploadRecordingModal
        open={isUploadModalOpen}
        closeModal={() => setUploadModalOpen(false)}
        data={{
          tags,
          videoId,
          isPrivate,
          videoTitle: title,
          description: description,
          rawSessionDate: sessionDate,
          sessionDate: formattedDate,
          notes: notes || [],
          links: links || [],
          video: s3BucketPath
            ? {
                name: title,
                url: s3BucketPath,
              }
            : null,
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  const {
    user: { isMentor, firstName, lastName, edlyftCohort },
  } = state

  return {
    isMentor,
    lastName,
    firstName,
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(SingleVideoTabs)
