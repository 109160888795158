import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import withPageWrapper from '../../../hoc/AuthPageWrapper'
import CommonSidebar from '../../ViewsContent/CommonSidebar'
import axios from '../../../axios-orders'
import {
  updateProfile as updateProfileAction
} from '../../../redux/actions/user'

const MentorDashboard = React.lazy(() => import('./Mentor'))
const StudentDashboard = React.lazy(() => import('./Student'))

const Dashboard = ({ isMentor, user, updateProfile }) => {
  useEffect(() => {
    if (!isMentor && !user.firstSignIn && !user.email.includes('test')) {
      axios
        .post('/profile/onboard/save-airtable', user)
        .then(data => {
          updateProfile({ firstSignIn: true });
        })
    }
  }, [])

  return (
    <React.Suspense fallback={null}>
      {isMentor === true ? <MentorDashboard /> : <StudentDashboard />}
    </React.Suspense>
  )
}

const mapStateToProps = state => {
  const {
    user,
  } = state

  return {
    isMentor: user.isMentor,
    user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: data => dispatch(updateProfileAction(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withPageWrapper(Dashboard, 'dashboard', null, null, CommonSidebar),
)
