import React, { useState, useEffect } from 'react'
import 'plyr/dist/plyr.css'
import VideoPlayer from 'plyr-react'
import defaultPoster from '../../assets/images/video-preview.jpg'
import { connect } from 'react-redux'
import PracticeProblemHint from './PracticeProblemHint'
import MathJax from 'react-mathjax-preview'
import { useForm } from 'react-hook-form'
import { Button } from '../Form/Button'
import { useHistory } from 'react-router-dom'
import PracticeProblemRecord from './PracticeProblemRecord'
import { saveInteraction } from '../../utils'
import { ReactComponent as IconLightBulb } from '../../assets/icons/light-bulb.svg'
import { ReactComponent as IconClose } from '../../assets/icons/x-round-alt.svg'
import { amplitudeInstance } from '../../amplitude'

const PracticeProblemOption = ({
  register,
  value,
  text,
  correctAnswer,
  showAnswer,
  selectedAnswer,
}) => {
  const isSelectedOption = selectedAnswer === value
  const selectedCorrectAnswer = isSelectedOption && correctAnswer === value
  const selectedWrongAnswer = isSelectedOption && correctAnswer !== value
  const correctAnswerNotSelected =
    !(selectedCorrectAnswer || selectedWrongAnswer) && correctAnswer === value
  const { location } = useHistory()
  let additionalClassnames = ''
  const [showTooltip, setShowTooltip] = useState()

  useEffect(() => {
    // Only show tooltip when a valid option is selected
    if (!selectedAnswer || selectedAnswer === 'none') {
      setShowTooltip(false)
    } else {
      setShowTooltip(isSelectedOption)
    }
  }, [isSelectedOption, selectedAnswer])

  // Toggle option class names based on selection
  // Additional space at the end is intentional
  if (showAnswer) {
    if (correctAnswer === value) {
      additionalClassnames += 'practice-problem-option--valid '
    }
    if (selectedCorrectAnswer) {
      additionalClassnames += 'practice-problem-option--correct-choice'
    } else if (selectedWrongAnswer) {
      additionalClassnames += 'practice-problem-option--wrong-choice'
    }
  }

  return (
    <>
      <div
        className={`practice-problem-option relative font-thin text-base leading-16 ${additionalClassnames}`}
      >
        <input
          disabled={showAnswer}
          value={value}
          type='radio'
          name='option'
          ref={register}
          className='opacity-0 absolute w-full h-full left-0 top-0'
        />
        <div
          className='flex rounded practice-problem-option__content'
          style={styles.practiceProblemOption}
        >
          <span
            style={styles.optionCircle}
            className='flex items-center justify-center practice-problem-option__circle relative rounded-full border border-gray-lighter-soft bg-white'
          >
            <span
              style={styles.innerOptionCircle}
              className='hidden bg-blue-normal rounded-full'
            ></span>
          </span>
          <div className='practice-problem-option__text'>
            <MathJax math={text} />
          </div>
        </div>
        {showAnswer && (
          <>
            {(selectedCorrectAnswer || selectedWrongAnswer) && (
              <div className='mt-1 mb-2 relative z-10 leading-13 text-sm flex items-center'>
                <span
                  className={`font-medium ${
                    selectedCorrectAnswer
                      ? 'text-green-normal'
                      : 'text-gray-dark'
                  }`}
                >
                  {selectedCorrectAnswer ? 'Correct!' : 'Incorrect'}
                </span>
                <span
                  style={styles.explanationBorder}
                  className='inline-block mx-1'
                >
                  |
                </span>
                <a
                  href={`${location.pathname}#problem-solution`}
                  style={styles.explanation}
                >
                  {selectedCorrectAnswer ? 'See explanation' : 'See solution'}
                </a>
              </div>
            )}
            {correctAnswerNotSelected && (
              <div className='mt-1 mb-2 relative z-10 leading-13 text-sm '>
                <span className='font-medium text-gray-dark'>
                  Correct Answer
                </span>
              </div>
            )}
          </>
        )}
      </div>
      {showTooltip && (
        <div
          style={styles.tooltip}
          className='absolute flex items-start rounded-md bg-white'
        >
          <button
            onClick={() => setShowTooltip(false)}
            style={styles.tooltipIconClose}
            className='absolute'
          >
            <IconClose />
          </button>
          <span className='mr-2' style={styles.tooltipIconHint}>
            <IconLightBulb />
          </span>
          <span className='leading-18 font-light text-tiny text-gray-darkest'>
            You are more likely to remember something if you say it out loud.
            Use the record feature as a study hack.
          </span>
        </div>
      )}
    </>
  )
}

const PracticeProblem = ({
  id,
  hint,
  title,
  problem,
  solution,
  hintLink,
  edlyftCohort,
  solutionVideo,
  answerOptions,
  correctAnswer,
  showNextQuestion,
  screenOut,
  userId,
  isActive,
  isMentor,
  firstName,
  lastName,
}) => {
  const [solutionState, setSolutionState] = useState(null)
  const { register, watch, setValue } = useForm()
  const watchOption = watch('option')

  useEffect(() => {
    setSolutionState(null)
    setValue('option', null)
  }, [id])

  return (
    <div className='py-10 px-8 rounded bg-white border border-gray-lighter-soft'>
      <h6 className='font-medium leading-22 text-gray-darkest text-3xl mb-12'>
        {title}
      </h6>
      <div className='mb-20'>
        <div className='overflow-x-auto'>
          <MathJax math={problem} />
        </div>
      </div>
      {Array.isArray(answerOptions) && answerOptions.length > 0 && (
        <div className='rounded bg-gray-lightest pt-3 pb-6 px-4 mb-5'>
          <span className='block mb-4 text-xl text-gray-darkest font-medium leading-28'>
            Select Answer
          </span>
          <div className='flex flex-wrap'>
            {answerOptions.map(option => (
              <div key={option?.id} className='w-1/2 relative'>
                <PracticeProblemOption
                  value={option?.id}
                  text={option?.text}
                  register={register}
                  correctAnswer={correctAnswer}
                  showAnswer={solutionState === 'reveal'}
                  selectedAnswer={watchOption}
                />
              </div>
            ))}
            <div className='w-1/2 relative'>
              <PracticeProblemOption
                value='none'
                register={register}
                text="I don't see my answer."
                correctAnswer={correctAnswer}
                selectedAnswer={watchOption}
                showAnswer={solutionState === 'reveal'}
              />
            </div>
          </div>
        </div>
      )}
      <div className='flex flex-wrap -mx-3 mb-5'>
        <div
          onClick={() => {
            saveInteraction({
              resourcePoster: '',
              edlyftCohort,
              resourceType: 'practiceProblem',
              resourceId: id,
              screenOut,
              userId,
              isActive,
              isMentor,
              resourceTitle: 'hintOnPracticeProblem',
              userName: `${firstName} ${lastName}`,
            })
          }}
          className='w-1/2 px-2'
        >
          <PracticeProblemHint title={title} hint={hint} hintLink={hintLink} />
        </div>
        {watchOption && (
          <div className='w-1/2 px-2'>
            <PracticeProblemRecord title={title} problemId={id} />
          </div>
        )}
      </div>
      {solutionState !== 'reveal' && (
        <div className='flex justify-center'>
          <Button
            onClick={() => {
              saveInteraction({
                resourcePoster: '',
                edlyftCohort,
                resourceType: 'practiceProblem',
                resourceId: id,
                screenOut,
                userId,
                isActive,
                isMentor,
                resourceTitle: 'submittedPracticeProblem',
                userName: `${firstName} ${lastName}`,
              })
              setSolutionState('reveal')
              amplitudeInstance.logEvent(
                'Clicked Submit Button for Practice Problem',
                {
                  cohort: edlyftCohort,
                  practiceProblemTitle: title,
                },
              )
            }}
            disabled={!watchOption}
            style={styles.submitButton}
            className='bg-blue-normal'
            text='Submit'
          />
        </div>
      )}
      {solutionState === 'reveal' && (
        <div className='flex justify-center mb-10'>
          <Button
            onClick={showNextQuestion}
            style={styles.submitButton}
            className='bg-blue-normal text-tiny leading-15'
            text='Next Question'
          />
        </div>
      )}
      {solutionState === 'reveal' && (
        <div id='problem-solution' className='rounded bg-gray-lightest p-5'>
          <span className='block text-gray-darkest leading-28 font-medium text-lg'>
            Solution
          </span>
          <div className='overflow-x-auto'>
            <MathJax math={solution} />
          </div>
          {solutionVideo && (
            <>
              <span className='block text-gray-darkest leading-28 font-medium text-lg'>
                Explanation
              </span>
              <div
                className='mx-auto rounded overflow-hidden'
                style={styles.videoWrapper}
              >
                <VideoPlayer
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: solutionVideo,
                        type: 'video/mp4',
                      },
                    ],
                    poster: defaultPoster,
                  }}
                  options={{
                    speed: {
                      selected: 1,
                      options: [1, 1.5, 2],
                    },
                    ratio: '16:9',
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const EXPLANATION_GREY = '#BABABA'

const styles = {
  tooltipIconClose: {
    top: '8px',
    right: '8px',
    width: '16px',
    height: '16px',
  },
  tooltipIconHint: {
    width: '24px',
    minWidth: '24px',
    minHeight: '24px',
    height: '24px',
  },
  tooltip: {
    zIndex: 10,
    left: '-150px',
    top: '30px',
    maxWidth: '245px',
    padding: '17px 30px 22px 12px',
    boxShadow: '10px 20px 30px 3px rgba(177, 188, 199, 0.15)',
  },
  explanationBorder: {
    color: EXPLANATION_GREY,
  },
  explanation: {
    color: EXPLANATION_GREY,
  },
  submitButton: {
    width: '134px',
  },
  innerOptionCircle: {
    width: '10px',
    height: '10px',
  },
  optionCircle: {
    width: '16px',
    height: '16px',
    minWidth: '16px',
    minHeight: '16px',
  },
  videoWrapper: {
    width: '297px',
  },
  revealButton: {
    width: '116px',
  },
  hintButton: {
    width: '109px',
  },
}

const mapStateToProps = state => {
  const {
    user: {
      edlyftCohort,
      screenOut,
      userId,
      isActive,
      isMentor,
      firstName,
      lastName,
    },
  } = state

  return {
    edlyftCohort,
    screenOut,
    userId,
    isActive,
    isMentor,
    firstName,
    lastName,
  }
}

export default connect(mapStateToProps)(PracticeProblem)
