import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Checkbox } from 'antd';
import { isNil } from 'lodash';
import axios from '../../axios-orders';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { updateEmailPreferences } from '../../redux/actions/user';
import { Button } from '../Form/Button';
import { amplitudeInstance } from '../../amplitude';
import { ReactComponent as CloseIcon } from '../../assets/icons/x-round.svg';

const EmailPreferenceModal = ({
  open,
  userId,
  closeModal,
  email,
  edlyftCohort,
  subscriptionId,
  lastName,
  firstName,
  sessionRecordingUpload,
  updateEmailPreferences,
}) => {
  const [isSubmittingForm, setSubmittingForm] = useState(false);
  const { handleSubmit, control } = useForm();
  const subscriptions = ['pass', 'mentor', 'scholarship'];

  const updatePreferences = (values) => {
    setSubmittingForm(true);
    axios
      .put('/profile/email-preferences', {
        sessionRecordingUpload: isNil(values.sessionRecordingUpload)
          ? sessionRecordingUpload
          : values.sessionRecordingUpload,
        edlyftCohort,
        email,
        lastName,
        firstName,
        userId,
      })
      .then(() => {
        updateEmailPreferences({
          sessionRecordingUpload: isNil(values.sessionRecordingUpload)
            ? sessionRecordingUpload
            : values.sessionRecordingUpload,
        });
        closeModal();
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setSubmittingForm(false);
      });
  };

  const onSubmit = (values) => {
    const suid = subscriptionId;
    if (subscriptions.includes(suid)) {
      updatePreferences(values);
    } else {
      const request = axios.get('/student/subscription', {
        params: { id: suid },
      });

      request
        .then((response) => {
          updatePreferences(values);
        })
        .catch((e) => {
          console.dir(e);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      closeOnEsc={false}
      closeOnOverlayClick={false}
      styles={{
        overlay: {
          zIndex: '2000',
        },
        modal: {
          width: '371px',
        },
      }}
      classNames={{
        overlay: 'py-0 pr-0',
        modal: 'max-w-full bg-white h-full p-6 mr-0 ml-auto overflow-auto',
      }}
      showCloseIcon={false}
    >
      <div className='relative'>
        <button
          onClick={closeModal}
          style={{ width: '25px', height: '25px' }}
          className='outline-none absolute top-0 right-0'
        >
          <CloseIcon />
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='pt-16 modal-email-preferences'>
            <div className='title'>Email Settings</div>
            <div className='sub-title'>
              Set your email notification preferrences.
            </div>
            <div className='pt-16 checkbox-section'>
              <div className='section-title'>New Recordings</div>
              <div className='section-description'>
                Receive email notifications when new recordings are uploaded for
                cohort.
              </div>
              <div>
                <Controller
                  name='sessionRecordingUpload'
                  control={control}
                  render={({ onChange, value }) => (
                    <Checkbox
                      onChange={(e) => {
                        onChange(e.target.checked);
                        amplitudeInstance.logEvent(
                          'Clicked New Recordings Email Checkbox',
                          {
                            cohort: edlyftCohort,
                            state: e.target.checked ? 'checked' : 'unchecked',
                          }
                        );
                      }}
                      checked={isNil(value) ? sessionRecordingUpload : value}
                    >
                      Email
                    </Checkbox>
                  )}
                />
              </div>
            </div>
            <div className='divider' />
            <div className='pt-16'>
              <Button
                disabled={isSubmittingForm}
                type='submit'
                style={{ width: '100%' }}
                text='Save Changes'
                className='bg-blue-normal'
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    user: {
      isMentor,
      photoURL,
      firstName,
      lastName,
      userId,
      email,
      courses,
      edlyftCohort,
      subscriptionId,
      sessionRecordingUpload,
    },
  } = state;

  return {
    isMentor,
    firstName,
    lastName,
    photoURL,
    userId,
    email,
    courses,
    edlyftCohort,
    subscriptionId,
    sessionRecordingUpload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateEmailPreferences: (data) => dispatch(updateEmailPreferences(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailPreferenceModal);
