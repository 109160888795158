import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from '../../axios-orders'
import TimestampsList from "../Timestamps/list";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus_blue.svg";
import TimestampForm from "../Timestamps/form";

const Timestamps = ({ isMentor }) => {
    const [allTimestamps, setTimestamps] = useState([])
    const [isAdding, setIsAdding] = useState(false)
    const [recordingKey, setRecordingKey] = useState({ videoID: '', sessionDate: '', classID: '' })
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [timestampsConfirmationCount, setTimestampsConfirmationCount] = useState(0)
    const [loading, setLoading] = useState(false)

    const handleTimestampsReady = useCallback((event) => {
        setTimestamps(event.detail.timestamps)
        setRecordingKey({
            videoID: event.detail.videoId,
            sessionDate: event.detail.sessionDate,
            classID: event.detail.classID,
        })
        setTimestampsConfirmationCount(event.detail.timestampsConfirmationCount)
        setShowConfirmation(event.detail.showConfirmation)
    }, [])

    const updateTimestamps = useCallback((allTimestamps, confirmationCount) => {
        setLoading(true)
        axios.put('/common/single-recording', {
            allTimestamps,
            sessionDate: recordingKey.sessionDate,
            videoID: recordingKey.videoID,
            timestampsConfirmationCount: confirmationCount ?? timestampsConfirmationCount,
        })
            .then(() => {
                setTimestamps(allTimestamps)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }, [recordingKey, allTimestamps])

    useEffect(() => {
        window.addEventListener('video-timestamps-ready', handleTimestampsReady)

        return () => {
            window.removeEventListener('video-timestamps-ready', handleTimestampsReady)
        }
    }, [recordingKey])

    return (
        <div className="timestamps-pane border-gray-thin px-4 py-8 rounded">
            <h3 className="timestamps-pane-header">Recording Content</h3>
            {(loading) ? <div className={'timestamps-empty'}>Loading...</div> : null}
            {(allTimestamps.length < 1 && recordingKey.videoID !== "" && !loading) ?
                <div className={'timestamps-empty'}>No Timestamps</div> : null}
            {(allTimestamps.length > 0 && recordingKey.videoID !== "" && !loading) ? <TimestampsList
                isMentor={isMentor}
                timestamps={allTimestamps}
                updateTimestamps={(timestamps) => updateTimestamps(timestamps)}
                videoID={recordingKey.videoID}
                classID={recordingKey.classID}
            />: null}
            <div className="timestamps-pane-footer">
                {!isAdding
                    ? (isMentor ?
                        <button className="add-new-timestamps-btn" onClick={() => setIsAdding(true)}>
                            <PlusIcon />&nbsp;&nbsp;
                            Add new
                        </button> : null
                    ) :
                    <TimestampForm
                        defaultTimestamp={""}
                        defaultSentence={""}
                        handleSubmit={(data) => {
                            const newTimestamps = [
                                ...allTimestamps,
                                data
                            ]
                            setTimestamps(newTimestamps)
                            setIsAdding(false)
                            updateTimestamps(newTimestamps, timestampsConfirmationCount)
                        }}
                        handleClose={() => {
                            setIsAdding(false)
                        }}
                    />
                }
                {(showConfirmation && isMentor) &&
                    <button
                        className={'timestamps-confirm-button'}
                        onClick={() => {
                            setTimestampsConfirmationCount(timestampsConfirmationCount + 1)
                            setShowConfirmation(false)
                            updateTimestamps(allTimestamps, timestampsConfirmationCount + 1)
                        }}>
                        Confirm timestamps
                    </button>}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { user: { isMentor } } = state
    return { isMentor }
}

export default connect(mapStateToProps)(Timestamps)
