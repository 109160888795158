import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
});
instance.CancelToken = axios.CancelToken

// Default axios instance
export const axiosDefault = axios
export const samBackend = axios.create({
  // SAM API Gateway (dev stack):
  baseURL: process.env.REACT_APP_SAM_BACKEND
});
samBackend.CancelToken = axios.CancelToken

// Express Server
export const express = axios.create({
  baseURL: process.env.REACT_APP_EXPRESS_SERVER,
});
express.CancelToken = axios.CancelToken;

export default instance
