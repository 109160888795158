import React from 'react'
import { validDateObject } from '../../helpers'
import { format } from 'date-fns-tz'
import { amplitudeInstance } from '../../amplitude'
import { connect } from 'react-redux'

const PracticeProblems = ({ items, setActiveProblem, edlyftCohort }) => {

  const handleItemClick = (item) => {
    amplitudeInstance.logEvent('Clicked Practice Problem Card', {
      cohort: edlyftCohort,
      practiceProblemTitle: item.title,
    })

    setActiveProblem(item)
  }

  const { data, title} = items

  return (
    <div className="rounded bg-gray-lightest border border-gray-lighter-soft px-6 py-6 pb-12">
      <h5 className="mb-10 leading-20 font-medium text-lg text-gray-darkest">{title}</h5>
      <div>
        {
          Array.isArray(data) &&
          <>
            {
              data.map((item) => {
                let date
                try {
                  date = format(validDateObject(item.dateCompleted), 'do LLL, yyy')
                } catch (e) { }

                return (
                  <button style={item.hasAttempted ? styles.hasAttempted : null} onClick={() => handleItemClick(item)} key={item.problemId} className="block w-full text-left border mb-4 rounded bg-white px-3 py-2 border-gray-lighter-soft">
                    <div className="flex items-center justify-between mb-2">
                      <span className="block text-gray-darkest font-medium leading-15 text-tiny">{item.title}</span>
                    </div>
                    {
                      date &&
                      <div className="flex items-center justify-between">
                        <div className="text-xs leading-10 text-gray-light">
                          <span className="font-medium">Last Completed: </span>
                          <span className="font-thin">{date}</span>
                        </div>
                      </div>
                    }
                  </button>
                )
              })
            }
          </>
        }
      </div>
    </div>
  )
}

const styles = {
  hasAttempted: {
    background: '#FEF8EF',
    border: '1px solid #EAEAEA',
  }
}

const mapStateToProps = state => {
  const { user: { edlyftCohort } } = state

  return {
    edlyftCohort,
  }
}

export default connect(mapStateToProps)(PracticeProblems)