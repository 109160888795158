import React, { useState, useEffect } from 'react'
import Logo from '../../assets/images/edlyft-logo.png'
import { Button } from '../Form/Button'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loadDynamicScript } from '../../helpers'
import 'antd/lib/select/style/css'
import { Select } from 'antd'
import axios from 'axios'
import { SortBy } from '../../helpers'
const { Option } = Select

const Label = ({ text, htmlFor }) => (
  <label
    htmlFor={htmlFor}
    className='text-tiny leading-15 mb-1 block'
    style={{ color: 'rgba(102, 102, 102, 0.3)' }}
  >
    {text}
  </label>
)

const TOTAL_STEPS = 2
const PROGRESS_HEIGHT = '10px'

const ReferralForm = ({ allCourses }) => {
  const routeParams = useParams()
  const referralFirstName = routeParams.firstName.toLowerCase()
  const referralLastName = routeParams.lastName.toLowerCase()
  const { register, handleSubmit, watch } = useForm()
  const email = watch('email')
  const firstName = watch('first_name')
  const referralCode = watch(
    'referral_code',
    `${referralFirstName}${referralLastName}`,
  )
  const [currentStep, setStep] = useState(1)
  const [courses, setCourses] = useState([])
  const disableSubmitButton =
    !firstName ||
    !email ||
    !courses ||
    (Array.isArray(courses) && courses.length < 1) ||
    !referralCode

  useEffect(() => {
    loadDynamicScript({
      id: 'calendly',
      src: 'https://assets.calendly.com/assets/external/widget.js',
    })
  }, [])

  /**
   * Handle react hooks form submission
   *
   * @param {Object} formData
   */
  const handleFormSubmit = formData => {
    setStep(2)
    submitAirtableData(formData)
  }

  /**
   * Post data to Airtable
   *
   * @param {Object} formData
   */
  const submitAirtableData = formData => {
    const fields = {
      Course: courses,
      'Email Address': formData.email,
      'First Name': formData.first_name,
      'Referral Code': referralCode,
    }
    const airtableBase = 'app90W9zhPEXY66Jf'
    const airtableAPIKey = process.env.REACT_APP_AIRTABLE_API
    const airtableTable = 'Table 1'
    // Post to axios
    axios
      .post(
        `https://api.airtable.com/v0/${airtableBase}/${airtableTable}`,
        {
          records: [
            {
              fields,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${airtableAPIKey}`,
          },
        },
      )
      .catch(e => {
        console.error(e)
      })
  }

  /**
   * Handle course select
   *
   * @param {Array} courses
   */
  const handleCourseChange = courses => {
    const coursesWithLimit = Array.isArray(courses) ? courses.slice(0, 2) : []
    setCourses(coursesWithLimit)
  }
  // Compute progress width
  const STEP_WIDTH = (currentStep / TOTAL_STEPS) * 100
  let unsupportedCourseSelected
  if (
    Array.isArray(courses) &&
    courses.length === 1 &&
    courses[0] === 'other'
  ) {
    unsupportedCourseSelected = true
  }

  return (
    <div className='py-10 px-8 w-full'>
      <img className='mb-16 mx-auto' style={{ width: '88px' }} src={Logo} />
      <h1 className='mb-6 font-medium text-8xl text-center text-center text-violet-dark leading-40'>
        Join the Waitlist
      </h1>
      <span className='leading-18 text-base text-gray-dark block text-center mb-8'>
        Your invite from {referralFirstName} expires in 14 days
      </span>
      <form
        className='flex justify-center w-full'
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div
          style={{
            maxWidth: '400px',
            boxShadow: '0px 10px 50px rgba(5, 76, 70, 0.03)',
            borderWidth: '1px',
          }}
          className='text-base flex-1 py-8 rounded-lg border-gray-lighter-hard'
        >
          {(currentStep === 1 ||
            (currentStep === 2 && !unsupportedCourseSelected)) && (
            <div className='mb-8 px-6'>
              <div
                style={{ color: '#707474' }}
                className='mb-3 flex leading-15 text-tiny items-center justify-between'
              >
                <span>
                  Step {currentStep} of {TOTAL_STEPS}
                </span>
              </div>
              <div
                style={{
                  height: PROGRESS_HEIGHT,
                  backgroundColor: 'rgba(120, 202, 149, 0.1)',
                }}
                className='overflow-hidden relative rounded-full w-full'
              >
                <span
                  style={{ height: PROGRESS_HEIGHT, width: `${STEP_WIDTH}%` }}
                  className='absolute rounded-full left-0 top-0 bg-green-normal'
                ></span>
              </div>
            </div>
          )}
          {currentStep === 1 && (
            <div className='px-8'>
              <div className='mb-8'>
                <Label text='Referral Code' htmlFor='referral' />
                <input
                  readOnly
                  defaultValue={referralCode}
                  style={styles.input}
                  className='font-light rounded form-input bg-gray-lightest'
                  id='referral'
                  type='text'
                  name='referral'
                  ref={register}
                />
              </div>
              <div className='mb-8'>
                <Label text='First Name' htmlFor='first_name' />
                <input
                  style={styles.input}
                  placeholder='Enter your first name'
                  className='font-light rounded form-input bg-gray-lightest'
                  id='first_name'
                  type='text'
                  name='first_name'
                  ref={register}
                />
              </div>
              <div className='mb-8'>
                <Label text='Email Address' htmlFor='email' />
                <input
                  style={styles.input}
                  placeholder='Enter your email address'
                  className='font-light rounded form-input bg-gray-lightest'
                  id='email'
                  type='email'
                  name='email'
                  ref={register}
                />
              </div>
              <div className='mb-12'>
                <Label text='Your Course' htmlFor='course' />
                <Select
                  className='form-select-wrapper referral-form-select'
                  mode='multiple'
                  size='large'
                  placeholder='Select your Course'
                  onChange={handleCourseChange}
                  style={{ width: '100%' }}
                  dropdownClassName='form-select'
                  dropdownRender={menu => (
                    <div className='form-select__menu py-3'>{menu}</div>
                  )}
                  value={courses}
                >
                  {SortBy(
                    allCourses?.filter(course => course.live),
                    'courseNumber',
                  ).map(item => (
                    <Option
                      className='form-select__option'
                      key={item.edlyftCohort}
                    >
                      {item.courseNumber}
                    </Option>
                  ))}
                  <Option className='form-select__option' key='other'>
                    Other
                  </Option>
                </Select>
              </div>
              <Button
                disabled={disableSubmitButton}
                className='bg-blue-normal block w-full'
                type='submit'
                text='Next'
              />
            </div>
          )}
          <div
            className={`${
              currentStep === 2 && !unsupportedCourseSelected
                ? 'block'
                : 'hidden'
            } calendly-inline-widget`}
            data-url='https://calendly.com/edlyft-fall/refer?hide_event_type_details=1'
            style={styles.calendly}
          ></div>
          {currentStep === 2 && unsupportedCourseSelected && (
            <p className='text-center text-lg px-3 text-xl'>
              Thank you for filling this form out. We are not supporting your
              course this semester. Please email us at info@edlyft.com, or check
              back later!
            </p>
          )}
        </div>
      </form>
    </div>
  )
}
const mapStateToProps = state => {
  const {
    edlyft: { courses },
  } = state
  return {
    allCourses: courses,
  }
}

export default connect(mapStateToProps)(ReferralForm)

const styles = {
  calendly: {
    minWidth: '320px',
    height: '630px',
  },
  input: {
    border: '1px solid #DFDFE1',
  },
}
