import { SET_EDLYFT_COURSES } from '../types'

const INITIAL_STATE = {
  courses: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EDLYFT_COURSES:
      return {
        ...state,
        courses: action.courses,
      }

    default:
      return state
  }
}

export default reducer
